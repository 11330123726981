import { StytchB2BProvider, useStytchB2BClient } from '@stytch/react/b2b';
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b';
import React, { useMemo } from 'react';

interface StytchProviderProps {
  children: React.ReactNode;
}

export const StytchProvider: React.FC<StytchProviderProps> = ({ children }) => {
  const stytchClient = useMemo(() => {
    const publicToken = import.meta.env.VITE_STYTCH_PUBLIC_TOKEN;

    if (!publicToken) {
      console.error('Stytch public token is not configured');
      throw new Error('Stytch configuration is missing');
    }

    const client = new StytchB2BUIClient(publicToken);
    return client;
  }, []);

  return (
    <StytchB2BProvider stytch={stytchClient}>
      {children}
    </StytchB2BProvider>
  );
};

// Export the hook for use in components
export const useStytch = useStytchB2BClient;

export default StytchProvider;
