import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { useGetStartupConfig } from 'librechat-data-provider/react-query';
import type { TLoginUser, TStartupConfig } from 'librechat-data-provider';
import type { TAuthContext } from '~/common';
import { useResendVerificationEmail } from '~/data-provider';
import { useLocalize } from '~/hooks';
import { useStytch } from './StytchProvider';
import './css/button.css';

interface AuthResponse {
  samlRedirect?: boolean;
  redirectUrl?: string;
}

type TLoginFormProps = {
  onSubmit: (data: TLoginUser) => Promise<AuthResponse | void>;
  startupConfig: TStartupConfig;
  error: Pick<TAuthContext, 'error'>['error'];
  setError: Pick<TAuthContext, 'setError'>['setError'];
};

const LoginForm: React.FC<TLoginFormProps> = ({
  startupConfig,
  error,
  setError,
}) => {
  const localize = useLocalize();
  const stytchClient = useStytch();
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TLoginUser>();
  const [showResendLink, setShowResendLink] = useState<boolean>(false);
  const [isAyzenbergDomain, setIsAyzenbergDomain] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [magicLinkSent, setMagicLinkSent] = useState<boolean>(false);

  const { data: config } = useGetStartupConfig();
  const useUsernameLogin = config?.ldap?.username;

  // Watch the email field to detect ayzenberg.com domain
  const email = watch('email');
  useEffect(() => {
    if (email) {
      const domain = email.split('@')[1];
      setIsAyzenbergDomain(domain === 'ayzenberg.com');
    } else {
      setIsAyzenbergDomain(false);
    }
  }, [email]);

  useEffect(() => {
    if (error && error.includes('422') && !showResendLink) {
      setShowResendLink(true);
    }
  }, [error, showResendLink]);

  const resendLinkMutation = useResendVerificationEmail({
    onMutate: () => {
      setError(undefined);
      setShowResendLink(false);
    },
  });

  const handleSSOLogin = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const connectionId = import.meta.env.VITE_STYTCH_OKTA_CONNECTION_ID;
      if (!connectionId) {
        throw new Error('SSO configuration is missing');
      }

      await stytchClient.sso.start({
        connection_id: connectionId,
        login_redirect_url: `${window.location.origin}/api/auth/authenticate`,
      });
    } catch (error: unknown) {
      console.error('SSO error:', error);
      setError(
        error instanceof Error ? error.message : 'SSO authentication failed',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleMagicLinkLogin = async (data: TLoginUser) => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/auth/magic-link', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data.email,
          name: data.email.split('@')[0], // Use email username as name
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'Failed to send magic link');
      }

      setMagicLinkSent(true);
    } catch (error: unknown) {
      console.error('Magic link error:', error);
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to send magic link';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  if (!startupConfig) {
    return null;
  }

  const renderError = (fieldName: string) => {
    const errorMessage = errors[fieldName]?.message;
    return errorMessage ? (
      <span
        role="alert"
        className="magenta-to-yellow-title-gradient mt-1 text-sm"
      >
        {String(errorMessage)}
      </span>
    ) : null;
  };

  const handleResendEmail = () => {
    const email = getValues('email');
    if (!email) {
      return setShowResendLink(false);
    }
    resendLinkMutation.mutate({ email });
  };

  const getButtonText = () => {
    if (isLoading) {
      return 'Loading...';
    }
    return isAyzenbergDomain ? 'Continue with Okta' : 'Continue with Email';
  };

  if (magicLinkSent) {
    return (
      <div className="mx-auto max-w-[272px] text-center">
        <div className="mb-4 text-lg font-semibold text-[#0f172a] dark:text-white">
          Check your email
        </div>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          We&apos;ve sent a magic link to <strong>{email}</strong>. Click the
          link to sign in to your account.
        </p>
        <button
          onClick={() => setMagicLinkSent(false)}
          className="mt-4 text-sm text-[#BC0278] hover:text-[#F214A1] dark:text-[#F214A1] dark:hover:text-[#BC0278]"
        >
          Use a different email
        </button>
      </div>
    );
  }

  return (
    <>
      {showResendLink && (
        <div className="flex justify-center">
          <div className="mt-2 max-w-[272px] rounded-md border border-green-500 bg-green-500/10 px-3 py-2 text-center text-sm text-gray-600 dark:text-gray-200">
            {localize('com_auth_email_verification_resend_prompt')}
            <button
              type="button"
              className="ml-2 underline"
              onClick={handleResendEmail}
              disabled={resendLinkMutation.isLoading}
            >
              {localize('com_auth_email_resend_link')}
            </button>
          </div>
        </div>
      )}
      <div className="my-6">
        <p className="text-center text-base text-[#0f172a] dark:text-white">
          Log in to your Ayzenberg.AI account
        </p>
      </div>
      <form
        aria-label="Login form"
        method="POST"
        onSubmit={
          isAyzenbergDomain
            ? (e) => e.preventDefault()
            : handleSubmit(handleMagicLinkLogin)
        }
        className="mx-auto max-w-[272px]"
      >
        <div className="flex flex-col gap-2">
          <div className="mb-2">
            <div className="relative">
              <div
                className="rounded-lg p-[1px]"
                style={{
                  background:
                    'linear-gradient(91.969deg, #F214A1 0%, #BC0278 100%)',
                }}
              >
                <div className="rounded-lg bg-[#F2F2F2] dark:bg-[#0f172a]">
                  <input
                    type="text"
                    id="email"
                    autoComplete={useUsernameLogin ? 'username' : 'email'}
                    aria-label={localize('com_auth_email')}
                    {...register('email', {
                      required: localize('com_auth_email_required'),
                      maxLength: {
                        value: 120,
                        message: localize('com_auth_email_max_length'),
                      },
                      pattern: {
                        value: useUsernameLogin ? /\S+/ : /\S+@\S+\.\S+/,
                        message: localize('com_auth_email_pattern'),
                      },
                    })}
                    aria-invalid={!!errors.email}
                    className="form-input peer block w-full appearance-none px-4 py-3 text-base text-white focus:outline-none focus:ring-0 dark:text-white"
                    placeholder=" "
                  />
                  <label
                    htmlFor="email"
                    className="absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform px-3 text-sm text-[#0f172a] opacity-80 duration-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-3 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4 dark:text-white dark:peer-focus:text-white"
                  >
                    {useUsernameLogin
                      ? localize('com_auth_username').replace(/ \(.*$/, '')
                      : localize('com_auth_email_address')}
                  </label>
                </div>
              </div>
            </div>
            {renderError('email')}
          </div>
        </div>
        <div className="mt-8">
          {isAyzenbergDomain ? (
            <button
              type="button"
              aria-label="Sign in with Okta"
              data-testid="sso-button"
              onClick={handleSSOLogin}
              disabled={isLoading}
              className="auth-button h-12 w-full transform rounded-lg p-3 tracking-wide text-white transition-colors duration-200 hover:text-[#0F172A] focus:outline-none disabled:cursor-not-allowed"
            >
              {getButtonText()}
            </button>
          ) : (
            <button
              type="submit"
              aria-label="Sign in with magic link"
              data-testid="magic-link-button"
              disabled={isLoading}
              className="auth-button h-12 w-full transform rounded-lg p-3 tracking-wide text-white transition-colors duration-200 hover:text-[#0F172A] focus:outline-none disabled:cursor-not-allowed"
            >
              {getButtonText()}
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default LoginForm;
