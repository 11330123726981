// French phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_ui_examples: 'Exemples',
  com_ui_new_chat: 'Nouvelle conversation',
  com_ui_happy_birthday: 'C\'est mon premier anniversaire !',
  com_ui_example_quantum_computing: 'Expliquer l\'informatique quantique en termes simples',
  com_ui_example_10_year_old_b_day:
    'Tu as des idées créatives pour l\'anniversaire d\'un enfant de 10 ans ?',
  com_ui_example_http_in_js: 'Comment faire une requête HTTP en Javascript ?',
  com_ui_capabilities: 'Capacités',
  com_ui_capability_remember:
    'Se souvient de ce que l\'utilisateur a dit plus tôt dans la conversation',
  com_ui_capability_correction: 'Permet à l\'utilisateur de fournir des corrections de suivi',
  com_ui_capability_decline_requests: 'Formé à refuser les demandes inappropriées',
  com_ui_limitations: 'Limitations',
  com_ui_limitation_incorrect_info: 'Peut occasionnellement générer des informations incorrectes',
  com_ui_limitation_harmful_biased:
    'Peut occasionnellement produire des instructions nuisibles ou du contenu biaisé',
  com_ui_limitation_limited_2021: 'Connaissance limitée du monde et des événements après 2021',
  com_ui_experimental: 'Fonctionnalités expérimentales',
  com_ui_input: 'Entrée',
  com_ui_close: 'Fermer',
  com_ui_model: 'Modèle',
  com_ui_select_model: 'Sélectionner un modèle',
  com_ui_use_prompt: 'Utiliser le prompt',
  com_ui_prev: 'Précédent',
  com_ui_next: 'Suivant',
  com_ui_stop: 'Arrêt ',
  com_ui_prompt_templates: 'Modèles de prompt',
  com_ui_hide_prompt_templates: 'Masquer les modèles de prompt',
  com_ui_showing: 'Affichage',
  com_ui_of: 'des',
  com_ui_entries: 'entrées',
  com_ui_pay_per_call:
    'Toutes les conversations IA au même endroit. Payez à la demande et non par mois',
  com_ui_new_footer: 'Toutes les conversations IA au même endroit.',
  com_ui_enter: 'Entrer',
  com_ui_submit: 'Soumettre',
  com_ui_upload_success: 'Fichier téléversé avec succès',
  com_ui_upload_error: 'Une erreur s\'est produite lors du téléversement de votre fichier',
  com_ui_cancel: 'Annuler',
  com_ui_save: 'Sauvegarder',
  com_ui_save_submit: 'Enregistrer et Soumettre',
  com_user_message: 'Vous',
  com_ui_copy_to_clipboard: 'Copier dans le presse-papier',
  com_ui_copied_to_clipboard: 'Copié dans le presse-papier',
  com_ui_regenerate: 'Régénérer',
  com_ui_continue: 'Continuer',
  com_ui_edit: 'Modifier',
  com_ui_success: 'Succès',
  com_ui_all: 'tout',
  com_ui_clear: 'Effacer',
  com_ui_revoke: 'Révoquer',
  com_ui_revoke_info:
    'Révoquer toutes les informations d\'identification fournies par l\'utilisateur',
  com_ui_import_conversation: 'Importer',
  com_ui_import_conversation_info: 'Importer des conversations à partir d\'un fichier JSON',
  com_ui_import_conversation_success: 'Conversations importées avec succès',
  com_ui_import_conversation_error:
    'Une erreur s\'est produite lors de l\'importation de vos conversations',
  com_ui_confirm_action: 'Confirmer l\'action',
  com_ui_chats: 'discussions',
  com_ui_share: 'Partager',
  com_ui_share_var: 'Partager {0}',
  com_ui_share_to_all_users: 'Partager à tous les utilisateurs',
  com_ui_share_link_to_chat: 'Partager le lien de la discussion',
  com_ui_share_error: 'Une erreur est survenue lors du partage du lien de la discussion',
  com_ui_share_retrieve_error: 'Une erreur est survenue lors de la suppression du lien partagé.',
  com_ui_share_delete_error: 'Une erreur est survenue lors de la suppression du lien partagé.',
  com_ui_share_create_message:
    'Votre nom et tout message que vous ajoutez après le partage restent privés.',
  com_ui_share_created_message:
    'Un lien partagé vers votre conversation a été créé. Gérez les conversations précédemment partagées à tout moment via les paramètres.',
  com_ui_share_update_message:
    'Votre nom, les instructions personnalisées et tout message que vous ajoutez après le partage restent privés.',
  com_ui_share_updated_message:
    'Un lien partagé vers votre conversation a été mis à jour. Gérez les conversations précédemment partagées à tout moment via les paramètres.',
  com_ui_shared_link_not_found: 'Lien partagé introuvable',
  com_ui_prompt_preview_not_shared: 'L\'auteur n\'a pas autorisé la collaboration pour ce prompt.',
  com_ui_rename: 'Renommer',
  com_ui_renaming_var: 'Renommer "{0}"',
  com_ui_archive: 'Archiver',
  com_ui_archive_error: 'échec de l\'archivage de la conversation',
  com_ui_unarchive: 'Désarchiver',
  com_ui_unarchive_error: 'Échec du désarchivage de la conversation',
  com_ui_more_options: 'Plus',
  com_ui_preview: 'Aperçu',
  com_ui_upload: 'Téléverser',
  com_ui_connect: 'Connecter',
  com_ui_bookmarks: 'Signets',
  com_ui_bookmarks_rebuild: 'Reconstruire',
  com_ui_bookmarks_new: 'Nouveau signet',
  com_ui_bookmark_delete_confirm: 'Êtes-vous sûr de vouloir supprimer ce signet?',
  com_ui_bookmarks_title: 'Titre',
  com_ui_bookmarks_count: 'Nombre',
  com_ui_bookmarks_description: 'Description',
  com_ui_bookmarks_create_success: 'Signet créé avec succès',
  com_ui_bookmarks_update_success: 'Signet mis à jour avec succès',
  com_ui_bookmarks_delete_success: 'Signet supprimé avec succès',
  com_ui_bookmarks_create_error: 'Une erreur est survenue lors de la création du signet',
  com_ui_bookmarks_update_error: 'Une erreur est survenue lors de la mise à jour du signet',
  com_ui_bookmarks_delete_error: 'Une erreur est survenue lors de la suppression du signet',
  com_ui_bookmarks_add_to_conversation: 'Ajouter à la conversation en cours',
  com_ui_accept: 'J\'accepte',
  com_ui_decline: 'Je n\'accepte pas',
  com_ui_terms_and_conditions: 'Conditions d\'utilisation',
  com_ui_no_terms_content: 'Aucun contenu de conditions d\'utilisation à afficher',
  com_auth_error_login:
    'Impossible de se connecter avec les informations fournies. Veuillez vérifier vos identifiants et réessayer.',
  com_auth_error_login_rl:
    'Trop de tentatives de connexion en peu de temps. Veuillez réessayer plus tard.',
  com_auth_error_login_ban:
    'Votre compte a été temporairement banni en raison de violations de notre service.',
  com_auth_error_login_server:
    'Une erreur interne du serveur s\'est produite. Veuillez patienter quelques instants et réessayer.',
  com_auth_no_account: 'Vous n\'avez pas de compte?',
  com_auth_sign_up: 'S\'inscrire',
  com_auth_sign_in: 'Se connecter',
  com_auth_google_login: 'Se connecter avec Google',
  com_auth_facebook_login: 'Se connecter avec Facebook',
  com_auth_github_login: 'Se connecter avec Github',
  com_auth_discord_login: 'Se connecter avec Discord',
  com_auth_email: 'Courriel',
  com_auth_email_required: 'Le courriel est obligatoire',
  com_auth_email_min_length: 'Le courriel doit comporter au moins 6 caractères',
  com_auth_email_max_length: 'Le courriel ne doit pas dépasser 120 caractères',
  com_auth_email_pattern: 'Vous devez entrer une adresse courriel valide',
  com_auth_email_address: 'Adresse courriel',
  com_auth_password: 'Mot de passe',
  com_auth_password_required: 'Le mot de passe est obligatoire',
  com_auth_password_min_length: 'Le mot de passe doit comporter au moins 8 caractères',
  com_auth_password_max_length: 'Le mot de passe doit être inférieur à 128 caractères',
  com_auth_password_forgot: 'Mot de passe oublié?',
  com_auth_password_confirm: 'Confirmer le mot de passe',
  com_auth_password_not_match: 'Les mots de passe ne correspondent pas',
  com_auth_continue: 'Continuer',
  com_auth_create_account: 'Créez votre compte',
  com_auth_error_create:
    'Il y a eu une erreur lors de la tentative d\'enregistrement de votre compte. Veuillez réessayer.',
  com_auth_full_name: 'Nom complet',
  com_auth_name_required: 'Le nom est obligatoire',
  com_auth_name_min_length: 'Le nom doit comporter au moins 3 caractères',
  com_auth_name_max_length: 'Le nom doit être inférieur à 80 caractères',
  com_auth_username: 'Nom d\'utilisateur',
  com_auth_username_required: 'Le nom d\'utilisateur est obligatoire',
  com_auth_username_min_length: 'Le nom d\'utilisateur doit comporter au moins 3 caractères',
  com_auth_username_max_length: 'Le nom d\'utilisateur doit être inférieur à 20 caractères',
  com_auth_already_have_account: 'Vous avez déjà un compte ?',
  com_auth_login: 'Connexion',
  com_auth_reset_password: 'Réinitialiser votre mot de passe',
  com_auth_click: 'Cliquez',
  com_auth_here: 'ICI',
  com_auth_to_reset_your_password: 'pour réinitialiser votre mot de passe.',
  com_auth_reset_password_link_sent: 'Courriel envoyé',
  com_auth_reset_password_email_sent:
    'Un courriel vous a été envoyé avec des instructions supplémentaires pour réinitialiser votre mot de passe.',
  com_auth_error_reset_password:
    'Il y a eu un problème pour réinitialiser votre mot de passe. Aucun utilisateur n\'a été trouvé avec l\'adresse courriel fournie. Veuillez réessayer.',
  com_auth_reset_password_success: 'Réinitialisation du mot de passe réussie',
  com_auth_login_with_new_password:
    'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.',
  com_auth_error_invalid_reset_token:
    'Ce jeton de réinitialisation de mot de passe n\'est plus valide.',
  com_auth_click_here: 'Cliquez ici',
  com_auth_to_try_again: 'pour réessayer.',
  com_auth_submit_registration: 'Soumettre l\'inscription',
  com_auth_welcome_back: 'Bienvenue à nouveau',
  com_endpoint_open_menu: 'Ouvrir le menu',
  com_endpoint_bing_enable_sydney: 'Activer Sydney',
  com_endpoint_bing_to_enable_sydney: 'Pour activer Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing peut utiliser jusqu\'à 7k jetons pour le "contexte", qu\'il peut référencer pour la conversation. La limite spécifique n\'est pas connue mais peut entraîner des erreurs dépassant les 7k jetons',
  com_endpoint_bing_system_message_placeholder:
    'AVERTISSEMENT : L\'abus de cette fonctionnalité peut vous faire BANNIR de l\'utilisation de Bing! Cliquez sur "Message système" pour obtenir les instructions complètes et le message par défaut si omis, qui est le préréglage "Sydney" qui est considéré comme sûr.',
  com_endpoint_system_message: 'Message système',
  com_endpoint_message: 'Message',
  com_endpoint_message_not_appendable: 'Editer votre message ou regénerer.',
  com_endpoint_default_blank: 'par défaut : vide',
  com_endpoint_default_false: 'par défaut : faux',
  com_endpoint_default_creative: 'par défaut : créatif',
  com_endpoint_default_empty: 'par défaut : vide',
  com_endpoint_default_with_num: 'par défaut : {0}',
  com_endpoint_context: 'Contexte',
  com_endpoint_tone_style: 'Style de ton',
  com_endpoint_token_count: 'Nombre de jetons',
  com_endpoint_output: 'Sortie',
  com_endpoint_google_temp:
    'Des valeurs plus élevées = plus aléatoires, tandis que des valeurs plus faibles = plus concentrées et déterministes. Nous vous recommandons de modifier ceci ou Top P mais pas les deux.',
  com_endpoint_google_topp:
    'Top-p change la façon dont le modèle sélectionne les jetons pour la sortie. Les jetons sont sélectionnés du plus K (voir le paramètre topK) probable au moins jusqu\'à ce que la somme de leurs probabilités égale la valeur top-p.',
  com_endpoint_google_topk:
    'Top-k change la façon dont le modèle sélectionne les jetons pour la sortie. Un top-k de 1 signifie que le jeton sélectionné est le plus probable parmi tous les jetons du vocabulaire du modèle (également appelé décodage glouton), tandis qu\'un top-k de 3 signifie que le jeton suivant est sélectionné parmi les 3 jetons les plus probables (en utilisant la température).',
  com_endpoint_google_maxoutputtokens:
    'Nombre maximum de jetons qui peuvent être générés dans la réponse. Spécifiez une valeur plus faible pour des réponses plus courtes et une valeur plus élevée pour des réponses plus longues.',
  com_endpoint_google_custom_name_placeholder: 'Définir un nom personnalisé pour Google',
  com_endpoint_google_prompt_prefix_placeholder:
    'Définir des instructions ou un contexte personnalisés. Ignoré si vide.',
  com_endpoint_custom_name: 'Nom personnalisé',
  com_endpoint_prompt_prefix: 'Préfixe du prompt',
  com_endpoint_temperature: 'Température',
  com_endpoint_default: 'par défaut',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Nombre maximum de jetons en sortie',
  com_endpoint_openai_temp:
    'Des valeurs plus élevées = plus aléatoires, tandis que des valeurs plus faibles = plus concentrées et déterministes. Nous vous recommandons de modifier ceci ou Top P mais pas les deux.',
  com_endpoint_openai_max:
    'Le nombre maximum de jetons à générer. La longueur totale des jetons d\'entrée et des jetons générés est limitée par la longueur du contexte du modèle.',
  com_endpoint_openai_topp:
    'Une alternative à l\'échantillonnage avec température, appelée échantillonnage du noyau, où le modèle considère les résultats des jetons avec une masse de probabilité top_p. Ainsi, 0,1 signifie que seuls les jetons représentant les 10 % de masse de probabilité les plus élevés sont pris en compte. Nous vous recommandons de modifier ceci ou la température mais pas les deux.',
  com_endpoint_openai_freq:
    'Nombre compris entre -2,0 et 2,0. Les valeurs positives pénalisent les nouveaux jetons en fonction de leur fréquence existante dans le texte jusqu\'à présent, diminuant ainsi la probabilité que le modèle répète la même ligne mot pour mot.',
  com_endpoint_openai_pres:
    'Nombre compris entre -2,0 et 2,0. Les valeurs positives pénalisent les nouveaux jetons en fonction du fait qu\'ils apparaissent ou non dans le texte jusqu\'à présent, augmentant ainsi la probabilité que le modèle parle de nouveaux sujets.',
  com_endpoint_openai_resend:
    'Renvoyer toutes les images précédemment jointes. Remarque : cela peut augmenter considérablement le coût en jetons et vous pouvez rencontrer des erreurs avec de nombreuses pièces jointes d\'images.',
  com_endpoint_openai_detail:
    'La résolution pour les requêtes Vision. "Low" est moins cher et plus rapide, "High" est plus détaillé et plus cher, et "Auto" choisira automatiquement entre les deux en fonction de la résolution de l\'image.',
  com_endpoint_openai_custom_name_placeholder: 'Définir un nom personnalisé pour ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Définir des instructions personnalisées à inclure dans le message système. Par défaut : aucun',
  com_endpoint_anthropic_temp:
    'Varie de 0 à 1. Utilisez une température proche de 0 pour l\'analyse / le choix multiple, et proche de 1 pour les tâches créatives et génératives. Nous vous recommandons de modifier ceci ou Top P mais pas les deux.',
  com_endpoint_anthropic_topp:
    'Top-p change la façon dont le modèle sélectionne les jetons pour la sortie. Les jetons sont sélectionnés du plus K (voir le paramètre topK) probable au moins jusqu\'à ce que la somme de leurs probabilités égale la valeur top-p.',
  com_endpoint_anthropic_topk:
    'Top-k change la façon dont le modèle sélectionne les jetons pour la sortie. Un top-k de 1 signifie que le jeton sélectionné est le plus probable parmi tous les jetons du vocabulaire du modèle (également appelé décodage glouton), tandis qu\'un top-k de 3 signifie que le jeton suivant est sélectionné parmi les 3 jetons les plus probables (en utilisant la température).',
  com_endpoint_anthropic_maxoutputtokens:
    'Nombre maximum de jetons qui peuvent être générés dans la réponse. Spécifiez une valeur plus faible pour des réponses plus courtes et une valeur plus élevée pour des réponses plus longues.',
  com_endpoint_anthropic_custom_name_placeholder: 'Définir un nom personnalisé pour Anthropic',
  com_endpoint_frequency_penalty: 'Pénalité de fréquence',
  com_endpoint_presence_penalty: 'Pénalité de présence',
  com_endpoint_plug_use_functions: 'Utiliser les fonctions',
  com_endpoint_plug_resend_images: 'Renvoyer des images',
  com_endpoint_plug_skip_completion: 'Sauter la complétion',
  com_endpoint_disabled_with_tools: 'désactivé avec les outils',
  com_endpoint_disabled_with_tools_placeholder: 'Désactivé avec les outils sélectionnés',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Définir des instructions personnalisées à inclure dans le message système. Par défaut : aucun',
  com_endpoint_import: 'Importer',
  com_endpoint_set_custom_name:
    'Définir un nom personnalisé, au cas où vous trouveriez ce préréglage',
  com_endpoint_preset_delete_confirm: 'Êtes-vous sûr de vouloir supprimer ce préréglage?',
  com_endpoint_preset_clear_all_confirm: 'Êtes-vous sûr de vouloir supprimer tous vos préréglages?',
  com_endpoint_preset_import: 'Préréglage importé!',
  com_endpoint_preset_import_error:
    'Il y a eu une erreur lors de l\'importation de votre préréglage. Veuillez réessayer.',
  com_endpoint_preset_save_error:
    'Il y a eu une erreur lors de la sauvegarde de votre préréglage. Veuillez réessayer.',
  com_endpoint_preset_delete_error:
    'Il y a eu une erreur lors de la suppression de votre préréglage. Veuillez réessayer.',
  com_endpoint_preset_default_removed: 'n\'est plus le préréglage par défaut.',
  com_endpoint_preset_default_item: 'Par défaut :',
  com_endpoint_preset_default_none: 'Aucun préréglage par défaut actif.',
  com_endpoint_preset_title: 'Préréglage',
  com_endpoint_preset_saved: 'Enregistré!',
  com_endpoint_preset_default: 'est maintenant le préréglage par défaut.',
  com_endpoint_preset: 'préréglage',
  com_endpoint_presets: 'préréglages',
  com_endpoint_preset_selected: 'Préréglage actif!',
  com_endpoint_preset_selected_title: 'Actif!',
  com_endpoint_preset_name: 'Nom du préréglage',
  com_endpoint_new_topic: 'Nouveau sujet',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Masquer',
  com_endpoint_show: 'Afficher',
  com_endpoint_examples: ' Exemples',
  com_endpoint_completion: 'Complétion',
  com_endpoint_agent: 'Agent',
  com_endpoint_show_what_settings: 'Afficher les paramètres {0}',
  com_endpoint_save: 'Enregistrer',
  com_endpoint_export: 'Exporter',
  com_endpoint_export_share: 'Exporter/Partager',
  com_endpoint_save_as_preset: 'Enregistrer comme préréglage',
  com_endpoint_presets_clear_warning:
    'Etes-vous sûr de vouloir effacer tous les préréglages? Cette action est irréversible.',
  com_endpoint_not_implemented: 'Non implémenté',
  com_endpoint_no_presets:
    'Aucun préréglage pour l\'instant, utilisez le bouton paramètres pour en créer un',
  com_endpoint_not_available: 'Aucun endpoint disponible',
  com_endpoint_view_options: 'Voir les options',
  com_endpoint_save_convo_as_preset: 'Enregistrer la conversation comme préréglage',
  com_endpoint_my_preset: 'Mon préréglage',
  com_endpoint_agent_model: 'Modèle d\'agent (recommandé : GPT-3.5)',
  com_endpoint_completion_model: 'Modèle de complétion (recommandé : GPT-4)',
  com_endpoint_func_hover: 'Activer l\'utilisation des plugins comme fonctions OpenAI',
  com_endpoint_skip_hover:
    'Activer le saut de l\'étape de complétion, qui examine la réponse finale et les étapes générées',
  com_endpoint_config_key: 'Définir la clé API',
  com_endpoint_config_placeholder: 'Définissez votre clé dans le menu En-tête pour discuter.',
  com_endpoint_config_key_for: 'Définir la clé API pour',
  com_endpoint_config_key_name: 'Clé',
  com_endpoint_config_value: 'Entrez la valeur pour',
  com_endpoint_config_key_name_placeholder: 'Définir d\'abord la clé API',
  com_endpoint_config_key_encryption: 'Votre clé sera cryptée et supprimée à',
  com_endpoint_config_key_expiry: 'le délai d\'expiration',
  com_endpoint_config_click_here: 'Cliquez ici',
  com_endpoint_config_google_service_key: 'Clé de compte de service Google',
  com_endpoint_config_google_cloud_platform: '(de Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Clé API Google',
  com_endpoint_config_google_gemini_api: '(API Gemini)',
  com_endpoint_config_google_api_info:
    'Pour obtenir votre clé API de langage génératif (pour Gemini),',
  com_endpoint_config_key_import_json_key: 'Importez la clé JSON du compte de service.',
  com_endpoint_config_key_import_json_key_success:
    'Clé JSON du compte de service importé avec succès',
  com_endpoint_config_key_import_json_key_invalid:
    'Clé JSON du compte de service invalide. Avez-vous importé le bon fichier ?',
  com_endpoint_config_key_get_edge_key:
    'Pour obtenir votre jeton d\'accès pour Bing, connectez-vous à',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Utilisez dev tools ou une extension lorsque vous êtes connecté au site pour copier le contenu du cookie _U. Si cela échoue, suivez ces',
  com_endpoint_config_key_edge_instructions: 'instructions',
  com_endpoint_config_key_edge_full_key_string: 'pour fournir les chaînes complètes des cookies.',
  com_endpoint_config_key_chatgpt:
    'Pour obtenir votre jeton d\'accès pour ChatGPT \'Version gratuite\', se connecter à',
  com_endpoint_config_key_chatgpt_then_visit: 'puis visitez',
  com_endpoint_config_key_chatgpt_copy_token: 'Copiez le jeton d\'accès.',
  com_endpoint_config_key_google_need_to: 'Vous devez',
  com_endpoint_config_key_google_vertex_ai: 'Activer Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API sur Google Cloud, puis',
  com_endpoint_config_key_google_service_account: 'Créer un compte de service',
  com_endpoint_config_key_google_vertex_api_role:
    'Assurez-vous de cliquer \'Créer et continuer\' pour donner au moins le role \'Utilisateur de Vertex AI\'. Finalement, créez une clé JSON à importer ici.',
  com_nav_welcome_message: 'Comment puis-je vous aider aujourd\'hui?',
  com_nav_auto_scroll: 'Défilement automatique jusqu\'au plus récent à l\'ouverture',
  com_nav_modular_chat: 'Activer le changement de points de terminaison en cours de conversation',
  com_nav_latex_parsing: 'Analyse LaTeX dans les messages (peut affecter les performances)',
  com_nav_profile_picture: 'Photo de profil',
  com_nav_change_picture: 'Changer de photo',
  com_nav_plugin_store: 'Boutique de plugins',
  com_show_agent_settings: 'Afficher les paramètres de l\'agent',
  com_show_completion_settings: 'Afficher les paramètres de complétion',
  com_hide_examples: 'Masquer les exemples',
  com_show_examples: 'Afficher les exemples',
  com_nav_plugin_search: 'Rechercher des plugins',
  com_nav_plugin_auth_error:
    'Une erreur s\'est produite lors de la tentative d\'authentification de ce plugin. Veuillez réessayer.',
  com_nav_export_filename: 'Nom du fichier',
  com_nav_export_filename_placeholder: 'Définir le nom du fichier',
  com_nav_export_type: 'Type',
  com_nav_export_include_endpoint_options: 'Inclure les options d\'extrémité',
  com_nav_enabled: 'Activé',
  com_nav_not_supported: 'Non pris en charge',
  com_nav_export_all_message_branches: 'Exporter toutes les branches de messages',
  com_nav_export_recursive_or_sequential: 'Récursif ou séquentiel ?',
  com_nav_export_recursive: 'Récursif',
  com_nav_export_conversation: 'Exporter la conversation',
  com_nav_export: 'Exporter',
  com_nav_shared_links: 'Liens partagés',
  com_nav_shared_links_manage: 'Gérer',
  com_nav_shared_links_empty: 'Vous n\'avez aucun lien partagé.',
  com_nav_shared_links_name: 'Nom',
  com_nav_shared_links_date_shared: 'Date de partage',
  com_nav_theme: 'Thème',
  com_nav_theme_system: 'Système',
  com_nav_theme_dark: 'Sombre',
  com_nav_theme_light: 'Clair',
  com_nav_font_size: 'Taille de police',
  com_nav_user_name_display: 'Afficher le nom d\'utilisateur dans les messages',
  com_nav_save_drafts: 'Enregistrer les brouillons localement',
  com_nav_clear_all_chats: 'Effacer toutes les conversations',
  com_nav_confirm_clear: 'Confirmer l\'effacement',
  com_nav_close_sidebar: 'Fermer la barre latérale',
  com_nav_open_sidebar: 'Ouvrir la barre latérale',
  com_nav_send_message: 'Envoyer un message',
  com_nav_log_out: 'Se déconnecter',
  com_nav_user: 'UTILISATEUR',
  com_nav_archived_chats: 'Conversations archivées',
  com_nav_archived_chats_manage: 'Gérer',
  com_nav_archive_all_chats: 'Archiver toutes les conversations',
  com_nav_archive_all: 'Archiver tout',
  com_nav_archive_name: 'Nom',
  com_nav_archive_created_at: 'Créé Le',
  com_nav_clear_conversation: 'Effacer les conversations',
  com_nav_clear_conversation_confirm_message:
    'Êtes-vous sûr de vouloir effacer toutes les conversations ? Ceci est irréversible.',
  com_nav_help_faq: 'Aide & FAQ',
  com_nav_settings: 'Paramètres',
  com_nav_search_placeholder: 'Rechercher des messages',
  com_nav_info_bookmarks_rebuild:
    'Si le nombre de signets est incorrect, veuillez reconstruire les informations des signets. Le nombre de signets sera recalculé et les données seront restaurées à leur état correct.',
  com_nav_setting_general: 'Général',
  com_nav_setting_beta: 'Fonctionnalités bêta',
  com_nav_setting_data: 'Contrôles des données',
  com_nav_setting_account: 'Compte',
  com_assistants_file_search: 'Recherche de fichiers',
  com_assistants_file_search_info:
    'L\'ajout de vecteurs de stockage pour la recherche de fichiers n\'est pas encore pris en charge. Vous pouvez les ajouter depuis le terrain de jeu du fournisseur ou joindre des fichiers aux messages pour une recherche de fichiers au niveau du fil de discussion.',
  com_assistants_non_retrieval_model:
    'La recherche de fichiers n\'est pas activée pour ce modèle. Veuillez sélectionner un autre modèle.',
  com_ui_attach_error_openai:
    'Impossible de joindre les fichiers de l\'Assistant à d\'autres points d\'accès',
  com_ui_attach_warn_endpoint: 'Les fichiers non compatibles avec l\'outil peuvent être ignorés',
  com_ui_assistant_deleted: 'Assistant supprimé avec succès',
  com_ui_assistant_delete_error: 'Une erreur s\'est produite lors de la suppression de l\'assistant.',
  com_ui_copied: 'Copié !',
  com_ui_copy_code: 'Copier le code',
  com_ui_copy_link: 'Copier le lien',
  com_ui_update_link: 'Mettre à jour le lien',
  com_ui_create_link: 'Créer un lien',
  com_nav_source_chat: 'Afficher la conversation source',
  com_ui_date_today: 'Aujourd\'hui',
  com_ui_date_yesterday: 'Hier',
  com_ui_date_previous_7_days: '7 derniers jours',
  com_ui_date_previous_30_days: '30 derniers jours',
  com_ui_date_january: 'Janvier',
  com_ui_date_february: 'Février',
  com_ui_date_march: 'Mars',
  com_ui_date_april: 'Avril',
  com_ui_date_may: 'Mai',
  com_ui_date_june: 'Juin',
  com_ui_date_july: 'Juillet',
  com_ui_date_august: 'Août',
  com_ui_date_september: 'Septembre',
  com_ui_date_october: 'Octobre',
  com_ui_date_november: 'Novembre',
  com_ui_date_december: 'Décembre',
  com_ui_nothing_found: 'Aucun résultat trouvé',
  com_ui_go_to_conversation: 'Aller à la conversation',
  com_ui_add: 'Ajouter',
  com_nav_tool_remove: 'Supprimer',
  com_nav_tool_dialog: 'Outils de l\'assistant',
  com_nav_tool_dialog_description:
    'L\'assistant doit être sauvegardé pour conserver les sélections d\'outils.',
  com_nav_tool_search: 'Outils de recherche',
  com_nav_my_files: 'Mes fichiers',
  com_nav_enter_to_send: 'Appuyez sur Entrée pour envoyer des messages',
  com_nav_show_code: 'Toujours afficher le code lors de l\'utilisation de l\'interpréteur de code',
  com_nav_archived_chats_empty: 'Vous n\'avez aucune conversation archivée.',
  com_nav_language: 'Langue',
  com_nav_lang_auto: 'Détection automatique',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_polish: 'Polski',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
  com_nav_lang_russian: 'Русский',
  com_nav_lang_japanese: '日本語',
  com_nav_lang_swedish: 'Svenska',
  com_nav_lang_korean: '한국어',
  com_nav_lang_vietnamese: 'Tiếng Việt',
  com_nav_lang_traditionalchinese: '繁體中文',
  com_nav_lang_arabic: 'العربية',
  com_nav_lang_turkish: 'Türkçe',
  com_nav_lang_dutch: 'Nederlands',
  com_nav_lang_indonesia: 'Indonesia',
  com_nav_lang_hebrew: 'עברית',
  com_nav_lang_finnish: 'Suomi',
  com_error_moderation:
    'Il semble que le contenu soumis ait été signalé par notre système de modération pour ne pas être conforme à nos lignes directrices communautaires. Nous ne pouvons pas procéder avec ce sujet spécifique. Si vous avez d\'autres questions ou sujets que vous souhaitez explorer, veuillez modifier votre message ou créer une nouvelle conversation.',
  com_error_no_user_key: 'Aucune clé trouvée. Veuillez fournir une clé et réessayer.',
  com_error_no_base_url: 'Aucune URL de base trouvée. Veuillez en fournir une et réessayer.',
  com_error_invalid_user_key:
    'Clé fournie non valide. Veuillez fournir une clé valide et réessayer.',
  com_error_expired_user_key:
    'La clé fournie pour {0} a expiré à {1}. Veuillez fournir une clé et réessayer.',
  com_files_no_results: 'Aucun résultat.',
  com_files_filter: 'Filtrer les fichiers...',
  com_files_number_selected: '{0} sur {1} fichier(s) sélectionné(s)',
  com_sidepanel_select_assistant: 'Sélectionner un assistant',
  com_sidepanel_parameters: 'Paramètres',
  com_sidepanel_assistant_builder: 'Constructeur d\'assistant',
  com_sidepanel_hide_panel: 'Masquer le panneau',
  com_sidepanel_attach_files: 'Joindre des fichiers',
  com_sidepanel_manage_files: 'Gérer les fichiers',
  com_sidepanel_conversation_tags: 'Signets',
  com_assistants_capabilities: 'Capacités des assistants',
  com_assistants_knowledge: 'Connaissances',
  com_assistants_knowledge_info:
    'Si vous téléchargez des fichiers dans la section Connaissances, les conversations avec votre Assistant pourront inclure le contenu de ces fichiers.',
  com_assistants_knowledge_disabled:
    'L\'assistant doit être créé, et l\'interpréteur de code ou la récupération doivent être activés et enregistrés avant de pouvoir importer des fichiers en tant que connaissances.',
  com_assistants_image_vision: 'Vision d\'image',
  com_assistants_code_interpreter: 'Interpréteur de code',
  com_assistants_code_interpreter_files:
    'Les fichiers suivants sont disponibles uniquement pour l\'interpréteur de code :',
  com_assistants_retrieval: 'Récupération',
  com_assistants_search_name: 'Rechercher des assistants par nom',
  com_ui_tools: 'Outils',
  com_assistants_actions: 'Actions',
  com_assistants_add_tools: 'Ajouter des outils',
  com_assistants_add_actions: 'Ajouter des actions',
  com_assistants_available_actions: 'Actions disponibles',
  com_assistants_running_action: 'Action en cours',
  com_assistants_completed_action: 'A parlé à {0}',
  com_assistants_completed_function: 'A exécuté {0}',
  com_assistants_function_use: 'L\'assistant a utilisé {0}',
  com_assistants_domain_info: 'L\'assistant a envoyé ces informations à {0}',
  com_assistants_delete_actions_success: 'Action supprimée avec succès de l\'Assistant',
  com_assistants_update_actions_success: 'Action créée ou mise à jour avec succès',
  com_assistants_update_actions_error:
    'Une erreur s\'est produite lors de la création ou de la mise à jour de l\'action.',
  com_assistants_delete_actions_error:
    'Une erreur s\'est produite lors de la suppression de l\'action.',
  com_assistants_actions_info:
    'Permettez à votre Assistant de récupérer des informations ou d\'effectuer des actions via des API',
  com_assistants_name_placeholder: 'Nom de l\'assistant (facultatif)',
  com_assistants_instructions_placeholder: 'Les instructions système que l\'assistant utilise',
  com_assistants_description_placeholder: 'Décrivez votre assistant ici (facultatif)',
  com_assistants_actions_disabled: 'Vous devez créer un assistant avant d\'ajouter des actions.',
  com_assistants_update_success: 'Mise à jour réussie',
  com_assistants_update_error:
    'Une erreur s\'est produite lors de la mise à jour de votre assistant.',
  com_assistants_create_success: 'Création réussie',
  com_assistants_create_error: 'Une erreur s\'est produite lors de la création de votre assistant.',
  com_ui_field_required: 'Ce champ est obligatoire',
  com_ui_download_error:
    'Erreur lors du téléchargement du fichier. Le fichier a peut-être été supprimé.',
  com_ui_attach_error_type: 'Type de fichier non pris en charge pour ce point d\'accès :',
  com_ui_attach_error_size: 'Limite de taille de fichier dépassée pour le point de terminaison :',
  com_ui_attach_error:
    'Impossible de joindre le fichier. Créez ou sélectionnez une conversation, ou essayez d\'actualiser la page.',
  com_ui_on: 'Activé',
  com_ui_off: 'Désactivé',
  com_ui_yes: 'Oui',
  com_ui_no: 'Non',
  com_ui_ascending: 'Croissant',
  com_ui_descending: 'Décroissant',
  com_ui_show_all: 'Tout afficher',
  com_ui_name: 'Nom',
  com_ui_date: 'Date',
  com_ui_storage: 'Stockage',
  com_ui_context: 'Contexte',
  com_ui_size: 'Taille',
  com_ui_host: 'Hôte',
  com_ui_update: 'Mettre à jour',
  com_ui_authentication: 'Authentification',
  com_ui_instructions: 'Instructions',
  com_ui_description: 'Description',
  com_ui_error: 'Erreur',
  com_ui_select: 'Sélectionner',
  com_ui_select_search_model: 'Rechercher un modèle par nom',
  com_ui_select_search_plugin: 'Rechercher un plugin par nom',
  com_ui_upload_files: 'Téléverser des fichiers',
  com_ui_none_selected: 'Aucune sélection',
  com_ui_fork: 'Bifurquer',
  com_ui_fork_info_1:
    'Utilisez ce paramètre pour créer une bifurcation des messages avec le comportement souhaité.',
  com_ui_fork_info_2:
    '"Forker" fait référence à la création d\'une nouvelle conversation qui commence/se termine à partir de messages spécifiques dans la conversation actuelle, en créant une copie selon les options sélectionnées.',
  com_ui_fork_info_3:
    'Le terme "message cible" fait référence soit au message à partir duquel cette fenêtre contextuelle a été ouverte, soit, si vous cochez "{0}", au dernier message de la conversation.',
  com_ui_fork_info_visible:
    'Cette option permet de diviser uniquement les messages visibles ; en d\'autres termes, le chemin direct vers le message cible, sans aucune branche.',
  com_ui_fork_info_branches:
    'Cette option divise les messages visibles, ainsi que les branches associées ; en d\'autres termes, le chemin direct vers le message cible, y compris les branches le long du chemin.',
  com_ui_fork_info_target:
    'Cette option divise tous les messages menant au message cible, y compris ses voisins ; en d\'autres termes, toutes les branches de messages, qu\'elles soient visibles ou non et quel que soit leur chemin, sont incluses.',
  com_ui_fork_info_start:
    'Si cette option est cochée, le fork commencera à partir de ce message jusqu\'au dernier message de la conversation, selon le comportement sélectionné ci-dessus.',
  com_ui_fork_info_remember:
    'Cochez cette case pour mémoriser les options que vous sélectionnez pour une utilisation future, ce qui vous permettra de bifurquer plus rapidement les conversations selon vos préférences.',
  com_ui_fork_success: 'Conversation bifurquée avec succès',
  com_ui_fork_processing: 'Bifurquer la conversation...',
  com_ui_fork_error: 'Une erreur s\'est produite lors du dédoublement de la conversation',
  com_ui_fork_change_default: 'Option de fourche par défaut',
  com_ui_fork_default: 'Utiliser l\'option de fourche par défaut',
  com_ui_fork_remember: 'Se souvenir',
  com_ui_fork_split_target_setting: 'Démarrer la bifurcation à partir du message cible par défaut',
  com_ui_fork_split_target: 'Démarrer la bifurcation ici',
  com_ui_fork_remember_checked:
    'Votre sélection sera mémorisée après utilisation. Vous pouvez la modifier à tout moment dans les paramètres.',
  com_ui_fork_all_target: 'Inclure tout à partir d\'ici',
  com_ui_fork_branches: 'Inclure les branches associées',
  com_ui_fork_visible: 'Messages visibles uniquement',
  com_ui_fork_from_message: 'Sélectionner une option de bifurcation',
  com_ui_mention:
    'Mentionnez un point de terminaison, un assistant ou un préréglage pour basculer rapidement vers celui-ci',
  com_ui_import_conversation_file_type_error:
    'Type de fichier non pris en charge pour l\'importation',
  com_ui_avatar: 'Avatar',
  com_ui_unknown: 'Inconnu',
  com_ui_result: 'Résultat',
  com_ui_image_gen: 'Génération d\'image',
  com_ui_assistant: 'Assistant',
  com_ui_assistants: 'Assistants virtuels',
  com_ui_attachment: 'Pièce jointe',
  com_ui_assistants_output: 'Sortie des assistants',
  com_ui_create: 'Créer',
  com_ui_delete_assistant_confirm:
    'Êtes-vous sûr de vouloir supprimer cet Assistant ? Cette action est irréversible.',
  com_ui_upload_delay:
    'Le téléversement de "{0}" prend plus de temps que prévu. Veuillez patienter pendant que le fichier termine son indexation pour la récupération.',
  com_ui_privacy_policy: 'Politique de confidentialité',
  com_ui_terms_of_service: 'Conditions d\'utilisation',
  com_ui_min_tags: 'Impossible de supprimer plus de valeurs, un minimum de {0} est requis.',
  com_ui_max_tags: 'Le nombre maximum autorisé est {0}, en utilisant les dernières valeurs.',
  com_auth_back_to_login: 'Retour à la connexion',
  com_endpoint_context_tokens: 'Jetons de contexte maximum',
  com_endpoint_context_info:
    'Le nombre maximum de jetons qui peuvent être utilisés pour le contexte. Utilisez ceci pour contrôler le nombre de jetons envoyés par requête. Si non spécifié, les valeurs par défaut du système seront utilisées en fonction de la taille de contexte connue des modèles. Définir des valeurs plus élevées peut entraîner des erreurs et/ou un coût en jetons plus élevé.',
  com_endpoint_prompt_prefix_placeholder:
    'Définir des instructions ou un contexte personnalisé. Ignoré si vide.',
  com_endpoint_instructions_assistants_placeholder:
    'Remplace les instructions de l\'assistant. Cela est utile pour modifier le comportement au cas par cas.',
  com_endpoint_prompt_prefix_assistants_placeholder:
    'Définir des instructions ou un contexte supplémentaire en plus des instructions principales de l\'Assistant. Ignoré si vide.',
  com_endpoint_prompt_prefix_assistants: 'Instructions supplémentaires pour les assistants',
  com_endpoint_instructions_assistants: 'Instructions de remplacement',
  com_endpoint_stop: 'Séquences d\'arrêt',
  com_endpoint_stop_placeholder: 'Séparez les valeurs en appuyant sur `Entrée`',
  com_endpoint_openai_max_tokens:
    'Champ `max_tokens` optionnel, représentant le nombre maximum de jetons pouvant être générés dans la complétion de conversation. La longueur totale des jetons d\'entrée et des jetons générés est limitée par la longueur du contexte du modèle. Vous pouvez rencontrer des erreurs si ce nombre dépasse le maximum de jetons de contexte.',
  com_endpoint_openai_resend_files:
    'Renvoyer tous les fichiers précédemment joints. Remarque : cela augmentera le coût en jetons et vous pourriez rencontrer des erreurs avec de nombreuses pièces jointes.',
  com_endpoint_openai_stop: 'Jusqu\'à 4 séquences où l\'API cessera de générer d\'autres jetons.',
  com_endpoint_plug_resend_files: 'Renvoyer les fichiers',
  com_endpoint_plug_image_detail: 'Détail de l\'image',
  com_endpoint_assistant: 'Assistant de point de terminaison',
  com_endpoint_use_active_assistant: 'Utiliser l\'assistant actif',
  com_endpoint_assistant_model: 'Modèle d\'assistant',
  com_endpoint_assistant_placeholder:
    'Veuillez sélectionner un assistant dans le panneau latéral droit',
  com_nav_welcome_assistant: 'Veuillez sélectionner un assistant',
  com_nav_hide_panel: 'Masquer le panneau latéral le plus à droite',
  com_nav_plugin_install: 'Installer',
  com_nav_plugin_uninstall: 'Désinstaller',
  com_ui_latest_footer: 'Chaque IA pour tout le monde.',
  com_ui_upload_invalid:
    'Fichier non valide pour le téléchargement. L\'image ne doit pas dépasser la limite',
  com_ui_upload_invalid_var:
    'Fichier non valide pour le téléchargement. L\'image ne doit pas dépasser {0} Mo',
  com_ui_read_aloud: 'Lire à haute voix',
  com_ui_add_model_preset: 'Ajouter un modèle ou un préréglage pour une réponse supplémentaire',
  com_ui_loading: 'Chargement...',
  com_ui_all_proper: 'Tout',
  com_ui_chat: 'Discussion',
  com_ui_dashboard: 'Tableau de bord',
  com_ui_my_prompts: 'Mes Prompts',
  com_ui_no_category: 'Aucune catégorie',
  com_ui_shared_prompts: 'Prompts partagés',
  com_ui_prompts_allow_use: 'Autoriser l\'utilisation de Prompts',
  com_ui_prompts_allow_create: 'Autoriser la création de Prompts',
  com_ui_prompts_allow_share_global: 'Autoriser le partage des Prompts à tous les utilisateurs',
  com_ui_prompt_shared_to_all: 'Ce prompt est partagé à tous les utilisateurs',
  com_ui_prompt_already_shared_to_all: 'Ce prompt est déjà partagé à tous les utilisateurs',
  com_ui_description_placeholder: 'Optionnel : Entrez une description à afficher dans le prompt',
  com_ui_no_prompt_description: 'Aucune description trouvée.',
  com_ui_prompt: 'Prompt',
  com_ui_prompts: 'Prompts',
  com_ui_prompt_name: 'Nom du Prompt',
  com_ui_delete_prompt: 'Supprimer le Prompt?',
  com_ui_admin: 'Administrateur',
  com_ui_simple: 'Simple',
  com_ui_versions: 'Versions',
  com_ui_version_var: 'Version {0}',
  com_ui_advanced: 'Avancé',
  com_ui_admin_settings: 'Paramètres administratifs',
  com_ui_error_save_admin_settings:
    'Une erreur est survenue lors de la sauvegarde de vos paramètres administratifs.',
  com_ui_prompt_name_required: 'Un nom pour le Prompt est requis',
  com_ui_prompt_text_required: 'Un texte est requis',
  com_ui_prompt_text: 'Texte',
  com_ui_back_to_chat: 'Retour à la discussion',
  com_ui_back_to_prompts: 'Retour aux Prompts',
  com_ui_categories: 'Catégories',
  com_ui_filter_prompts_name: 'Filtrer les prompts par nom',
  com_ui_search_categories: 'Rechercher des catégories',
  com_ui_manage: 'Gérer',
  com_ui_variables: 'Variables',
  com_ui_variables_info:
    'Utilisez des doubles accolades dans votre texte pour créer des variables, par exemple {{exemple de variable}}, à remplir ultérieurement lors de l\'utilisation du prompt.',
  com_ui_special_variables:
    'Variables spéciales : Utilisez {{current_date}} pour la date actuelle, et {{current_user}} pour le nom de votre compte donné.',
  com_ui_delete: 'Supprimer',
  com_ui_delete_conversation: 'Supprimer la discussion?',
  com_ui_delete_confirm: 'Cela supprimera',
  com_ui_delete_confirm_prompt_version_var:
    'Cela supprimera la version sélectionnée pour "{0}." S\'il n\'existe aucune autre version, le prompt sera supprimé.',
  com_auth_error_login_unverified:
    'Votre compte n\'a pas été vérifié. Veuillez vérifier que vous avez reçu un lien de vérification dans votre courriel.',
  com_auth_registration_success_insecure: 'Inscription réussie.',
  com_auth_registration_success_generic:
    'Veuillez visiter votre courriel pour la vérification de celui-ci.',
  com_nav_text_to_speech: 'Texte en parole',
  com_nav_automatic_playback: 'Lecture automatique du dernier message (externe seulement)',
  com_nav_speech_to_text: 'Parole en texte',
  com_nav_delete_account: 'Supprimer le compte',
  com_nav_delete_account_confirm: 'Supprimer le compte - êtes-vous sûr?',
  com_nav_delete_account_button: 'Supprimer définitivement mon compte',
  com_nav_delete_account_email_placeholder: 'Veuillez entrer votre courriel de compte',
  com_nav_delete_account_confirm_placeholder:
    'Pour continuer, saisissez "DELETE" dans le champ ci-dessous',
  com_nav_delete_warning: 'ATTENTION : Cela supprimera définitivement votre compte.',
  com_nav_delete_data_info: 'Toutes vos données seront supprimées.',
  com_nav_delete_cache_storage: 'Supprimer le stockage du cache TTS',
  com_nav_conversation_mode: 'Mode conversation',
  com_nav_auto_send_text: 'Envoi automatique du texte (après 3 sec)',
  com_nav_auto_transcribe_audio: 'Transcription audio automatique',
  com_nav_db_sensitivity: 'Sensibilité en décibels',
  com_nav_playback_rate: 'Taux de lecture audio',
  com_nav_audio_play_error: 'Erreur de lecture audio : {0}',
  com_nav_audio_process_error: 'Erreur de traitement audio : {0}',
  com_nav_long_audio_warning: 'Les textes plus longs prendront plus de temps à traiter.',
  com_nav_engine: 'Moteur',
  com_nav_browser: 'Navigateur',
  com_nav_external: 'Externe',
  com_nav_enable_cache_tts: 'Activer le cache TTS',
  com_nav_voice_select: 'Voix',
  com_nav_info_enter_to_send:
    'Lorsqu\'activée, appuyez sur la touche ENTRÉE pour envoyer votre message. Lorsque désactivée, appuyez sur Entrée ajoutera une nouvelle ligne, et vous devrez appuyer sur CTRL + ENTRÉE pour envoyer votre message.',
  com_nav_info_save_draft:
    'Lorsqu\'activé, le texte et les pièces jointes que vous entrez dans le formulaire de chat seront automatiquement sauvegardés localement sous forme de brouillons. Ces brouillons seront disponibles même si vous actualisez la page ou passez à une conversation différente. Les brouillons sont stockés localement sur votre appareil et sont supprimés une fois le message envoyé.',
  com_nav_info_fork_change_default:
    'Messages visibles uniquement, inclut uniquement le chemin direct vers le message sélectionné. Inclure les branches liées, ajoute des branches tout au long du chemin. Inclure tous depuis/jusque là, inclut tous les messages et branches connectés.',
  com_nav_info_fork_split_target_setting:
    'Lorsqu\'activé, le forking commencera du message cible jusqu\'au dernier message de la conversation, selon le comportement sélectionné.',
  com_nav_info_user_name_display:
    'Lorsqu\'activé, le nom d\'utilisateur de l\'expéditeur sera affiché au-dessus de chaque message que vous envoyez. Lorsque désactivé, vous verrez seulement "Vous" au-dessus de vos messages.',
  com_nav_info_latex_parsing:
    'Lorsqu\'activé, le code LaTeX dans les messages sera rendu comme des équations mathématiques. Désactiver cela peut améliorer les performances si vous n\'avez pas besoin du rendu LaTeX.',
  com_nav_info_revoke:
    'Cette action révoquera et supprimera toutes les clés API que vous avez fournies. Vous devrez saisir à nouveau ces informations d\'identification pour continuer à utiliser ces points de terminaison.',
  com_nav_info_delete_cache_storage:
    'Cette action supprimera tous les fichiers audio TTS (Text-to-Speech) mis en cache stockés sur votre appareil. Les fichiers audio mis en cache sont utilisés pour accélérer la lecture des fichiers audio TTS générés précédemment, mais ils peuvent consommer de l\'espace de stockage sur votre appareil.',
  com_nav_setting_speech: 'Parole',
  com_ui_create_prompt: 'Créer un prompt',
  com_nav_auto_send_prompts: 'Envoi automatique des prompts',
  com_nav_always_make_prod: 'Rendre toujours les nouvelles versions en production',
  com_ui_error_connection: 'Erreur de connexion au serveur, essayez de rafraîchir la page.',
  com_ui_locked: 'Verrouillé',
  com_ui_use_micrphone: 'Utiliser le microphone',
  com_auth_email_verification_failed: 'Échec de la vérification du courriel',
  com_auth_email_verification_rate_limited: 'Trop de requêtes. Veuillez réessayer plus tard',
  com_auth_email_verification_success: 'Courriel vérifié avec succès',
  com_auth_email_resent_success: 'Courriel de vérification renvoyé avec succès',
  com_auth_email_resent_failed: 'Échec de la réexpédition du courriel de vérification',
  com_auth_email_verification_failed_token_missing: 'Échec de la vérification, jeton manquant',
  com_auth_email_verification_invalid: 'Vérification du courriel invalide',
  com_auth_email_verification_in_progress: 'Vérification de votre courriel, veuillez patienter',
  com_auth_email_verification_resend_prompt: 'Vous n\'avez pas reçu de courriel ?',
  com_auth_email_resend_link: 'Renvoyer un courriel',
  com_auth_email_verification_redirecting: 'Redirection dans {0} secondes...',
  com_auth_reset_password_if_email_exists:
    'Si un compte avec ce courriel existe, un courriel avec des instructions de réinitialisation de mot de passe a été envoyé. Assurez-vous de vérifier votre dossier de courrier indésirable.',
  com_nav_convo_menu_options: 'Options du menu de conversation',
  com_ui_artifacts: 'Artefacts',
  com_ui_artifacts_toggle: 'Afficher/Masquer l\'interface des artefacts',
  com_ui_include_shadcnui: 'Inclure les instructions des composants shadcn/ui',
  com_nav_info_code_artifacts:
    'Active l\'affichage des artéfacts de code expérimentaux à côté du chat',
  com_ui_custom_prompt_mode: 'Mode de prompt personnalisé',
  com_a11y_start: 'L\'IA a commencé sa réponse',
  com_ui_artifact_click: 'Cliquer pour ouvrir',
  com_nav_info_custom_prompt_mode:
    'Lorsqu\'activé, le prompt système par défaut pour les artéfacts ne sera pas inclus. Toutes les instructions de génération d\'artéfacts doivent être fournies manuellement dans ce mode.',
  com_a11y_ai_composing: 'L\'IA est en train de composer',
  com_nav_info_include_shadcnui:
    'Lorsque cette option est activée, les instructions d\'utilisation des composants shadcn/ui seront incluses. shadcn/ui est une collection de composants réutilisables construits avec Radix UI et Tailwind CSS. Note : ces instructions sont détaillées, il est conseillé de ne les activer que si l\'indication des importations et des composants corrects est importante pour vous. Pour plus d\'informations sur ces composants, visitez : https://ui.shadcn.com/',
  com_a11y_end: 'L\'IA a terminé sa réponse',
  com_warning_resubmit_unsupported:
    'La resoumission du message IA n\'est pas prise en charge pour ce point de terminaison.',
  com_error_no_system_messages:
    'Le service ou modèle d\'IA sélectionné ne prend pas en charge les messages système. Essayez d\'utiliser des prompts au lieu d\'instructions personnalisées.',
  com_error_invalid_request_error:
    'Le service d\'IA a rejeté la requête en raison d\'une erreur. Cela peut être dû à une clé API non valide ou à une requête mal formatée.',
  com_error_files_empty: 'Les fichiers vides ne sont pas autorisés',
  com_error_files_dupe: 'Fichier en double détecté.',
  com_error_input_length:
    'Le nombre de jetons du dernier message est trop élevé et dépasse la limite autorisée ({0}). Veuillez raccourcir votre message, ajuster la taille maximale du contexte dans les paramètres de conversation, ou créer une nouvelle branche de conversation pour continuer.',
  com_error_files_validation: 'Une erreur s\'est produite lors de la validation du fichier.',
  com_error_files_process: 'Une erreur s\'est produite lors du traitement du fichier.',
  com_error_files_upload: 'Une erreur s\'est produite lors du téléversement du fichier',
  com_generated_files: 'Fichiers générés :',
  com_error_files_upload_canceled:
    'La demande de téléversement du fichier a été annulée. Remarque : le téléversement peut être toujours en cours de traitement et devra être supprimé manuellement.',
  com_download_expired: 'Téléchargement expiré',
  com_download_expires: '(cliquez ici pour télécharger - expire le {0})',
  com_click_to_download: '(cliquez ici pour télécharger)',
  com_assistants_conversation_starters: 'Suggestions de conversation',
  com_assistants_code_interpreter_info:
    'L\'interpréteur de code permet à l\'assistant d\'écrire et d\'exécuter du code. Cet outil peut traiter des fichiers avec différents formats de données et générer des fichiers tels que des graphiques.',
  com_assistants_conversation_starters_placeholder: 'Saisissez une amorce de conversation',
  com_sidepanel_agent_builder: 'Constructeur d\'agent',
  com_agents_name_placeholder: 'Facultatif : Le nom de l\'agent',
  com_agents_instructions_placeholder: 'Les instructions système que l\'agent utilise',
  com_agents_description_placeholder: 'Décrivez votre Agent ici (facultatif)',
  com_agents_update_error: 'Une erreur s\'est produite lors de la mise à jour de votre agent',
  com_agents_search_name: 'Rechercher des agents par nom',
  com_agents_create_error: 'Une erreur s\'est produite lors de la création de votre agent.',
  com_agents_missing_provider_model:
    'Veuillez sélectionner un fournisseur et un modèle avant de créer un agent.',
  com_agents_allow_editing: 'Autoriser les autres utilisateurs à modifier votre agent',
  com_agents_not_available: 'Agent non disponible',
  com_agents_no_access: 'Vous n\'avez pas l\'autorisation de modifier cet agent.',
  com_agents_enable_file_search: 'Activer la recherche de fichiers',
  com_agents_file_search_info:
    'Lorsque cette option est activée, l\'agent sera informé des noms exacts des fichiers listés ci-dessous, lui permettant d\'extraire le contexte pertinent de ces fichiers.',
  com_agents_execute_code: 'Exécuter le code',
  com_agents_file_search_disabled:
    'L\'agent doit être créé avant de pouvoir télécharger des fichiers pour la Recherche de Fichiers.',
  com_ui_agent_already_shared_to_all: 'Cet agent est déjà partagé avec tous les utilisateurs',
  com_ui_agent_editing_allowed: 'D\'autres utilisateurs peuvent déjà modifier cet agent',
  com_ui_provider: 'Fournisseur',
  com_ui_endpoint: 'Point de terminaison',
  com_ui_no_changes: 'Aucune modification à mettre à jour',
  com_ui_model_parameters: 'Paramètres du modèle',
  com_ui_region: 'Région',
  com_ui_select_region: 'Sélectionner une région',
  com_ui_model_save_success: 'Paramètres du modèle sauvegardés avec succès',
  com_ui_select_provider: 'Sélectionner un fournisseur',
  com_ui_select_provider_first: 'Sélectionnez d\'abord un fournisseur',
  com_ui_special_variables_info:
    'Utilisez `{{current_date}}` pour la date actuelle et `{{current_user}}` pour votre nom de compte.',
  com_ui_select_search_region: 'Rechercher une région par nom',
  com_ui_dropdown_variables: 'Variables déroulantes :',
  com_assistants_max_starters_reached: 'Nombre maximum de démarreurs de conversation atteint',
  com_ui_dropdown_variables_info:
    'Créez des menus déroulants personnalisés pour vos prompts : `{{nom_variable:option1|option2|option3}}`',
  com_ui_revoke_keys: 'Révoquer les clés',
  com_ui_revoke_key_endpoint: 'Révoquer la clé pour {0}',
  com_ui_revoke_keys_confirm: 'Êtes-vous sûr de vouloir révoquer toutes les clés ?',
  com_ui_revoke_key_confirm: 'Êtes-vous sûr de vouloir révoquer cette clé ?',
  com_ui_controls: 'Contrôles',
  com_ui_chat_history: 'Historique des discussions',
  com_ui_agent: 'Agent',
  com_ui_agent_deleted: 'Agent supprimé avec succès',
  com_ui_agent_delete_error: 'Une erreur s\'est produite lors de la suppression de l\'agent',
  com_ui_delete_agent_confirm: 'Êtes-vous sûr de vouloir supprimer cet agent ?',
  com_ui_agents: 'Agents',
  com_ui_enter_var: 'Saisir {0}',
  com_ui_prompt_update_error: 'Une erreur s\'est produite lors de la mise à jour du prompt',
  com_ui_command_placeholder:
    'Facultatif : Saisissez une commande pour l\'invite ou le nom sera utilisé',
  com_ui_command_usage_placeholder: 'Sélectionnez un prompt par commande ou par nom',
  com_ui_delete_tool_confirm: 'Êtes-vous sûr de vouloir supprimer cet outil ?',
  com_ui_delete_tool: 'Supprimer l\'outil',
  com_ui_delete_action: 'Supprimer l\'action',
  com_ui_delete_action_confirm: 'Êtes-vous sûr de vouloir supprimer cette action ?',
  com_ui_bookmarks_create_exists: 'Ce signet existe déjà',
  com_ui_bookmarks_filter: 'Filtrer les favoris...',
  com_ui_bookmarks_delete: 'Supprimer le signet',
  com_ui_no_bookmarks:
    'Il semble que vous n\'ayez pas encore de favoris. Cliquez sur une discussion pour en ajouter un',
  com_ui_add_multi_conversation: 'Ajouter plusieurs conversations',
  com_ui_no_conversation_id: 'Aucun identifiant de conversation trouvé',
  com_endpoint_search: 'Rechercher un endpoint par nom',
  com_endpoint_anthropic_prompt_cache:
    'La mise en cache des prompts permet de réutiliser les contextes ou instructions volumineux entre les appels API, réduisant ainsi les coûts et la latence',
  com_endpoint_prompt_cache: 'Utiliser la mise en cache des prompts',
  com_nav_font_size_xs: 'Très petit',
  com_endpoint_config_key_never_expires: 'Votre clé n\'expirera jamais',
  com_nav_account_settings: 'Paramètres du compte',
  com_nav_font_size_sm: 'Petit',
  com_nav_font_size_base: 'Moyen',
  com_nav_font_size_lg: 'Grand',
  com_nav_font_size_xl: 'Très grand',
  com_nav_user_msg_markdown: 'Interpréter les messages utilisateur en markdown',
  com_nav_tool_dialog_agents: 'Outils de l\'agent',
  com_ui_misc: 'Divers',
  com_ui_roleplay: 'Jeu de rôle',
  com_ui_write: 'Écriture',
  com_ui_idea: 'Idées',
  com_ui_finance: 'Finance',
  com_ui_shop: 'Boutique',
  com_ui_code: 'Code',
  com_ui_travel: 'Voyage',
  com_ui_teach_or_explain: 'Apprentissage',
  com_ui_select_file: 'Sélectionner un fichier',
  com_ui_upload_image: 'Téléverser une image',
  com_ui_select_a_category: 'Aucune catégorie sélectionnée',
  com_ui_drag_drop_file: 'Glissez-déposez un fichier ici',
  com_nav_chat_direction: 'Direction de la conversation',
  com_ui_clear_all: 'Tout effacer',
  com_nav_clear_cache_confirm_message: 'Êtes-vous sûr de vouloir vider le cache ?',
  com_nav_stop_generating: 'Arrêter la génération',
  com_nav_tts_init_error: 'Échec de l\'initialisation de la synthèse vocale : {0}',
  com_nav_auto_send_text_disabled: 'définir sur -1 pour désactiver',
  com_nav_tts_unsupported_error:
    'La synthèse vocale pour le moteur sélectionné n\'est pas prise en charge par ce navigateur.',
  com_nav_media_source_init_error:
    'Impossible de préparer le lecteur audio. Veuillez vérifier les paramètres de votre navigateur.',
  com_nav_source_buffer_error:
    'Erreur lors de la configuration de la lecture audio. Veuillez actualiser la page.',
  com_nav_buffer_append_error:
    'Problème avec la diffusion audio. La lecture peut être interrompue.',
  com_nav_speech_cancel_error:
    'Impossible d\'arrêter la lecture audio. Vous devrez peut-être actualiser la page.',
  com_nav_voices_fetch_error:
    'Impossible de récupérer les options vocales. Veuillez vérifier votre connexion Internet.',
  com_nav_edge: 'Edge',
  com_nav_enable_cloud_browser_voice: 'Utiliser les voix cloud',
  com_nav_chat_commands: 'Commandes de chat',
  com_nav_commands_tab: 'Paramètres des commandes',
  com_nav_commands: 'Commandes',
  com_nav_chat_commands_info:
    'Ces commandes sont activées en tapant des caractères spécifiques au début de votre message. Chaque commande est déclenchée par son préfixe désigné. Vous pouvez les désactiver si vous utilisez fréquemment ces caractères pour commencer vos messages.',
  com_nav_at_command: 'Commande-@',
  com_nav_at_command_description:
    'Basculer la commande "@" pour changer d\'endpoints, de modèles, de préréglages, etc.',
  com_nav_plus_command_description:
    'Basculer la commande "+" pour ajouter un paramètre de réponses multiples',
  com_nav_plus_command: '+-Commande',
  com_nav_slash_command: '/-Commande',
  com_nav_slash_command_description:
    'Basculer la commande "/" pour sélectionner une invite via le clavier',
  com_nav_command_settings: 'Paramètres des commandes',
  com_nav_command_settings_description:
    'Personnaliser les commandes disponibles dans la conversation',
  com_nav_no_search_results: 'Aucun résultat de recherche trouvé',
  com_nav_setting_chat: 'Chat',
};
