import React, { useState, useRef } from 'react';
import IconMinus from '/assets/icons/iconMinus.svg';
import LightIconMinus from '/assets/icons/lightIconMinus.svg';
import './Accordion.css';

type AccordionProps = {
  title: string;
  paragraphs: string[];
  buttonText?: string | null;
};

const Accordion: React.FC<AccordionProps> = ({
  title,
  paragraphs,
  buttonText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion-background ${isOpen ? 'open' : ''}`}>
      <div
        className="flex cursor-pointer items-center justify-between p-6"
        onClick={toggleSection}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleSection();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <h3 className="magenta-to-yellow-title-gradient text-xl font-medium leading-7">
          {title}
        </h3>
        <div className="relative">
          {/* light icon */}
          <div>
            <img
              src={LightIconMinus}
              alt={isOpen ? 'Open' : 'Close'}
              className="h-6 w-6 transition-transform duration-200 ease-linear dark:hidden"
              style={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
              }}
            />
            <img
              src={LightIconMinus}
              alt={isOpen ? 'Close' : 'Open'}
              className="absolute left-0 top-0 h-6 w-6 transition-transform duration-200 ease-linear dark:hidden"
              style={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(0deg)',
              }}
            />
          </div>

          {/* dark icon */}
          <div>
            <img
              src={IconMinus}
              alt={isOpen ? 'Open' : 'Close'}
              className="hidden h-6 w-6 transition-transform duration-200 ease-linear dark:block"
              style={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
              }}
            />
            <img
              src={IconMinus}
              alt={isOpen ? 'Close' : 'Open'}
              className="absolute left-0 top-0 hidden h-6 w-6 transition-transform duration-200 ease-linear dark:block"
              style={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(0deg)',
              }}
            />
          </div>
        </div>
      </div>
      <div
        ref={contentRef}
        className="transition-max-height overflow-hidden opacity-100 duration-500 ease-in-out"
        style={{
          maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px',
        }}
      >
        <div className="p-4">
          {paragraphs.map((paragraph, index) => (
            <p
              key={index}
              className="text-text-primary text-base font-normal leading-normal"
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))}
          {buttonText && (
            <button className="bg-primary-500 hover:bg-primary-600 rounded-md px-4 py-2 text-white transition-colors">
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
