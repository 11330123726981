import { cn } from '~/utils';

export default function ContinueIcon({
  className = '',
}: {
  className?: string;
}) {
  return (
    <svg
      fill="none" // Ensure no fill is applied
      stroke="currentColor" // Use currentColor for the stroke, controlled by CSS
      strokeWidth="2" // Adjust stroke width as necessary
      viewBox="0 0 24 24"
      className={cn('icon-gradient h-3 w-3 -rotate-180', className)} // Apply the icon-gradient class
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Define the gradient for hover */}
      <defs>
        <linearGradient id="gradient-fill" x1="0" y1="0" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#F214A1" />
          <stop offset="100%" stopColor="#BC0278" />
        </linearGradient>
      </defs>

      {/* Apply the stroke to the polygon shapes */}
      <polygon points="11 19 2 12 11 5 11 19" />
      <polygon points="22 19 13 12 22 5 22 19" />
    </svg>
  );
}
