import MarkdownLite from '~/components/Chat/Messages/Content/MarkdownLite';
import DialogTemplate from '~/components/ui/DialogTemplate';
import { useAcceptTermsMutation } from '~/data-provider';
import { useToastContext } from '~/Providers';
import { OGDialog } from '~/components/ui';
import { useLocalize } from '~/hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditionsModal = ({
  open,
  onOpenChange,
  onAccept,
  onDecline,
  title,
  contentUrl,
  modalContent = `# You Beta Believe It!
  Welcome to the Ayzenberg AI Beta, where innovation meets imagination. We’re delighted you’re joining us on this exhilarating journey with Ayzenberg AI – a place where cutting-edge technology and creativity blend seamlessly.

### Your Safe Space

Your data's security is as important to us as your creative freedom. Rest assured, everything you share is encrypted both at rest and in motion, safeguarded by the robust infrastructure of Google Cloud and Microsoft Azure. We use your data solely to enhance our services, identify trends, and tackle any challenges that come our way. You’re in a secure, encrypted haven where your information remains yours alone. Pretty cool, right? For more details, check out our complete [Privacy Policy](/privacy-policy).

### The Legal Stuff

By diving into Ayzenberg AI, you’re joining a community under these welcoming terms:

1. **Who We Are**: Ayzenberg AI is here to empower you with top-tier AI marketing intelligence and conversational prowess.

2. **Your Playground**: Feel free to explore and create within our boundaries – keeping it legal and respectful.

3. **Your Adventure, Your Choice**: Embrace the outputs we generate, knowing you’re in control of your creations, while respecting the creations of others.

4. **Our Promise**: We’re constantly evolving to serve you better. While we can’t promise perfection, we promise progress.

5. **Our Right**: We may need to make updates or changes. We reserve the right to do so and can part ways with users who don’t follow the guidelines.

For the nitty-gritty, check out our full [Terms of Service](/terms-of-service).

### Got Questions?

If you have any questions or need assistance, don't hesitate to reach out to your account manager or drop us a line at [ai@ayzenberg.com](mailto:ai@ayzenberg.com). We’re here to help!

### Agreeing Is Easy!

By clicking “I Agree,” you’re officially part of the Ayzenberg family, ready to explore the wonders of AI with us.`,
}: {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onAccept: () => void;
  onDecline: () => void;
  title?: string;
  contentUrl?: string;
  modalContent?: string;
}) => {
  const localize = useLocalize();
  const { showToast } = useToastContext();
  const [isChecked, setIsChecked] = useState(false);

  const acceptTermsMutation = useAcceptTermsMutation({
    onSuccess: () => {
      onAccept();
      onOpenChange(false);
    },
    onError: () => {
      showToast({ message: 'Failed to accept terms' });
    },
  });

  const handleAccept = () => {
    if (isChecked) {
      acceptTermsMutation.mutate();
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (open && !isOpen) {
      return;
    }
    onOpenChange(isOpen);
  };

  return (
    <OGDialog open={open} onOpenChange={handleOpenChange}>
      <DialogTemplate
        title={'Disclaimer (Effective Date: December 5, 2024)'}
        className="w-11/12 max-w-3xl sm:w-3/4 md:w-1/2 lg:w-2/5"
        showCloseButton={false}
        showCancelButton={false}
        main={
          <section
            // Motivation: This is a dialog, so its content should be focusable
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            className="max-h-[60vh] overflow-y-auto p-4"
            aria-label={localize('com_ui_terms_and_conditions')}
          >
            <div className="prose dark:prose-invert !text-text-primary w-full max-w-none">
              <MarkdownLite content={modalContent} />
              <div className="mt-4 flex flex-col items-center gap-4">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="terms-checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 accent-[#F214A1] checked:border-none checked:bg-gradient-to-r checked:from-[#F214A1] checked:to-[#BC0278] focus:ring-[#F214A1]"
                  />
                  <label htmlFor="terms-checkbox" className="text-sm">
                    I Agree and have read the{' '}
                    <Link
                      to="/privacy-policy"
                      className="footer-gradient-text text-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {localize('com_ui_privacy_policy')}
                    </Link>{' '}
                    and the{' '}
                    <Link
                      to="/terms-of-service"
                      className="footer-gradient-text text-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </Link>
                  </label>
                </div>
              </div>
            </div>
          </section>
        }
        buttons={
          <div className="flex justify-end">
            <button
              onClick={handleAccept}
              disabled={!isChecked}
              className={`link-button inline-flex h-10 items-center justify-center rounded-lg px-4 py-2 text-sm text-white  ${
                isChecked ? 'opacity-100 hover:text-black' : 'opacity-50 '
              }`}
            >
              Continue
            </button>
          </div>
        }
      />
    </OGDialog>
  );
};

export default TermsAndConditionsModal;
