import { XIcon } from 'lucide-react';
import { useRecoilState } from 'recoil';
import { useGetBannerQuery } from 'librechat-data-provider/react-query';
import store from '~/store';
import { useEffect, useRef } from 'react';

export const Banner = ({
  onHeightChange,
}: {
  onHeightChange?: (height: number) => void;
}) => {
  const { data: banner } = useGetBannerQuery();
  const [hideBannerHint, setHideBannerHint] = useRecoilState<string[]>(
    store.hideBannerHint,
  );
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onHeightChange && bannerRef.current) {
      onHeightChange(bannerRef.current.offsetHeight);
    }
  }, [banner, hideBannerHint, onHeightChange]);

  if (
    !banner ||
    (banner.bannerId && hideBannerHint.includes(banner.bannerId))
  ) {
    return null;
  }

  const onClick = () => {
    setHideBannerHint([...hideBannerHint, banner.bannerId]);
    if (onHeightChange) {
      onHeightChange(0); // Reset height when banner is closed
    }
  };

  return (
    <div
      ref={bannerRef}
      className="banner-background sticky top-0 z-20 flex items-center p-4 text-white md:relative"
    >
      <div
        className="w-full truncate text-center text-sm"
        dangerouslySetInnerHTML={{ __html: banner.message }}
      ></div>
      <button
        type="button"
        aria-label="Dismiss banner"
        className="opacity-80 hover:opacity-100"
        onClick={onClick}
      >
        <XIcon className="mx-auto h-5 w-5" />
      </button>
    </div>
  );
};
