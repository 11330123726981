import { useLocalize } from '~/hooks';
import { Link } from 'react-router-dom';
import { TStartupConfig } from 'librechat-data-provider';
import './css/Footer.css';

interface FooterProps {
  startupConfig: TStartupConfig | null | undefined;
  hideLinks?: boolean;
}

function Footer({ startupConfig, hideLinks = false }: FooterProps) {
  const localize = useLocalize();

  if (!startupConfig) {
    return null;
  }

  if (hideLinks) {
    return null;
  }

  const privacyPolicyRender = (
    <Link to="/privacy-policy" className="footer-gradient-text text-sm">
      {localize('com_ui_privacy_policy')}
    </Link>
  );

  const termsOfServiceRender = (
    <Link to="/terms-of-service" className="footer-gradient-text text-sm">
      Terms and Conditions
    </Link>
  );

  return (
    <div className="align-end m-4 flex justify-center gap-2" role="contentinfo">
      {privacyPolicyRender}
      {privacyPolicyRender && termsOfServiceRender && (
        <div className="gradient-border" />
      )}
      {termsOfServiceRender}
    </div>
  );
}

export default Footer;
