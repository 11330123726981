import { atom, selector } from 'recoil';
import { logger } from '~/utils';
import type { Artifact } from '~/common';

// Helper to create localStorage effect with Set serialization support
const localStorageEffect = (key: string) => ({setSelf, onSet}: any) => {
  const savedValue = localStorage.getItem(key);
  if (savedValue != null) {
    try {
      const parsed = JSON.parse(savedValue);
      // Convert arrays back to Sets for seenArtifacts
      if (key === 'seenArtifacts') {
        const converted: Record<string, Set<string>> = {};
        Object.entries(parsed).forEach(([convId, artifacts]) => {
          converted[convId] = new Set(artifacts as string[]);
        });
        setSelf(converted);
      } else {
        setSelf(parsed);
      }
    } catch (error) {
      console.error(`Error loading ${key} from localStorage:`, error);
    }
  }

  onSet((newValue: any) => {
    if (newValue === null) {
      localStorage.removeItem(key);
    } else {
      try {
        // Convert Sets to arrays for seenArtifacts before storing
        if (key === 'seenArtifacts') {
          const serializable: Record<string, string[]> = {};
          Object.entries(newValue).forEach(([convId, artifacts]) => {
            serializable[convId] = Array.from(artifacts as Set<string>);
          });
          localStorage.setItem(key, JSON.stringify(serializable));
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }
      } catch (error) {
        console.error(`Error saving ${key} to localStorage:`, error);
      }
    }
  });
};

export const artifactsState = atom<Record<string, Artifact | undefined> | null>({
  key: 'artifactsState',
  default: null,
  effects: [localStorageEffect('artifacts')]
});

export const currentArtifactId = atom<string | null>({
  key: 'currentArtifactId',
  default: null,
  effects: [localStorageEffect('currentArtifactId')]
});

// Track the active conversation ID for artifact scoping
export const activeConversationId = atom<string | null>({
  key: 'activeConversationId',
  default: null,
  effects: [localStorageEffect('activeConversationId')]
});

// Track whether artifacts panel should be visible
export const artifactsVisible = atom<boolean>({
  key: 'artifactsVisible',
  default: false,
  effects: [localStorageEffect('artifactsVisible')]
});

// Track seen artifacts per conversation
export const seenArtifactsState = atom<Record<string, Set<string>>>({
  key: 'seenArtifactsState',
  default: {},
  effects: [localStorageEffect('seenArtifacts')]
});

// Scoped artifacts selector that only returns artifacts for the active conversation
export const scopedArtifactsState = selector({
  key: 'scopedArtifactsState',
  get: ({ get }) => {
    const artifacts = get(artifactsState);
    const conversationId = get(activeConversationId);

    if (!artifacts || !conversationId) {
      logger.debug('[scopedArtifactsState] No artifacts or conversation ID:', {
        hasArtifacts: !!artifacts,
        conversationId,
      });
      return null;
    }

    // Filter artifacts to only include those for the active conversation
    const scopedArtifacts: Record<string, Artifact | undefined> = {};
    Object.entries(artifacts).forEach(([id, artifact]) => {
      // Check for both formats: old (includes) and new (explicit -conversationId-)
      if (artifact && (id.includes(conversationId) || id.includes(`-${conversationId}_`))) {
        scopedArtifacts[id] = artifact;
      }
    });

    const hasArtifacts = Object.keys(scopedArtifacts).length > 0;
    logger.debug('[scopedArtifactsState] Filtered artifacts:', {
      conversationId,
      totalArtifacts: Object.keys(artifacts).length,
      scopedArtifacts: Object.keys(scopedArtifacts).length,
      hasArtifacts,
    });

    return hasArtifacts ? scopedArtifacts : null;
  },
});
