import React from 'react';
import { ArrowLeft, Search } from 'lucide-react';
import type { WhitelistHeaderProps } from './types';

export const WhitelistHeader: React.FC<WhitelistHeaderProps> = ({
  onBack,
  searchTerm,
  setSearchTerm,
  onAddClick,
}) => (
  <div className="mb-6">
    <div className="flex items-center justify-between">
      <button
        onClick={onBack}
        className="auth-button flex items-center gap-2 rounded-lg px-3 py-2 text-sm text-white hover:text-[#0F172A]"
      >
        <ArrowLeft className="h-4 w-4" />
        Back
      </button>
      <h1 className="flex-1 text-center text-[42px] font-light text-black dark:text-white">
        Whitelist Emails
      </h1>
    </div>

    <div className="mt-4 flex items-center gap-4">
      <div
        className="flex items-center rounded border bg-gray-100 p-2 dark:bg-[#2A3C5D]"
        style={{ width: '250px' }}
      >
        <Search
          className="mr-2 text-gray-500"
          style={{ width: '16px', height: '16px' }}
        />
        <input
          type="text"
          placeholder="Search by email or company"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full bg-transparent text-gray-800 placeholder-gray-500 focus:outline-none dark:text-gray-200 dark:placeholder-gray-400"
        />
      </div>
      <button
        onClick={onAddClick}
        className="auth-button flex items-center gap-2 rounded-lg px-3 py-2 text-sm text-white hover:text-[#0F172A]"
      >
        + Add Email
      </button>
    </div>
  </div>
);
