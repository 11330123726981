import React from 'react';

const MetaIcon: React.FC<{ size?: number; className?: string }> = ({
  size = 100,
  className = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 256 256"
      className={className}
      fill="currentColor" // this allows the color to be controlled externally
    >
      <g
        fill="none"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <g transform="scale(5.33333,5.33333)">
          <path
            d="M47,29.36l-2.193,1.663l-2.187,-1.523c0,-0.16 0,-0.33 -0.01,-0.5c0,-0.16 0,-0.33 -0.01,-0.5c-0.14,-3.94 -1.14,-8.16 -3.14,-11.25c-1.54,-2.37 -3.51,-3.5 -5.71,-3.5c-2.31,0 -4.19,1.38 -6.27,4.38c-0.06,0.09 -0.13,0.18 -0.19,0.28c-0.04,0.05 -0.07,0.1 -0.11,0.16c-0.1,0.15 -0.2,0.3 -0.3,0.46c-0.9,1.4 -1.84,3.03 -2.86,4.83c-0.09,0.17 -0.19,0.34 -0.28,0.51c-0.03,0.04 -0.06,0.09 -0.08,0.13l-0.21,0.37l-1.24,2.19c-2.91,5.15 -3.65,6.33 -5.1,8.26c-2.55,3.39 -4.73,4.68 -7.6,4.68c-3.4,0 -5.56,-1.47 -6.89,-3.69c-1.09,-1.8 -1.62,-4.17 -1.62,-6.87l4.97,0.17c0,1.76 0.38,3.1 0.89,3.92c0.66,1.06 1.63,1.47 2.64,1.47c1.29,0 2.49,-0.27 4.77,-3.43c1.83,-2.53 3.99,-6.07 5.44,-8.3l1.37,-2.09l0.29,-0.46l0.3,-0.45l0.5,-0.77c0.76,-1.16 1.58,-2.39 2.46,-3.57c0.1,-0.14 0.2,-0.28 0.31,-0.42c0.1,-0.14 0.21,-0.28 0.31,-0.41c0.9,-1.15 1.85,-2.22 2.87,-3.1c1.85,-1.61 3.84,-2.5 5.85,-2.5c3.37,0 6.58,1.95 9.04,5.61c2.51,3.74 3.82,8.4 3.97,13.25c0.01,0.16 0.01,0.33 0.01,0.5c0.01,0.17 0.01,0.33 0.01,0.5z"
            fill="currentColor"
          ></path>
          <path
            d="M4.918,15.456c2.277,-3.505 5.565,-5.956 9.335,-5.956c2.184,0 4.354,0.645 6.621,2.493c2.479,2.02 5.122,5.346 8.419,10.828l1.182,1.967c2.854,4.746 4.477,7.187 5.428,8.339c1.222,1.479 1.985,1.873 3.097,1.873c2.82,0 3.617,-2.54 3.617,-5.501l4.383,-0.137c0,3.095 -0.611,5.369 -1.651,7.165c-1.004,1.737 -2.962,3.473 -6.256,3.473c-2.048,0 -3.862,-0.444 -5.868,-2.333c-1.542,-1.45 -3.345,-4.026 -4.732,-6.341l-4.126,-6.879c-2.07,-3.452 -3.969,-6.027 -5.068,-7.192c-1.182,-1.254 -2.642,-2.754 -5.067,-2.754c-1.963,0 -3.689,1.362 -5.084,3.465z"
            fill="currentColor"
          ></path>
          <path
            d="M14.25,14.5c-1.959,0 -3.683,1.362 -5.075,3.465c-1.969,2.972 -3.175,7.398 -3.175,11.649c0,1.753 -0.003,3.072 0.5,3.886l-3.84,2.813c-1.086,-1.806 -1.66,-4.113 -1.66,-6.813c0,-4.91 1.355,-10.091 3.918,-14.044c2.274,-3.505 5.589,-5.956 9.352,-5.956z"
            fill="currentColor"
          ></path>
          <path
            d="M21.67,20.27l-0.3,0.45l-0.29,0.46c0.71,1.03 1.52,2.27 2.37,3.69l0.21,-0.37c0.02,-0.04 0.05,-0.09 0.08,-0.13c0.09,-0.17 0.19,-0.34 0.28,-0.51c-0.83,-1.36 -1.63,-2.57 -2.35,-3.59zM24.94,15.51c-0.11,0.14 -0.21,0.28 -0.31,0.42c0.73,0.91 1.47,1.94 2.25,3.1c0.1,-0.16 0.2,-0.31 0.3,-0.46c0.04,-0.06 0.07,-0.11 0.11,-0.16c0.06,-0.1 0.13,-0.19 0.19,-0.28c-0.76,-1.12 -1.5,-2.13 -2.23,-3.03c-0.1,0.13 -0.21,0.27 -0.31,0.41z"
            fill="currentColor"
            opacity="0.05"
          ></path>
          <path
            d="M21.67,20.27l-0.3,0.45c0.71,1.02 1.51,2.24 2.37,3.65c0.09,-0.17 0.19,-0.34 0.28,-0.51c-0.83,-1.36 -1.63,-2.57 -2.35,-3.59zM24.63,15.93c0.73,0.91 1.47,1.94 2.25,3.1c0.1,-0.16 0.2,-0.31 0.3,-0.46c-0.77,-1.14 -1.52,-2.16 -2.24,-3.06c-0.11,0.14 -0.21,0.28 -0.31,0.42z"
            fill="currentColor"
            opacity="0.07"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default MetaIcon;




