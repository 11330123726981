import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import TagManager from 'react-gtm-module';
import { Constants } from 'librechat-data-provider';
import { useGetStartupConfig } from 'librechat-data-provider/react-query';
import { useLocalize } from '~/hooks';

interface FooterProps {
  className?: string;
  showPrivacyAndTos?: boolean;
}

export default function Footer({ className, showPrivacyAndTos = true }: FooterProps) {
  const { data: config } = useGetStartupConfig();
  const localize = useLocalize();

  const privacyPolicy = config?.interface?.privacyPolicy;
  const termsOfService = config?.interface?.termsOfService;

  const privacyPolicyRender = privacyPolicy?.externalUrl != null && (
    <a
      className="text-[#7f7f7f] underline"
      href={privacyPolicy.externalUrl}
      target={privacyPolicy.openNewTab === true ? '_blank' : undefined}
      rel="noreferrer"
    >
      {localize('com_ui_privacy_policy')}
    </a>
  );

  const termsOfServiceRender = termsOfService?.externalUrl != null && (
    <a
      className="text-[#7f7f7f] underline"
      href={termsOfService.externalUrl}
      target={termsOfService.openNewTab === true ? '_blank' : undefined}
      rel="noreferrer"
    >
      {localize('com_ui_terms_of_service')}
    </a>
  );

  const mainContentParts = (
    typeof config?.customFooter === 'string'
      ? config.customFooter
      : '[LibreChat ' +
        Constants.VERSION +
        '](https://librechat.ai) - ' +
        localize('com_ui_latest_footer')
  ).split('|');

  useEffect(() => {
    if (config?.analyticsGtmId != null && typeof window.google_tag_manager === 'undefined') {
      const tagManagerArgs = {
        gtmId: config.analyticsGtmId,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [config?.analyticsGtmId]);

  const mainContentRender = mainContentParts.map((text, index) => (
    <React.Fragment key={`main-content-part-${index}`}>
      <ReactMarkdown
        components={{
          a: ({ node: _n, href, children, ...otherProps }) => (
            <a
              className="text-text-secondary underline"
              href={href}
              target="_blank"
              rel="noreferrer"
              {...otherProps}
            >
              {children}
            </a>
          ),
          p: ({ node: _n, children }) => (
            <div className="block">
              {React.Children.map(children, (child) =>
                typeof child === 'string'
                  ? child.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      {i !== child.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))
                  : child,
              )}
            </div>
          ),
        }}
      >
        {text.trim()}
      </ReactMarkdown>
    </React.Fragment>
  ));

  // Conditionally render privacy policy and terms of service
  const footerElements = showPrivacyAndTos
    ? [privacyPolicyRender, termsOfServiceRender].filter(Boolean)
    : [];

  return (
    <>
      <div
        className={
          className ??
          'relative flex items-center justify-center gap-2 px-2 pt-3 text-center text-xs text-[#7f7f7f] md:px-[60px]'
        }
        role="contentinfo"
      >
        {mainContentRender.map((content) => content)}
      </div>
      {showPrivacyAndTos && footerElements.length > 0 && (
        <div
          className={
            className ??
            'relative flex items-center justify-center gap-2 px-2 py-2 text-center text-xs text-white md:px-[60px]'
          }
          role="contentinfo"
        >
          {footerElements.map((contentRender, index) => {
            const isLastElement = index === footerElements.length - 1;
            return (
              <React.Fragment key={`footer-element-${index}`}>
                {contentRender}
                {!isLastElement && (
                  <div
                    key={`separator-${index}`}
                    className="h-2 border-r-[1px] border-border-medium"
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </>
  );
}

