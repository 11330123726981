import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import type {
  Email,
  WhitelistHookResult,
  EmailData,
  EditEmailData,
} from '~/types/whitelist';

const useWhitelist = (): WhitelistHookResult => {
  const queryClient = useQueryClient();
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editingEmail, setEditingEmail] = useState<Email | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [success, setSuccess] = useState('');

  const {
    data: emails = [],
    isLoading,
    isError,
    error,
  } = useQuery(['whitelistedEmails'], () =>
    axios.get('/api/whitelist').then((res) => res.data),
  );

  const addEmail = useMutation(
    (data: EmailData) => axios.post('/api/whitelist', data),
    { onSuccess: () => queryClient.invalidateQueries(['whitelistedEmails']) },
  );

  const editEmail = useMutation(
    ({ currentEmail, data }: EditEmailData) =>
      axios.put(`/api/whitelist/${encodeURIComponent(currentEmail)}`, data),
    { onSuccess: () => queryClient.invalidateQueries(['whitelistedEmails']) },
  );

  const removeEmail = useMutation(
    (email: string) =>
      axios.delete(`/api/whitelist/${encodeURIComponent(email)}`),
    { onSuccess: () => queryClient.invalidateQueries(['whitelistedEmails']) },
  );

  return {
    emails,
    isLoading,
    isError,
    error,
    addEmail,
    editEmail,
    removeEmail,
    showForm,
    setShowForm,
    editMode,
    setEditMode,
    editingEmail,
    setEditingEmail,
    searchTerm,
    setSearchTerm,
    success,
    setSuccess,
  };
};

export default useWhitelist;
