import React from 'react';

const NewTools: React.FC = () => {
  return (
    <>
      <div className="mb-3 text-[14px] md:text-[12px]">
        New Tools Available:
      </div>
      <div className="flex items-center justify-center gap-2 text-[10px] leading-normal md:text-[18px]">
        {[
          { icon: '🎯', label: 'EMV Calculator' },
          { icon: '🌆', label: 'Flux Image Generator' },
          { icon: '🌄', label: 'DALL-E 3 Image Generator' },
        ].map((item, index) => (
          <React.Fragment key={index}>
            <div className="inline-flex items-center gap-2">
              <span>{item.icon}</span>
              <span className="inline-block whitespace-nowrap text-left md:whitespace-normal lg:whitespace-nowrap">
                {item.label}
              </span>
            </div>
            {index < 2 && (
              <div className="mx-4 hidden items-center md:flex">
                <div className="h-[26px] w-[1px] bg-[#0f172a] dark:bg-white"></div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default NewTools;
