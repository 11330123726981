import { cn } from '~/utils/';

export default function Plugin({ className = '', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      className={cn('h-4 w-4', className)}
      width="16"
      height="16"
      strokeWidth="2"
      {...props}
    >
      <g fill="currentColor">
        <path d="m50.006 5.041-.088.001c-.561.014-13.853.414-23.205 6.41a3.521 3.521 0 0 0 3.801 5.929c7.469-4.788 18.836-5.272 19.547-5.297 20.897.029 37.888 17.039 37.888 37.943 0 20.921-17.021 37.942-37.942 37.942-10.406 0-20.467-4.191-27.601-11.499-6.906-7.073-10.579-16.434-10.343-26.331.222-6.954 3.798-13.155 8.696-15.08 3.498-1.375 7.408-.489 11.459 2.547-4.586 7.064-3.787 16.63 2.401 22.819l5.644 5.644a3.524 3.524 0 0 0 4.98 0l2.324-2.324 5.644 5.644c.688.687 1.589 1.031 2.49 1.031s1.802-.344 2.49-1.031a3.52 3.52 0 0 0 0-4.98l-5.644-5.644 6.197-6.197 5.644 5.644a3.5 3.5 0 0 0 2.49 1.031 3.522 3.522 0 0 0 2.49-6.011l-5.644-5.644 2.324-2.324a3.52 3.52 0 0 0 0-4.98l-5.644-5.645a18.12 18.12 0 0 0-12.892-5.34 18.1 18.1 0 0 0-10.353 3.224c-8.311-6.604-15.315-5.457-18.977-4.018-7.556 2.97-12.844 11.574-13.159 21.438-.284 11.837 4.1 23.006 12.342 31.447 8.452 8.656 20.349 13.621 32.64 13.621 24.805 0 44.985-20.18 44.985-44.985S74.811 5.041 50.006 5.041" />
      </g>
    </svg>
  );
}
