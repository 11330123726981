import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../hooks/AuthContext';
import WhitelistManagement from '../components/UserManager/WhitelistManagement';

const UserManagerRoute = () => {
  const { user } = useAuthContext();

  if (user?.role !== 'USER_MANAGER' && user?.role !== 'ADMIN') {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex-1 overflow-hidden">
      <Outlet />
    </div>
  );
};

export default {
  element: <UserManagerRoute />,
  children: [
    {
      path: 'whitelist',
      element: <WhitelistManagement />,
    },
  ],
};
