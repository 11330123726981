import React from 'react';
import { TrashIcon, PencilIcon } from 'lucide-react';
import type { EmailListProps } from './types';

export const EmailList: React.FC<EmailListProps> = ({
  emails,
  searchTerm,
  onEdit,
  onRemove,
}) => {
  const filteredEmails = emails.filter((item) => {
    const emailMatch = item.email
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const companyMatch =
      typeof item.company === 'string' &&
      item.company.toLowerCase().includes(searchTerm.toLowerCase());
    return emailMatch || companyMatch;
  });

  return (
    <>
      <div className="mb-4 text-base font-medium text-gray-800 dark:text-gray-200">
        Total Users: {emails.length}
        {searchTerm && ` (${filteredEmails.length} matching search)`}
      </div>
      <div className="mb-4 space-y-2">
        {filteredEmails.map((item) => (
          <div
            key={item.email}
            className="flex w-full flex-col justify-between rounded bg-gray-50 px-4 py-4 shadow-md sm:flex-row sm:items-center sm:px-6 dark:bg-[#1C2846]"
          >
            <div className="w-full flex-1">
              <div className="break-words text-base font-medium text-gray-800 sm:text-lg dark:text-gray-200">
                {item.email}
              </div>
              {typeof item.company === 'string' && item.company.length > 0 && (
                <div className="break-words text-sm text-gray-600 dark:text-gray-400">
                  Company: {item.company}
                </div>
              )}
              {typeof item.notes === 'string' && item.notes.length > 0 && (
                <div className="break-words text-sm text-gray-600 dark:text-gray-400">
                  Notes: {item.notes}
                </div>
              )}
              {typeof item.addedBy === 'string' &&
                item.addedBy.length > 0 &&
                typeof item.addedAt === 'string' &&
                item.addedAt.length > 0 && (
                <div className="pt-2 text-xs text-gray-500 dark:text-gray-400">
                    Added by {item.addedBy} on{' '}
                  {new Date(item.addedAt).toLocaleDateString()}
                </div>
              )}
            </div>
            <div className="mt-4 flex w-full items-center justify-start gap-3 sm:mt-0 sm:w-auto sm:pr-3 md:justify-end">
              <button
                onClick={() => onEdit(item)}
                className="text-gray-600 dark:text-gray-400"
              >
                <PencilIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => onRemove(item.email)}
                className="text-red-500 hover:text-red-700"
              >
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
        {filteredEmails.length === 0 && (
          <div className="py-4 text-center text-gray-500">
            No matching emails found
          </div>
        )}
      </div>
    </>
  );
};
