import { useOutletContext } from 'react-router-dom';
import { useAuthContext } from '~/hooks/AuthContext';
import type { TLoginLayoutContext } from '~/common';
import { ErrorMessage } from '~/components/Auth/ErrorMessage';
import { getLoginError } from '~/utils';
import { useLocalize } from '~/hooks';
import LoginForm from './LoginForm';
import CardComponent, { cardData } from './subcomponents/CardLayout';
import InfoSectionComponent, { infoSectionData } from './subcomponents/InfoSection';

function Login() {
  const localize = useLocalize();
  const { error, setError, login } = useAuthContext();
  const { startupConfig } = useOutletContext<TLoginLayoutContext>();

  return (
    <>
      {error && <ErrorMessage>{localize(getLoginError(error))}</ErrorMessage>}
      {startupConfig?.emailLoginEnabled && (
        <LoginForm
          onSubmit={login}
          startupConfig={startupConfig}
          error={error}
          setError={setError}
        />
      )}
      {startupConfig?.registrationEnabled && (
        <div>
          <p className="my-4 text-center text-sm font-normal text-[#0f172a] dark:text-white">
            {localize('com_auth_no_account')}{' '}
            <a
              href="/register"
              className="gradient-text-underline"
            >
              {localize('com_auth_sign_up')}
            </a>
          </p>
          <div className="max-w-[1184px] space-y-6 py-16 md:px-8">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              {cardData.map((card, index) => (
                <CardComponent
                  key={index}
                  title={card.title}
                  description={card.description}
                  icon={card.icon}
                />
              ))}
            </div>
          </div>
          <div className="mx-auto my-16 flex max-w-[814px] flex-col gap-8">
            {infoSectionData.map((section, index) => (
              <InfoSectionComponent
                key={index}
                title={section.title}
                paragraphs={section.paragraphs}
                buttonText={section.buttonText}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
export default Login;
