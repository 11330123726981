interface FAQItem {
  title: string;
  paragraphs: string[];
}

export const faqData: FAQItem[] = [
  {
    title: 'What is ayzenberg.ai?',
    paragraphs: [
      'ayzenberg.ai, also known as the Marketing Intelligence Hub, is a state-of-the-art platform integrating AI technologies to provide marketers with actionable insights. It\'s the culmination of over a decade of AI-based solution development aimed at enhancing marketing and media insights.',
    ],
  },
  {
    title: 'How was the ayzenberg.ai model developed?',
    paragraphs: [
      'Our proprietary AI model is trained on two decades of editorial insights from <a href="https://www.alistdaily.com/" target="_blank" rel="noopener noreferrer" class="link-gradient-text">AList</a>, allowing it to deliver precise, data-driven marketing recommendations.',
    ],
  },
  {
    title:
      'What AI technologies are integrated into the Marketing Intelligence Hub?',
    paragraphs: [
      'ayzenberg.ai combines the power of ChatGPT, Claude, Gemini, Llama, GPT Researcher, Flux, and DALL.E into a seamless platform, empowering marketers with creative solutions and strategic guidance.',
    ],
  },
  {
    title: 'Who can benefit from using ayzenberg.ai?',
    paragraphs: [
      'Marketing executives, analysts, and decision-makers will find this tool invaluable for making informed decisions based on real-time data analysis and strategic insights.',
    ],
  },
  {
    title:
      'What kind of data can I analyze with the Marketing Intelligence Hub?',
    paragraphs: [
      'You can access a comprehensive suite of analytics, including campaign performance metrics, audience engagement data, and social media insights, all customizable to your specific objectives.',
    ],
  },
  {
    title: 'How do I access ayzenberg.ai?',
    paragraphs: [
      'Access is provided via a secure login system. For Ayzenberg employees, please use your Ayzenberg email to access ayzenberg.ai via secure sign-in with Okta. For partners and clients, please sign in using your designated email address for authenticated passwordless entry. For setup and access details, please reach out to <a href="mailto:william@ayzenberg.com" class="link-gradient-text">william@ayzenberg.com</a>.',
    ],
  },
  {
    title: 'How can I report a bug or technical issue?',
    paragraphs: [
      'Use the "Feedback" button located at the top right corner of the dashboard to directly communicate any problems to our support team for swift resolution.',
    ],
  },
  {
    title: 'How are feature requests handled?',
    paragraphs: [
      'We welcome your suggestions and feature requests. Please submit them through our contact form on the Ayzenberg website or email us at <a href="mailto:ai@ayzenberg.com" class="link-gradient-text">ai@ayzenberg.com</a>.',
    ],
  },
  {
    title:
      'What kind of support is available for the Marketing Intelligence Hub?',
    paragraphs: [
      'We provide dedicated support to ensure you have a seamless experience with ayzenberg.ai. For assistance, contact your support representative or reach out to us at <a href="mailto:ai@ayzenberg.com" class="link-gradient-text">ai@ayzenberg.com</a>.',
    ],
  },
  {
    title: 'How do you ensure data security in ayzenberg.ai?',
    paragraphs: [
      'We use advanced encryption and industry best practices to keep your data secure and confidential. Your data\'s security is as important to us as your creative freedom. Everything you share is encrypted both at rest and in motion, safeguarded by the robust infrastructure of Google Cloud and Microsoft Azure.',
    ],
  },
];
