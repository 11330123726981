import type { TFile } from 'librechat-data-provider';
import type { ExtendedFile } from '~/common';

export default function FileIcon({
  file,
  fileType,
}: {
  file?: ExtendedFile | TFile;
  fileType: {
    fill: string;
    paths: React.FC;
    title: string;
  };
}) {
  const isGradient = fileType.fill === 'gradient';
  const gradientId = 'artifactGradient';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      fill="none"
      className="h-10 w-10 flex-shrink-0"
      width="36"
      height="36"
    >
      {isGradient && (
        <defs>
          <linearGradient id={gradientId} gradientTransform="rotate(91.969)">
            <stop offset="0%" stopColor="#F214A1" />
            <stop offset="100%" stopColor="#BC0278" />
          </linearGradient>
        </defs>
      )}
      <rect
        width="36"
        height="36"
        rx="6"
        fill={isGradient ? `url(#${gradientId})` : fileType.fill}
      />
      {(file?.['progress'] ?? 1) >= 1 && <>{<fileType.paths />}</>}
    </svg>
  );
}
