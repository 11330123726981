import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useGetStartupConfig } from 'librechat-data-provider/react-query';
import type { TStartupConfig } from 'librechat-data-provider';
import TagManager from 'react-gtm-module';
import AuthLayout from '~/components/Auth/AuthLayout';
import { useLocalize } from '~/hooks';

const headerMap = {
  '/login': 'com_auth_welcome_back',
  '/register': 'com_auth_create_account',
  '/forgot-password': 'com_auth_reset_password',
  '/reset-password': 'com_auth_reset_password',
};

export default function StartupLayout({
  isAuthenticated,
}: {
  isAuthenticated?: boolean;
}) {
  const [error, setError] = useState<string | null>(null);
  const [headerText, setHeaderText] = useState<string | null>(null);
  const [startupConfig, setStartupConfig] = useState<TStartupConfig | null>(
    null,
  );
  const {
    data,
    isFetching,
    error: startupConfigError,
  } = useGetStartupConfig({
    enabled: isAuthenticated === false || startupConfig === null,
  });
  const localize = useLocalize();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate('/c/new', { replace: true });
    }
    if (data) {
      setStartupConfig(data);
    }
  }, [isAuthenticated, navigate, data]);

  useEffect(() => {
    const title = startupConfig?.appTitle;
    document.title = title || 'Ayzenberg AI';
  }, [startupConfig?.appTitle]);

  // Initialize Google Tag Manager
  useEffect(() => {
    if (startupConfig?.analyticsGtmId != null && typeof window.google_tag_manager === 'undefined') {
      const tagManagerArgs = {
        gtmId: startupConfig.analyticsGtmId,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [startupConfig?.analyticsGtmId]);

  useEffect(() => {
    setError(null);
    setHeaderText(null);
  }, [location.pathname]);

  const contextValue = {
    error,
    setError,
    headerText,
    setHeaderText,
    startupConfigError,
    startupConfig,
    isFetching,
  };

  const currentHeader = headerText || headerMap[location.pathname] || '';

  return (
    <AuthLayout
      header={localize(currentHeader)}
      isFetching={isFetching}
      startupConfig={startupConfig}
      startupConfigError={startupConfigError}
      pathname={location.pathname}
      error={error}
    >
      <Outlet context={contextValue} />
    </AuthLayout>
  );
}
