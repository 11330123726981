// Finnish phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_error_moderation:
    'Näyttää siltä, että moderointijärjestelmämme merkitsi lähetetyn sisällön yhteisön sääntöjen vastaisiksi. Emme voi jatkaa tämän aiheen käsittelyä. Jos sinulla on muita kysymyksiä tai aiheita joita haluaisit käsitellä, ole hyvä ja muokkaa viestiäsi, tai aloita uusi keskustelu.',
  com_error_no_user_key: 'Avainta ei löytynyt. Lisää avain ja yritä uudestaan.',
  com_error_no_base_url: 'Base URL puuttuu. Syötä URL ja yritä uudestaan.',
  com_error_invalid_user_key: 'Avain ei kelpaa. Lisää toimiva avain ja yritä uudestaan.',
  com_error_expired_user_key:
    '{0} varten annettu avain vanheni {1}. Syötä uusi avain ja yritä uudestaan.',
  com_files_no_results: 'Ei tuloksia.',
  com_files_filter: 'Suodata tiedostoja...',
  com_files_number_selected: '{0}/{1} tiedostoa valittu',
  com_sidepanel_select_assistant: 'Valitse Avustaja',
  com_sidepanel_parameters: 'Parametrit',
  com_sidepanel_assistant_builder: 'Avustajan rakentaminen',
  com_sidepanel_hide_panel: 'Piilota sivupalkki',
  com_sidepanel_attach_files: 'Liitä tiedostoja',
  com_sidepanel_manage_files: 'Hallinnoi tiedostoja',
  com_assistants_capabilities: 'Kyvykkyydet',
  com_assistants_file_search: 'Tiedostohaku',
  com_assistants_file_search_info:
    'Vektoritietokannan liittämistä tiedostohakuun ei vielä tueta. Voit liittää ne rajapinnan palveluntarjoajan käyttöliittymän kautta, tai liittää tiedostoja viesteihin keskusteluketjupohjaisesti.',
  com_assistants_knowledge: 'Tiedot',
  com_assistants_knowledge_info:
    'Jos lataat tiedostoja Tietoihin, Avustajasi kanssa käytyihin keskusteluihin voi tulla niiden sisältöä.',
  com_assistants_knowledge_disabled:
    'Avustaja täytyy ensin luoda, ja Kooditulkki tai Tiedonhaku täytyy olla päällä ja asetukset tallennettuna, ennen kuin tiedostoja voidaan ladata Tietoihin.',
  com_assistants_image_vision: 'Kuvanäkö',
  com_assistants_code_interpreter: 'Kooditulkki',
  com_assistants_code_interpreter_files: 'Seuraavat tiedostot ovat vain Kooditulkin käytettävissä:',
  com_assistants_retrieval: 'Tiedonhaku',
  com_assistants_search_name: 'Hae Avustajia nimen perusteella',
  com_ui_tools: 'Työkalut',
  com_assistants_actions: 'Toiminnot',
  com_assistants_add_tools: 'Lisää Työkaluja',
  com_assistants_add_actions: 'Lisää Toimintoja',
  com_assistants_non_retrieval_model:
    'Tiedostohaku ei ole käytössä tässä mallissa. Valitse toinen malli.',
  com_assistants_available_actions: 'Käytettävissä olevat Toiminnot',
  com_assistants_running_action: 'Suoritetaan toimintoa',
  com_assistants_completed_action: 'Puhuttiin {0}:lle',
  com_assistants_completed_function: 'Suoritettiin {0}',
  com_assistants_function_use: 'Avustaja käytti: {0}',
  com_assistants_domain_info: 'Avustaja lähetti tiedon tänne: {0}',
  com_assistants_delete_actions_success: 'Toiminto poistettiin Avustajalta onnistuneesti',
  com_assistants_update_actions_success: 'Toiminto luotiiin tai päivitettiin onnistuneesti',
  com_assistants_update_actions_error: 'Toiminnon luomisessa tai päivittämisessä tapahtui virhe.',
  com_assistants_delete_actions_error: 'Toiminnon poistamisessa tapahtui virhe.',
  com_assistants_actions_info:
    'Salli Avustajalle Tiedonhaku tai Toimintojen suorittaminen API-kutsujen kautta',
  com_assistants_name_placeholder: 'Valinnainen: Avustajan nimi',
  com_assistants_instructions_placeholder: 'Avustajan käyttämät järjestelmäohjeet',
  com_assistants_description_placeholder: 'Valinnainen: Kuvaus Avustajasta',
  com_assistants_actions_disabled: 'Avustaja täytyy luoda ennen toimintojen lisäämistä',
  com_assistants_update_success: 'Päivitys onnistui',
  com_assistants_update_error: 'Avustajan päivittämisessä tapahtui virhe.',
  com_assistants_create_success: 'Luonti onnistui',
  com_assistants_create_error: 'Avustajan luonnissa tapahtui virhe.',
  com_ui_date_today: 'Tänään',
  com_ui_date_yesterday: 'Eilen',
  com_ui_date_previous_7_days: 'Edelliset 7 päivää',
  com_ui_date_previous_30_days: 'Edelliset 30 päivää',
  com_ui_date_january: 'Tammikuu',
  com_ui_date_february: 'Helmikuu',
  com_ui_date_march: 'Maaliskuu',
  com_ui_date_april: 'Huhtikuu',
  com_ui_date_may: 'Toukokuu',
  com_ui_date_june: 'Kesäkuu',
  com_ui_date_july: 'Heinäkuu',
  com_ui_date_august: 'Elokuu',
  com_ui_date_september: 'Syyskuu',
  com_ui_date_october: 'Lokakuu',
  com_ui_date_november: 'Marraskuu',
  com_ui_date_december: 'Joulukuu',
  com_ui_field_required: 'Tämä kenttä on pakollinen',
  com_ui_download_error: 'Virhe tiedoston lataamisesta. Tiedosto on saatettu poistaa.',
  com_ui_attach_error_type: 'Päätepiste ei tue tiedostotyyppiä::',
  com_ui_attach_error_openai: 'Avustajan tiedostoja ei voi liittää muihin päätepisteisiin',
  com_ui_attach_warn_endpoint:
    'Ilman yhteensopivaa työkalua muut kuin Avustajan tiedostot voidaan jättää huomiotta.',
  com_ui_attach_error_size: 'Tiedoston koko ylittää päätepisteen rajan:',
  com_ui_attach_error:
    'Tiedosto ei voi liittää. Luo tai valitse keskustelu, tai kokeile ladata sivu uudestaan.',
  com_ui_examples: 'Esimerkkejä',
  com_ui_new_chat: 'Uusi keskustelu',
  com_ui_happy_birthday: 'On 1. syntymäpäiväni!',
  com_ui_experimental: 'Kokeelliset ominaisuudet',
  com_ui_on: 'Päällä',
  com_ui_off: 'Pois',
  com_ui_yes: 'Kyllä',
  com_ui_no: 'Ei',
  com_ui_ascending: 'Nouseva',
  com_ui_descending: 'Laskeva',
  com_ui_show_all: 'Näytä kaikki',
  com_ui_name: 'Nimi',
  com_ui_date: 'Päivämäärä',
  com_ui_storage: 'Varasto',
  com_ui_context: 'Konteksti',
  com_ui_size: 'Koko',
  com_ui_host: 'Host',
  com_ui_update: 'Päivitys',
  com_ui_authentication: 'Autentikointi',
  com_ui_instructions: 'Ohjeet',
  com_ui_description: 'Kuvaus',
  com_ui_error: 'Virhe',
  com_ui_error_connection: 'Palvelimeen yhdistäessä tapahtui virhe. Kokeile ladata sivu uudestaan.',
  com_ui_select: 'Valitse',
  com_ui_input: 'Syöte',
  com_ui_close: 'Sulje',
  com_ui_model: 'Malli',
  com_ui_select_model: 'Valitse malli',
  com_ui_select_search_model: 'Hae mallia nimen perusteella',
  com_ui_select_search_plugin: 'Hae lisäosaa nimen perusteella',
  com_ui_use_prompt: 'Käytä syötettä',
  com_ui_prev: 'Edellinen',
  com_ui_next: 'Seuraava',
  com_ui_stop: 'Pysäytä',
  com_ui_upload_files: 'Lataa tiedostoja',
  com_ui_prompt: 'Syöte',
  com_ui_prompts: 'Syötteet',
  com_ui_prompt_name: 'Syötteen nimi',
  com_ui_delete_prompt: 'Poista syöte?',
  com_ui_admin: 'Ylläpito',
  com_ui_simple: 'Yksinkertainen',
  com_ui_versions: 'Versiot',
  com_ui_version_var: 'Versio {0}',
  com_ui_advanced: 'Edistynyt',
  com_ui_admin_settings: 'Ylläpitoasetukset',
  com_ui_error_save_admin_settings: 'Ylläpitoasetusten tallentamisessa tapahtui virhe.',
  com_ui_prompt_preview_not_shared: 'Tekijä ei ole sallinut yhteistyötä tälle syötteelle.',
  com_ui_prompt_name_required: 'Syötteen nimi on pakollinen',
  com_ui_prompt_text_required: 'Teksti on pakollinen',
  com_ui_prompt_text: 'Teksti',
  com_ui_back_to_chat: 'Palaa keskusteluun',
  com_ui_back_to_prompts: 'Palaa syötteisiin',
  com_ui_categories: 'Kategoriat',
  com_ui_filter_prompts_name: 'Syötteiden nimisuodatus',
  com_ui_search_categories: 'Hakukategoriat',
  com_ui_manage: 'Hallinnoi',
  com_ui_variables: 'Muuttujat',
  com_ui_variables_info:
    'Käytä kaksoisaaltosulkeita tekstissäsi muuttujien luomiseen, esim. {{esimerkkimuuttuja}}. Muuttujia voi täyttää myöhemmin syötettä käyttäessä.',
  com_ui_special_variables:
    'Erikoismuuttujat: Käytä {{current_date}} kuluvaa päivämäärää varten, ja {{current_user}} käyttäjätunnustasi varten.',
  com_ui_showing: 'Näytetään',
  com_ui_of: '/',
  com_ui_entries: 'Merkinnät',
  com_ui_pay_per_call:
    'Kaikki tekoälykeskustelut yhdessä paikassa. Maksa kerrasta, älä kuukaudesta.',
  com_ui_new_footer: 'Kaikki tekoälykeskustelut yhdessä paikassa.',
  com_ui_latest_footer: 'Kaikki tekoälyt kaikille.',
  com_ui_enter: 'Syötä',
  com_ui_submit: 'Lähetä',
  com_ui_none_selected: 'Ei valintaa',
  com_ui_upload_success: 'Tiedoston lataus onnistui',
  com_ui_upload_error: 'Tiedoston lataamisessa tapahtui virhe',
  com_ui_upload_invalid:
    'Virheellinen ladattava tiedosto. Tiedoston täytyy olla kokorajaan mahtuva kuvatiedosto',
  com_ui_upload_invalid_var:
    'Virheellinen ladattava tiedosto. Tiedoston täytyy olla enintään {0} MB kokoinen kuvatiedosto',
  com_ui_cancel: 'Peruuta',
  com_ui_save: 'Tallenna',
  com_ui_renaming_var: 'Uudelleennimetään "{0}"',
  com_ui_save_submit: 'Tallenna & Lähetä',
  com_user_message: 'Sinä',
  com_ui_read_aloud: 'Lue ääneen',
  com_ui_copied: 'Kopioitu!',
  com_ui_copy_code: 'Kopioi koodi',
  com_ui_copy_to_clipboard: 'Kopioi leikepöydälle',
  com_ui_copied_to_clipboard: 'Kopioitu leikepöydältä',
  com_ui_fork: 'Haarauta',
  com_ui_fork_info_1: 'Käytä tätä asetusta viestien haarauttamiseen halutulla tavalla.',
  com_ui_fork_info_2:
    '"Haarauttaminen" luo uuden keskustelun siten, että se alkaa/päättyy tietyistä tämänhetkisen keskustelun viesteistä, luoden kopion halutulla tavalla.',
  com_ui_fork_info_3:
    '"Kohdeviesti" tarkoittaa joko viestiä, josta tämä ponnahdusikkuna avattiin, tai, jos rastitat "{0}", viimeisintä viestiä keskustelussa.',
  com_ui_fork_info_visible:
    'Tämä vaihtoehto haarauttaa vain näkyvissä olevat viestit; toisin sanoen, suoran polun kohdeviestiin, ilman sivupolkuja.',
  com_ui_fork_info_branches:
    'Tämä vaihtoehto haarauttaa näkyvissä olevat viestit sekä niihin liittyvät sivupolut; toisin sanoen, suoran polun kohdeviestiin sisällyttäen matkalla olevat sivupolut.',
  com_ui_fork_info_target:
    'Tämä vaihtoehto haarauttaa kaikki viestit kohdeviestiin asti, sisällyttäen sen naapurit; toisin sanoen, kaikki sivupolut riippumatta siitä ovatko ne näkyvissä tai samalla polulla tulevat matkaan.',
  com_ui_fork_info_start:
    'Jos tämä on valittu, haarauttaminen alkaa tästä viestistä keskustelun viimeiseen viestiin saakka, yllä valitun toimintatavan mukaisesti.',
  com_ui_fork_info_remember:
    'Jos tämä on valittu, tallentaa tehdyt valinnat tulevaa jatkokäyttöä varten nopeuttaen keskusteluhaarojen luomista samoilla asetuksilla.',
  com_ui_fork_success: 'Keskustelun haarauttaminen onnistui.',
  com_ui_fork_processing: 'Haarautetaan keskustelua...',
  com_ui_fork_error: 'Keskustelun haarauttamisessa tapahtui virhe',
  com_ui_fork_change_default: 'Oletushaarautustapa',
  com_ui_fork_default: 'Käytä oletushaarautustapaa',
  com_ui_fork_remember: 'Muista',
  com_ui_fork_split_target_setting: 'Aloita haara oletuksena kohdeviestistä',
  com_ui_fork_split_target: 'Aloita haara tästä',
  com_ui_fork_remember_checked:
    'Valintasi muistetaan käytön jälkeen. Voit muuttaa tätä milloin tahansa asetuksista.',
  com_ui_fork_all_target: 'Sisällytä kaikki tänne/täältä',
  com_ui_fork_branches: 'Sisällytä sivupolut',
  com_ui_fork_visible: 'Vain näkyvät viestit',
  com_ui_fork_from_message: 'Valitse haarautustapa',
  com_ui_mention: 'Mainitse päätepiste, Avustaja tai asetus vaihtaaksesi siihen pikana',
  com_ui_add_model_preset: 'Lisää malli tai esiasetus lisävastausta varten',
  com_ui_regenerate: 'Luo uudestaan',
  com_ui_continue: 'Jatka',
  com_ui_edit: 'Muokkaa',
  com_ui_loading: 'Ladataan...',
  com_ui_success: 'Onnistui',
  com_ui_all: 'kaikki',
  com_ui_all_proper: 'Kaikki',
  com_ui_clear: 'Tyhjennä',
  com_ui_revoke: 'Peruuta',
  com_ui_revoke_info: 'Peruuta kaikki käyttäjän antamat tunnisteet',
  com_ui_import_conversation: 'Tuo',
  com_ui_nothing_found: 'Mitään ei löytynyt',
  com_ui_go_to_conversation: 'Siirry keskusteluun',
  com_ui_import_conversation_info: 'Tuo keskusteluja JSON-tiedostosta',
  com_ui_import_conversation_success: 'Keskustelujen tuonti onnistui',
  com_ui_import_conversation_error: 'Keskustelujesi tuonnissa tapahtui virhe',
  com_ui_import_conversation_file_type_error: 'Tiedostotyyppi ei ole tuettu tuonnissa',
  com_ui_confirm_action: 'Vahvista toiminto',
  com_ui_chat: 'Keskustelu',
  com_ui_dashboard: 'Työpöytä',
  com_ui_chats: 'keskustelut',
  com_ui_avatar: 'Profiilikuva',
  com_ui_unknown: 'Tuntematon',
  com_ui_result: 'Tulos',
  com_ui_image_gen: 'Kuvanluonti',
  com_ui_assistant: 'Avustaja',
  com_ui_assistant_deleted: 'Avustajan poisto onnistui',
  com_ui_assistant_delete_error: 'Avustajan poistossa tapahtui virhe',
  com_ui_assistants: 'Avustajat',
  com_ui_attachment: 'Liitetiedosto',
  com_ui_assistants_output: 'Avustajien tuotokset',
  com_ui_delete: 'Poista',
  com_ui_create: 'Luo',
  com_ui_create_prompt: 'Luo syöte',
  com_ui_share: 'Jaa',
  com_ui_share_var: 'Jaa {0}',
  com_ui_copy_link: 'Kopioi linkki',
  com_ui_update_link: 'Päivitä linkki',
  com_ui_create_link: 'Luo linkki',
  com_ui_share_to_all_users: 'Jaa kaikille käyttäjille',
  com_ui_my_prompts: 'Omat syötteet',
  com_ui_no_category: 'Ei kategoriaa',
  com_ui_shared_prompts: 'Jaetut syötteet',
  com_ui_prompts_allow_use: 'Salli syötteiden käyttäminen',
  com_ui_prompts_allow_create: 'Salli syötteiden luominen',
  com_ui_prompts_allow_share_global: 'Salli syötteiden jakaminen kaikille käyttäjille',
  com_ui_prompt_shared_to_all: 'Tämä syöte on jaettu kaikille käyttäjille',
  com_ui_prompt_update_error: 'Syötteen päivityksessä tapahtui virhe',
  com_ui_prompt_already_shared_to_all: 'Tämä syöte on jo jaettu kaikille käyttäjille',
  com_ui_description_placeholder: 'Valinnainen: Lisää kuvaus syötteelle',
  com_ui_command_placeholder: 'Valinnainen: Käsky syötteelle. Oletuskäskynä on nimi.',
  com_ui_command_usage_placeholder: 'Valitse syöte käskyn tai nimen perusteella',
  com_ui_no_prompt_description: 'Kuvausta ei löytynyt.',
  com_ui_share_link_to_chat: 'Jaa linkki keskusteluun',
  com_ui_share_error: 'Keskustelulinkin jakamisessa tapahtui virhe',
  com_ui_share_retrieve_error: 'Jaettujen linkkien jakamisessa tapahtui virhe',
  com_ui_share_delete_error: 'Jaetun linkin poistossa tapahtui virhe',
  com_ui_share_create_message:
    'Nimesi ja jakamisen jälkeen lisätäämäsi viestit pysyvät yksityisinä.',
  com_ui_share_created_message:
    'Jakolinkki keskusteluun on luotu. Hallinnoi aiemmin jaettuja keskusteluja milloin vain Asetusten kautta.',
  com_ui_share_update_message:
    'Nimesi, mukautetut ohjeet, ja mahdolliset viestit jotka lisäät jakamisen jälkeen jäävät yksityisiksi.',
  com_ui_share_updated_message:
    'Keskustelun jakolinkki on päivitetty. Hallinnoi aiemmin jaettuja keskusteluja milloin vain Asetusten kautta.',
  com_ui_shared_link_not_found: 'Jakolinkki ei löytynyt',
  com_ui_delete_conversation: 'Poista keskustelu?',
  com_ui_delete_confirm: 'Tämä suorittaa poiston',
  com_ui_delete_confirm_prompt_version_var:
    'Tämä poistaa valitun version "{0}":lta. Jos muita versioita ei ole, syöte poistetaan samalla.',
  com_ui_delete_assistant_confirm:
    'Haluatko varmasti poistaa tämän Avustajan? Poistoa ei voi perua.',
  com_ui_rename: 'Nimeä uudestaan',
  com_ui_archive: 'Arkisto',
  com_ui_archive_error: 'Keskustelun arkistointi epäonnistui',
  com_ui_unarchive: 'Palauta arkistosta',
  com_ui_unarchive_error: 'Palautus arkistosta epäonnistui',
  com_ui_more_options: 'Lisää',
  com_ui_preview: 'Esikatsele',
  com_ui_upload: 'Lataa',
  com_ui_connect: 'Yhdistä',
  com_ui_locked: 'Lukittu',
  com_ui_upload_delay:
    '"{0}" lataaminen kestää odotettua pidempään. Ole hyvä ja odota kunnes tiedosto saadaan indeksoitua tiedonhakua varten.',
  com_ui_privacy_policy: 'Tietosuojailmoitus',
  com_ui_terms_of_service: 'Käyttöehdot',
  com_ui_use_micrphone: 'Käytä mikrofonia',
  com_ui_min_tags: 'Enempää arvoja ei voida poistaa. Niiden minimimäärä on {0}.',
  com_ui_max_tags: 'Maksimimäärä on {0}. käytetään viimeisimpiä arvoja.',
  com_ui_bookmarks: 'Kirjanmerkit',
  com_ui_bookmarks_rebuild: 'Uudelleenkokoa',
  com_ui_bookmarks_new: 'Uusi kirjanmerkki',
  com_ui_bookmark_delete_confirm: 'Oletko varma, että haluat poistaa tämän kirjanmerkin?',
  com_ui_bookmarks_title: 'Otsikko',
  com_ui_bookmarks_count: 'Määrä',
  com_ui_bookmarks_description: 'Kuvaus',
  com_ui_bookmarks_create_success: 'Kirjanmerkki luotu onnistuneesti',
  com_ui_bookmarks_update_success: 'Kirjanmerkki päivitetty onnistuneesti',
  com_ui_bookmarks_delete_success: 'Kirjanmerkki poistettu onnistuneesti',
  com_ui_bookmarks_create_error: 'Virhe kirjanmerkin luomisessa',
  com_ui_bookmarks_update_error: 'Virhe kirjanmerkin päivittämisessä',
  com_ui_bookmarks_delete_error: 'Virhe kirjanmerkin poistamisessa',
  com_ui_bookmarks_add_to_conversation: 'Lisää nykyiseen keskusteluun',
  com_ui_accept: 'Hyväksyn',
  com_ui_decline: 'En hyväksy',
  com_ui_terms_and_conditions: 'Käyttöehdot',
  com_ui_no_terms_content: 'Ei käyttöehtoja näytettäväksi',
  com_auth_error_login:
    'Kirjautuminen annetuilla tiedoilla ei onnistunut. Tarkista kirjautumistiedot, ja yritä uudestaan.',
  com_auth_error_login_rl:
    'Liian monta kirjautumisyritystä lyhyen ajan sisällä. Yritä myöhemmin uudestaan.',
  com_auth_error_login_ban: 'Tilisi on väliaikaisesti suljettu palvelun sääntöjen rikkomisesta.',
  com_auth_error_login_server: 'Tapahtui sisäinen palvelinvirhe. Odota hetki, ja yritä uudestaan.',
  com_auth_error_login_unverified:
    'Tiliäsi ei ole vahvistettu. Vahvistuslinkin pitäisi löytyä sähköposteistasi.',
  com_auth_no_account: 'Ei tunnusta?',
  com_auth_sign_up: 'Rekisteröidy',
  com_auth_sign_in: 'Kirjaudu',
  com_auth_google_login: 'Jatka Googlella',
  com_auth_facebook_login: 'Jatka Facebookilla',
  com_auth_github_login: 'Jatka Githubilla',
  com_auth_discord_login: 'Jatka Discordilla',
  com_auth_email: 'Sähköposti',
  com_auth_email_required: 'Sähköposti on pakollinen',
  com_auth_email_min_length: 'Sähköpostiosoitteen on oltava vähintään 6 merkkiä pitkä',
  com_auth_email_max_length: 'Sähköpostiosoitteen ei pitäisi olla 120 merkkiä pidempi',
  com_auth_email_pattern: 'Sähköpostiosoite on syötettävä oikeassa muodossa',
  com_auth_email_address: 'Sähköpostiosoite Email address',
  com_auth_password: 'Salasana',
  com_auth_password_required: 'Salasana on pakollinen',
  com_auth_password_min_length: 'Salasanan on oltava vähintään 8 merkkiä pitkä',
  com_auth_password_max_length: 'Salasana voi olla enintään 128 merkkiä',
  com_auth_password_forgot: 'Salasana unohtunut?',
  com_auth_password_confirm: 'Vahvista salasana',
  com_auth_password_not_match: 'Salasanat eivät täsmää',
  com_auth_continue: 'Jatka',
  com_auth_create_account: 'Luo tili',
  com_auth_error_create: 'Tilin rekisteröinnissä tapahtui virhe. Yritä uudestaan.',
  com_auth_full_name: 'Koko nimi',
  com_auth_name_required: 'Nimi on pakollinen',
  com_auth_name_min_length: 'Nimessä on oltava vähintään 3 merkkiä',
  com_auth_name_max_length: 'Nimi voi olla enintään 80 merkkiä pitkä',
  com_auth_username: 'Käyttäjänimi (valinnainen)',
  com_auth_username_required: 'Käyttäjänimi on pakollinen',
  com_auth_username_min_length: 'Käyttäjänimessä on oltava vähintään 2 merkkiä',
  com_auth_username_max_length: 'Käyttäjänimi voi olla enintään 20 merkkiä pitkä',
  com_auth_already_have_account: 'Käyttäjätilisi on jo luotu?',
  com_auth_login: 'Kirjaudu',
  com_auth_registration_success_insecure: 'Rekisteröityminen onnistui.',
  com_auth_registration_success_generic:
    'Tarkista sähköpostisi sähköpostiosoitteen vahvistamiseksi.',
  com_auth_reset_password: 'Aseta uusi salasana',
  com_auth_click: 'Napauta',
  com_auth_here: 'TÄTÄ',
  com_auth_to_reset_your_password: 'asettaaksesi uuden salasanan.',
  com_auth_reset_password_link_sent: 'Sähköposti lähetetty',
  com_auth_reset_password_if_email_exists:
    'Jos kyseiselle sähköpostiosoitteelle löytyy käyttäjätili, siihen lähetetään sähköposti joka sisältää ohjeet salasanan uusimiseen. Tarkistathan myös roskapostikansion.',
  com_auth_reset_password_email_sent:
    'Jos käyttäjä on rekisteröitynyt, salasanan vaihto-ohjeet lähetetään hänelle sähköpostitse.',
  com_auth_reset_password_success: 'Salasanan asettaminen onnistui',
  com_auth_login_with_new_password: 'Voit nyt kirjautua uudella salasanallasi.',
  com_auth_error_invalid_reset_token: 'Tämä salasanan uusimistunniste ei ole enää voimassa.',
  com_auth_click_here: 'Napauta tästä',
  com_auth_to_try_again: 'kokeillaksesi uudestaan.',
  com_auth_submit_registration: 'Lähetä rekisteröityminen',
  com_auth_welcome_back: 'Tervetuloa takaisin',
  com_auth_back_to_login: 'Palaa kirjautumiseen',
  com_auth_email_verification_failed: 'Sähköpostin varmentaminen epäonnistui',
  com_auth_email_verification_rate_limited: 'Liian monta yritystä. Kokeile myöhemmin uudestaan',
  com_auth_email_verification_success: 'Sähköposti varmennettu',
  com_auth_email_resent_success: 'Varmennussähköpostin uudelleenlähetys onnistui',
  com_auth_email_resent_failed: 'Varmennussähköpostin uudelleenlähetys epäonnistui',
  com_auth_email_verification_failed_token_missing:
    'Varmennus epäonnistui tunnisteen puuttumisen vuoksi',
  com_auth_email_verification_invalid: 'Sähköpostin varmentaminen ei voimassa',
  com_auth_email_verification_in_progress: 'Varmennetaan sähköpostia. Ole hyvä ja odota.',
  com_auth_email_verification_resend_prompt: 'Sähköposti ei saapunut perille?',
  com_auth_email_resend_link: 'Lähetä sähköposti uudestaan',
  com_auth_email_verification_redirecting: 'Uudelleenohjataan {0} sekunnissa...',
  com_endpoint_open_menu: 'Avaa valikko',
  com_endpoint_bing_enable_sydney: 'Ota Sydney käyttöön',
  com_endpoint_bing_to_enable_sydney: 'Ottaaksesi Sydneyn käyttöön',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing voi käyttää jopa 7000 tokenia keskustelussa viittausympäristönä käytettyä \'kontekstia\' varten. Tarkka raja ei ole tiedossa, mutta yli 7000 tokenia käyttäessä voi esiintyä virheitä.',
  com_endpoint_bing_system_message_placeholder:
    'VAROITUS: Tämän ominaisuuden väärinkäyttö saattaa johtaa Bingin KÄYTTÖKIELTOON! Napauta \'Järjestelmäviestiä\', niin saat täydet ohjeet ja oletusviestin (jos jätetty pois), mikä on turvalliseksi katsottu \'Sydney\'-esiasetus.',
  com_endpoint_system_message: 'Järjestelmäviesti',
  com_endpoint_message: 'Vastaanottajana',
  com_endpoint_messages: 'Viestit',
  com_endpoint_message_not_appendable: 'Muokkaa viestiäsi tai Luo uudestaan.',
  com_endpoint_default_blank: 'oletus: tyhjä',
  com_endpoint_default_false: 'oletus: false',
  com_endpoint_default_creative: 'oletus: creative',
  com_endpoint_default_empty: 'oletus: tyhjä',
  com_endpoint_default_with_num: 'oletus: {0}',
  com_endpoint_context: 'Konteksti',
  com_endpoint_tone_style: 'Tyylisävy',
  com_endpoint_token_count: 'Token-määrä',
  com_endpoint_output: 'Tulos',
  com_endpoint_context_tokens: 'Konteksti-tokenien maksimimäärä',
  com_endpoint_context_info:
    'Kontekstia varten käytettävien tokeneiden maksimimäärä. Käytä tätä pyyntökohtaisten token-määrien hallinnointiin. Jos tätä ei määritetä, käytössä ovat järjestelmän oletusarvot perustuen tiedossa olevien mallien konteksti-ikkunoiden kokoon. Korkeamman arvon asettaminen voi aiheuttaa virheitä tai korkeamman token-hinnan.',
  com_endpoint_google_temp:
    'Korkeampi arvo = satunnaisempi; matalampi arvo = keskittyneempi ja deterministisempi. Suosittelemme, että muokkaat tätä tai Top P:tä, mutta ei molempia.',
  com_endpoint_google_topp:
    'Top-P vaikuttaa siihen kuinka malli valitsee tokeneita tulokseen. Tokenit valitaan top-k:sta (ks. Top-k -parametri) todennäköisimmistä vähiten todennäköseen, kunnes niiden todennäköisyyksien summa ylittää Top-P -arvon.',
  com_endpoint_google_topk:
    'Top-k vaikuttaa siihen, miten malli valitsee tokeineita tulokseen. Jos Top-k on 1, valitaan se token, joka on kaikkien todennäköisen mallin sanastossa (tunnetaan myös nimellä ahne dekoodaus), kun taas top-k 3 tarkoittaisi, että seuraavat token valitaan 3 todennäköisimmän tokenin joukosta, lämpötilaa hyödyntäen.',
  com_endpoint_google_maxoutputtokens:
    'Maksimimäärä tokeneillre, joita generoidaan tulokseen. Valitse pienempi arvo saadaksesi lyhyempiä vastauksia, ja suurempi arvo pitkiä vastauksia varten.',
  com_endpoint_google_custom_name_placeholder: 'Aseta Googlelle mukautettu nimi',
  com_endpoint_prompt_prefix_placeholder:
    'Aseta mukautetut ohjeet tai konteksti. Jätetään huomiotta, jos tyhjä.',
  com_endpoint_instructions_assistants_placeholder:
    'Yliajaa Avustajan ohjeet. Tätä voi hyödyntää käytöksen muuttamiseen keskustelukohtaisesti.',
  com_endpoint_prompt_prefix_assistants_placeholder:
    'Anna lisäohjeita tai kontekstia Avustajan pääohjeiden lisäksi. Set additional instructions or context on top of the Assistant\'s main instructions. Jätetään huomiotta, jos tyhjä.',
  com_endpoint_custom_name: 'Mukautettu nimi',
  com_endpoint_prompt_prefix: 'Mukautetut ohjeet',
  com_endpoint_prompt_prefix_assistants: 'Lisäohjeet',
  com_endpoint_instructions_assistants: 'Yliaja ohjeet',
  com_endpoint_temperature: 'Lämpötila',
  com_endpoint_default: 'oletus',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top k',
  com_endpoint_max_output_tokens: 'Tulos-tokeneiden maksimimäärä',
  com_endpoint_stop: 'Pysäytyssekvenssit',
  com_endpoint_stop_placeholder: 'Erota arvot toisistaan rivinvaihdoilla',
  com_endpoint_openai_max_tokens: `Valinnainen \`max_tokens\` -kenttä, joka kuvaa keskustelun vastauksessa generoitujen tokeneiden maksimimäärää.

      Syötteen ja vastauksen kokonaispituutta rajoittaa mallin konteksti-ikkuna. Konteksti -ikkunan koon ylittämisestä voi seurata virheitä.`,
  com_endpoint_openai_temp:
    'Korkeampi arvo = satunnaisempi; matalampi arvo = keskittyneempi ja deterministisempi. Suosittelemme, että muokkaat tätä tai Top P:tä, mutta ei molempia.',
  com_endpoint_openai_max:
    'Luotavien tokeneiden maksimimäärä. Mallin konteksti-ikkuna rajoittaa syötteiden ja vastausten kokonaispituutta.',
  com_endpoint_openai_topp:
    'Vaihtoehto lämpötilapohjaiselle otannalle, ydinotanta, valitsee tokeneita Top P -todennäköisyysmassasta. Esimerkiksi arvo 0.1 tarkoittaa että vain top 10% tokeneista todennäköisyysmassassa huomioidaan. Suosittelemme, että muokkaat tätä tai lämpötilaa, mutta ei molempia.',
  com_endpoint_openai_freq:
    'Lukuarvo väliltä -2.0 - 2.0. Positiiviset arvot rankaisevat uusia tokeneita perustuen niiden esiintymistiheyteen siihen mennessä luodussa tekstissä, mikä vähentää todennäköisyyttä, että malli toistaa saman rivin täsmälleen samanlaisena.',
  com_endpoint_openai_pres:
    'Lukuarvo väliltä -2.0 - 2.0. Positiiviset arvot rankaisevat uusia tokeneita perustuen niiden esiintymiseen siihen mennessä luodussa tekstissä, ja lisäävät todennäköisyyttä että malli aloittaa uuden aiheen.',
  com_endpoint_openai_resend:
    'Lähetä uudestaan kaikki aiemmin liitetyt kuvat. Huom: tämä voi lisätä token-kustannuksia huomattavasti, ja useiden kuvien käsittelystä kerralla voi seurata virheitä.',
  com_endpoint_openai_resend_files:
    'Lähetä uudestaan kaikki aiemmin liitetyt tiedostot. Huom: tämä lisää token-kustannuksia, ja useiden tiedostojen käsittelystä kerralla voi seurata virheitä.',
  com_endpoint_openai_detail:
    'Kuvatarkkuus Vision-pyynnöille. "Matala" on halvempi ja nopeampi, "Korkea" on yksityiskohtaisempi ja kalliimpi, ja "Auto" valitsee näiden välillä automaattisesti kuvan koon perusteella.',
  com_endpoint_openai_stop:
    'Enintään 4 sekvenssiä, joiden kohdalla API lopettaa tokenien luomisen.',
  com_endpoint_openai_custom_name_placeholder: 'Anna tekoälylle mukautettu nimi',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Aseta mukautetut ohjeet Järjestelmäohjeisiin sisällytettäväksi. Oletus: tyhjä',
  com_endpoint_anthropic_temp:
    'Vaihteluväli on 0 - 1. Käytä lähempänä nollaa olevaa lämpötilaa analyyttisiin tai monivalintatehtäviin, ja lähempänä yhtä luoviin ja generatiivisiin tehtäviin. Suosittelemme, että muokkaat tätä tai Top P:tä, mutta ei molempia.',
  com_endpoint_anthropic_topp:
    'Top-P vaikuttaa siihen kuinka malli valitsee tokeneita tulokseen. Tokenit valitaan top-k:sta (ks. Top-k -parametri) todennäköisimmistä vähiten todennäköseen, kunnes niiden todennäköisyyksien summa ylittää Top-P -arvon.',
  com_endpoint_anthropic_topk:
    'Top-k vaikuttaa siihen, miten malli valitsee tokeineita tulokseen. Jos Top-k on 1, valitaan se token, joka on kaikkien todennäköisen mallin sanastossa (tunnetaan myös nimellä ahne dekoodaus), kun taas top-k 3 tarkoittaisi, että seuraavat token valitaan 3 todennäköisimmän tokenin joukosta, lämpötilaa hyödyntäen.',
  com_endpoint_anthropic_maxoutputtokens:
    'Vastauksen maksimi-tokenmäärä. valitse pienempi arvo, jos haluat lyhyempiä vastauksia, ja korkeampi arvo, jos haluat pidempiä vastauksia.',
  com_endpoint_anthropic_custom_name_placeholder: 'Aseta mukautettu nimi Anthropicille',
  com_endpoint_frequency_penalty: 'Esiintymistiheysrangaistus',
  com_endpoint_presence_penalty: 'Esiintymisrangaistus',
  com_endpoint_plug_use_functions: 'Käytä Toimintoja',
  com_endpoint_plug_resend_files: 'Lähetä tiedostot uudestaan',
  com_endpoint_plug_resend_images: 'Lähetä kuvat uudestaan',
  com_endpoint_plug_image_detail: 'Kuvan yksityiskohdat',
  com_endpoint_plug_skip_completion: 'Ohita Vastaus',
  com_endpoint_disabled_with_tools: 'poissa käytöstä työkalujan kanssa',
  com_endpoint_disabled_with_tools_placeholder: 'Poissa käytössä Työkalut valittuna',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Aseta mukautetut ohjeet Järjestelmäohjeisiin liitettäviksi. Oletus: tyhjä',
  com_endpoint_import: 'Tuo',
  com_endpoint_set_custom_name: 'Aseta mukautettu nimi, jotta esiasetus olisi helpompi löytää',
  com_endpoint_preset_delete_confirm: 'Haluatko varmasti poistaa nämä esiasetukset?',
  com_endpoint_preset_clear_all_confirm: 'Haluatko varmasti poistaa kaikki esiasetuksesi?',
  com_endpoint_preset_import: 'Esiasetus tuotu!',
  com_endpoint_preset_import_error: 'Esiasetuksen tuonnissa tapahtui virhe. Yritä uudestaan.',
  com_endpoint_preset_save_error: 'Esiasetuksen tallennuksessa tapahtui virhe. Yritä uudestaan.',
  com_endpoint_preset_delete_error: 'Esiasetuksen poistossa tapahtui virhe. Yritä uudestaan.',
  com_endpoint_preset_default_removed: ' ei ole enää oletus-esiasetus.',
  com_endpoint_preset_default_item: 'Oletus:',
  com_endpoint_preset_default_none: 'Oletus-esiasetusta ei ole käytössä',
  com_endpoint_preset_title: 'Esiasetus',
  com_endpoint_preset_saved: 'Tallennettu!',
  com_endpoint_preset_default: 'on nyt oletus-esiasetus.',
  com_endpoint_preset: 'esiasetus',
  com_endpoint_presets: 'esiasetukset',
  com_endpoint_preset_selected: 'Esiasetus käytössä!',
  com_endpoint_preset_selected_title: 'Käytössä!',
  com_endpoint_preset_name: 'Esiasetuksen nimi',
  com_endpoint_new_topic: 'Uusi aihe',
  com_endpoint: 'Päätepiste',
  com_endpoint_hide: 'Piilota',
  com_endpoint_show: 'Näytä',
  com_endpoint_examples: ' Esiasetukset',
  com_endpoint_completion: 'Vastaus',
  com_endpoint_agent: 'Agentti',
  com_endpoint_show_what_settings: 'Näytä {0} asetusta',
  com_endpoint_export: 'Vie',
  com_endpoint_export_share: 'Vie/Jaa',
  com_endpoint_assistant: 'Avustaja',
  com_endpoint_use_active_assistant: 'Käytä aktiivista Avustajaa',
  com_endpoint_assistant_model: 'Avustajan malli',
  com_endpoint_save_as_preset: 'Tallenna esiasetukseksi',
  com_endpoint_presets_clear_warning:
    'Haluatko varmasti tyhjentää kaikki esiasetukset? Tätä toimintoa ei voi perua.',
  com_endpoint_not_implemented: 'Ei toteutettu',
  com_endpoint_no_presets:
    'Ei vielä esiasetuksia. Käytä Asetukset-painiketta luodaksesi esiasetuksen.',
  com_endpoint_not_available: 'Päätepistettä ei ole tarjolla',
  com_endpoint_view_options: 'Katseluvaihtoehdot',
  com_endpoint_save_convo_as_preset: 'Tallenna keskustelu esiasetukseksi',
  com_endpoint_my_preset: 'Esiasetukseni',
  com_endpoint_agent_model: 'Agenttimalli (Suositus: GPT-3.5)',
  com_endpoint_completion_model: 'Vastausmalli (Suositus: GPT-4)',
  com_endpoint_func_hover: 'Salli lisäosien käyttö OpenAI-toimintoina',
  com_endpoint_skip_hover:
    'Mahdollista vastausaskeleen ohitus, joka mahdollistaa lopuulisen vastauksen ja generoitujen askeleiden tarkastelun',
  com_endpoint_config_key: 'Aseta API-avain',
  com_endpoint_assistant_placeholder: 'Valitse Avustaja oikeanpuoleisesta sivupalkista',
  com_endpoint_config_placeholder: 'Keskustellaksesi aseta avaimesi Ylätunnistevalikossa.',
  com_endpoint_config_key_for: 'Aseta API-avain:',
  com_endpoint_config_key_name: 'Avain',
  com_endpoint_config_value: 'Aseta arvo:',
  com_endpoint_config_key_name_placeholder: 'Aseta ensin API-avain',
  com_endpoint_config_key_encryption: 'Avaimesi salataan ja poistetaan: ',
  com_endpoint_config_key_never_expires: 'Avaimesi ei koskaan vanhene',
  com_endpoint_config_key_expiry: 'vanhenemisaika',
  com_endpoint_config_click_here: 'Napauta tästä',
  com_endpoint_config_google_service_key: 'Google Service Account Key',
  com_endpoint_config_google_cloud_platform: '(Google Cloud Platform:ista)',
  com_endpoint_config_google_api_key: 'Google API Key',
  com_endpoint_config_google_gemini_api: '(Gemini API)',
  com_endpoint_config_google_api_info:
    'Saadaksesi Generative Language API -avaimesi (Gemini:a varten),',
  com_endpoint_config_key_import_json_key: 'Tuo palveluosoitteen JSON-avain.',
  com_endpoint_config_key_import_json_key_success:
    'Palveluosoitteetn JSON-avain tuotu onnistuneesti',
  com_endpoint_config_key_import_json_key_invalid:
    'Virheellinen palveluosoitteen JSON-avain. Toitko oikean tiedoston?',
  com_endpoint_config_key_get_edge_key: 'Saadaksisi pääsytunnuksesi Bingiä varten, kirjaudu',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Käytä kehitystyökaluja ja lisäosaa sivustolle kirjautuneena _U -evästeen kopioimiseen. Jos tämä ei toimi, seuraa näitä',
  com_endpoint_config_key_edge_instructions: 'ohjeita',
  com_endpoint_config_key_edge_full_key_string: 'saadaksesi täydet evästemerkkijonot.',
  com_endpoint_config_key_chatgpt:
    'Saadaksesi pääsytunnuksesi ChatGPT:n \'ilmaisversiota\' varten, kirjaudu',
  com_endpoint_config_key_chatgpt_then_visit: 'sitten vieraile',
  com_endpoint_config_key_chatgpt_copy_token: 'Kopioi pääsytunnus.',
  com_endpoint_config_key_google_need_to: 'Sinun täytyy',
  com_endpoint_config_key_google_vertex_ai: 'sallia Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API Google Cloud:issa, sitten',
  com_endpoint_config_key_google_service_account: 'Luo Palvelutili (Service Account)',
  com_endpoint_config_key_google_vertex_api_role:
    'Muista napauttaa \'Create and Continue\' jotta saat ainakin \'Vertex AI User\' -roolin. Lopuksi luo JSON-avain tänne tuotavaksi.',
  com_nav_welcome_assistant: 'Ole hyvä ja valitse Avustaja',
  com_nav_welcome_message: 'Miten voin auttaa tänään?',
  com_nav_auto_scroll: 'Vieritä automaattisesti viimeisimpään viestiin keskustelua avatessa',
  com_nav_hide_panel: 'Piilota oikeanpuoleinen sivupaneeli',
  com_nav_modular_chat: 'Salli päätepisteen vaihto kesken keskustelun',
  com_nav_latex_parsing: 'Tulkitse LaTeX:ia viesteissä (saattaa vaikuttaa suoritustehoon)',
  com_nav_text_to_speech: 'Tekstistä puheeksi',
  com_nav_automatic_playback: 'Toista viimeisin viesti automaattisesti',
  com_nav_speech_to_text: 'Puheesta tekstiksi',
  com_nav_profile_picture: 'Profiilikuva',
  com_nav_change_picture: 'Vaihda kuva',
  com_nav_plugin_store: 'Lisäosakauppa',
  com_nav_plugin_install: 'Asenna',
  com_nav_plugin_uninstall: 'Poista',
  com_ui_add: 'Lisää',
  com_nav_tool_remove: 'Poista',
  com_nav_tool_dialog: 'Avustajatyökalut',
  com_ui_misc: 'Muu',
  com_ui_roleplay: 'Roolipeli',
  com_ui_write: 'Kirjoittaminen',
  com_ui_idea: 'Ideat',
  com_ui_shop: 'Ostokset',
  com_ui_finance: 'Talous',
  com_ui_code: 'Koodi',
  com_ui_travel: 'Matkustus',
  com_ui_teach_or_explain: 'Oppiminen',
  com_ui_select_a_category: 'Kategoriaa ei valittu',
  com_nav_tool_dialog_description: 'Avustaja täytyy tallentaa, jotta työkaluvalinta säilyisi.',
  com_show_agent_settings: 'Näytä Agentin asetukset',
  com_show_completion_settings: 'Näytä Vastausasetukset',
  com_hide_examples: 'Piilota esimerkit',
  com_show_examples: 'Näytä esimerkit',
  com_nav_plugin_search: 'Hae lisäosaa',
  com_nav_tool_search: 'Hakutyökalut',
  com_nav_plugin_auth_error: 'Tämän lisäosan varmentamisessa tapahtui virhe. Yritä uudestaan.',
  com_nav_export_filename: 'Tiedoston nimi',
  com_nav_export_filename_placeholder: 'Aseta tiedoston nimi',
  com_nav_export_type: 'Tyyppi',
  com_nav_export_include_endpoint_options: 'Sisällytä päätepistevaihtoehdot',
  com_nav_enabled: 'Päällä',
  com_nav_not_supported: 'Ei tuettu',
  com_nav_export_all_message_branches: 'Vie kaikki sivupolut',
  com_nav_export_recursive_or_sequential: 'Rekursiivisesti vai sarjassa?',
  com_nav_export_recursive: 'Rekursiivisesti',
  com_nav_export_conversation: 'Vie keskustelu',
  com_nav_export: 'Vie',
  com_nav_shared_links: 'Jaetut linkit',
  com_nav_shared_links_manage: 'Hallinnoi',
  com_nav_shared_links_empty: 'Sinulla ei ole jaettuja linkkejä.',
  com_nav_shared_links_name: 'Nimi',
  com_nav_shared_links_date_shared: 'Jakopäivä',
  com_nav_source_chat: 'Katsele lähdekeskustelua',
  com_nav_my_files: 'Omat tiedostot',
  com_nav_theme: 'Teema',
  com_nav_theme_system: 'Oletus',
  com_nav_theme_dark: 'Tumma',
  com_nav_theme_light: 'Vaalea',
  com_nav_enter_to_send: 'Lähetä viestit Enter-painikkeella',
  com_nav_user_name_display: 'Näytä käyttäjänimi viesteissä',
  com_nav_save_drafts: 'Tallenna luonnokset paikallisesti',
  com_nav_show_code: 'Kooditulkkia käyttäessä näytä aina koodi',
  com_nav_auto_send_prompts: 'Lähetä syötteet automaattisesti',
  com_nav_always_make_prod: 'Tee aina uudet versiot tuotantoon',
  com_nav_clear_all_chats: 'Tyhjennä kaikki keskustelut',
  com_nav_confirm_clear: 'Vahvista tyhjennys',
  com_nav_close_sidebar: 'Sulje sivupalkki',
  com_nav_open_sidebar: 'Avaa sivupalkki',
  com_nav_send_message: 'Lähetä viesti',
  com_nav_log_out: 'Kirjaudu ulos',
  com_nav_user: 'KÄYTTÄJÄ',
  com_nav_archived_chats: 'Arkistoidut keskustelut',
  com_nav_archived_chats_manage: 'Hallinnoi',
  com_nav_archived_chats_empty: 'Sinulla ei ole arkistoituja keskusteluita.',
  com_nav_archive_all_chats: 'Arkistoi kaikki keskustelut',
  com_nav_archive_all: 'Arkistoi kaikki',
  com_nav_archive_name: 'Nimi',
  com_nav_archive_created_at: 'Arkistointipäivä',
  com_nav_clear_conversation: 'Tyhjennä keskustelut',
  com_nav_clear_conversation_confirm_message:
    'Oletko varma että haluat tyhjentää kaikki keskustelut? Tätä toimintoa ei voi peruuttaa.',
  com_nav_help_faq: 'Ohjeet & FAQ',
  com_nav_settings: 'Asetukset',
  com_nav_search_placeholder: 'Etsi keskusteluista',
  com_nav_delete_account: 'Poista käyttäjätili',
  com_nav_delete_account_confirm: 'Poista käyttäjätili - oletko varma?',
  com_nav_delete_account_button: 'Poista käyttäjätilini pysyvästi',
  com_nav_delete_account_email_placeholder: 'Syötä käyttäjätilisi sähköpostiosoite',
  com_nav_delete_account_confirm_placeholder:
    'Jatkaaksesi syötä "DELETE" alla olevaan syötekenttään',
  com_nav_delete_warning: 'VAROITUS: Tämä poistaa käyttäjätilisi pysyvästi.',
  com_nav_delete_data_info: 'Kaikki tietosi poistetaan.',
  com_nav_conversation_mode: 'Keskustelumoodi',
  com_nav_auto_send_text: 'Lähetä teksti automaattisesti (3 sekunnin kuluttua)',
  com_nav_auto_transcribe_audio: 'Automaattinen äänen litterointi',
  com_nav_db_sensitivity: 'Desibeliherkkyys',
  com_nav_playback_rate: 'Äänen toiston nopeus',
  com_nav_audio_play_error: 'Virhe ääntä toistaessa: {0}',
  com_nav_audio_process_error: 'Virhe ääntä käsitellessä: {0}',
  com_nav_long_audio_warning: 'Pidemmän tekstin käsittely kestää kauemmin.',
  com_nav_engine: 'Puhemoottori',
  com_nav_browser: 'Selain',
  com_nav_external: 'Ulkoinen',
  com_nav_delete_cache_storage: 'Tyhjennä TTS (tekstistä ääneksi) -välimuistivarasto',
  com_nav_enable_cache_tts: 'TTS (tekstistä ääneksi) -välimuisti käyttöön',
  com_nav_voice_select: 'Ääni',
  com_nav_info_enter_to_send:
    'Jos tämä on päällä, Enter-näppäimen painaminen lähettää viestin. Kun asetus on pois päältä, Enter lisää rivinvaihdon, ja viestin lähettämiseksi on painettava CTRL + ENTER.',
  com_nav_info_save_draft:
    'Jos tämä on päällä, teksti ja liitteet jotka syötät keskusteluun tallennetaan automaattisesti paikallisina luonnoksina. Nämä luonnokset ovat käytettävissä, vaikka välillä lataisit sivun uudestaan tai vaihtaisit toiseen keskusteluun. Luonnokset on tallettettu paikallisesti omalle laitteellesi, ja ne poistetaan, kun viesti on lähetetty.',
  com_nav_info_fork_change_default:
    '\'Vain näkyvät viestit\' sisältää vain suoran polun valittuun viestiin. \'Sisällytä sivupolut\' lisää polun varrella olevat sivupolut. \'Lisää kaikki tänne/täältä\' sisällyttää kaikki kytköksissä olevat viestit ja sivupolut.',
  com_nav_info_fork_split_target_setting:
    'Jos tämä on päällä, haara syntyy kohdeviestistä keskustelun viimeiseen viestiin valitun haarautumistavan mukaisesti.',
  com_nav_info_user_name_display:
    'Jos tämä on päällä, lähettäjän käyttäjänimi näytetään jokaisen lähettämäsi viestin päällä. Jos tämä ei ole käytössä, viestien päällä näytetään vain "Sinä".',
  com_nav_info_latex_parsing:
    'Kun tämä on päällä, viesteissä oleva LaTeX-koodi näytetään yhtälöinä. Tämän asetuksen jättäminen pois päältä saattaa parantaa suorituskykyä, jos et tarvitse LaTeX-tulkkia.',
  com_nav_info_revoke:
    'Tämä toiminto peruu ja poistaa kaikki antamasi API-avaimet. Ennen kuin voit jatkaa päätepisteiden käyttöä, sinun on syötettävä uudet tunnisteet.',
  com_nav_info_delete_cache_storage:
    'Tämä toiminto poistaa kaikki laitteesi välimuistiin tallennetut TTS (tekstistä puheeksi) -äänitiedostot. Välimuistiin tallennettuja äänitiedostoja käytetään aiemmin luotujen TTS-tiedostojen toistamisen nopeuttamikseksi, mutta ne saattavat viedä levytilaa laitteellasi.',
  com_nav_setting_general: 'Yleiset',
  com_nav_setting_beta: 'Beta-toiminnot',
  com_nav_setting_data: 'Datakontrollit',
  com_nav_setting_account: 'Käyttäjätili',
  com_nav_setting_speech: 'Puhe',
  com_nav_language: 'Kieli',
  com_nav_lang_auto: 'Tunnista automaattisesti',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_polish: 'Polski',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
  com_nav_lang_russian: 'Русский',
  com_nav_lang_japanese: '日本語',
  com_nav_lang_swedish: 'Svenska',
  com_nav_lang_korean: '한국어',
  com_nav_lang_vietnamese: 'Tiếng Việt',
  com_nav_lang_traditionalchinese: '繁體中文',
  com_nav_lang_arabic: 'العربية',
  com_nav_lang_turkish: 'Türkçe',
  com_nav_lang_dutch: 'Nederlands',
  com_nav_lang_indonesia: 'Indonesia',
  com_nav_lang_hebrew: 'עברית',
  com_nav_lang_finnish: 'Suomi',
};
