import { useMemo, useState, useEffect, useRef } from 'react';
import { Constants } from 'librechat-data-provider';
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { useChatContext } from '~/Providers';
import { getKey } from '~/utils/artifacts';
import { getLatestText } from '~/utils';
import store from '~/store';

// Helper to determine if content is likely code
const isLikelyCode = (content: string) => {
  // Check for common code indicators
  const codePatterns = [
    /^(const|let|var|function|class|import|export|#include|def|public|private)/m,
    /{[\s\S]*}/,  // JSON-like or code blocks
    /<[^>]+>/,    // HTML/XML tags
    /^[#;].*$/m,  // Comment lines
    /\b(if|for|while|return|try|catch)\b/
  ];
  return codePatterns.some(pattern => pattern.test(content));
};

// Helper to determine if content is likely visual/preview content
const isLikelyPreviewContent = (content: string, type: string) => {
  // Check for content types that are better viewed in preview
  const previewTypes = ['mermaid', 'html', 'svg', 'markdown', 'md'];
  if (previewTypes.includes(type.toLowerCase())) return true;

  // Check for HTML-like content
  if (content.trim().startsWith('<') && content.trim().endsWith('>')) return true;

  // Check for Markdown-like content
  const markdownPatterns = [
    /^#\s+/m,     // Headers
    /\[.*\]\(.*\)/, // Links
    /!\[.*\]\(.*\)/, // Images
    /^\s*[-*+]\s+/m, // Lists
    /\|.*\|.*\|/   // Tables
  ];
  return markdownPatterns.some(pattern => pattern.test(content));
};

export default function useArtifacts() {
  const [activeTab, setActiveTab] = useState('preview');
  const { isSubmitting, latestMessage, conversation } = useChatContext();

  // Use scoped artifacts instead of global artifacts
  const scopedArtifacts = useRecoilValue(store.scopedArtifactsState);
  const setActiveConversationId = useSetRecoilState(store.activeConversationId);
  const resetArtifacts = useResetRecoilState(store.artifactsState);
  const resetCurrentArtifactId = useResetRecoilState(store.currentArtifactId);
  const [currentArtifactId, setCurrentArtifactId] = useRecoilState(store.currentArtifactId);
  const [artifactsVisible, setArtifactsVisible] = useRecoilState(store.artifactsVisible);

  const orderedArtifactIds = useMemo(() => {
    return Object.keys(scopedArtifacts ?? {}).sort(
      (a, b) => (scopedArtifacts?.[a]?.lastUpdateTime ?? 0) - (scopedArtifacts?.[b]?.lastUpdateTime ?? 0),
    );
  }, [scopedArtifacts]);

  const prevConversationIdRef = useRef<string | null>(null);
  const prevArtifactIdRef = useRef<string | null>(null);

  // Update active conversation when conversation changes or component mounts
  useEffect(() => {
    if (conversation?.conversationId) {
      console.log('[useArtifacts] Setting active conversation:', conversation.conversationId);
      setActiveConversationId(conversation.conversationId);
    }
  }, [conversation?.conversationId, setActiveConversationId]);

  // Reset state when switching conversations or starting new conversation
  useEffect(() => {
    console.log('[useArtifacts] Conversation change detected:', {
      current: conversation?.conversationId,
      previous: prevConversationIdRef.current,
      artifactsVisible,
      currentArtifactId,
      hasArtifacts: !!scopedArtifacts,
    });

    const isConversationSwitch = 
      conversation && 
      conversation.conversationId !== prevConversationIdRef.current && 
      prevConversationIdRef.current != null;

    const isNewConversation = 
      conversation && 
      conversation.conversationId === Constants.NEW_CONVO;

    if (isConversationSwitch || isNewConversation) {
      console.log('[useArtifacts] Resetting states for conversation change');
      
      // Reset all states immediately
      setArtifactsVisible(false);
      resetArtifacts();
      resetCurrentArtifactId();
      prevArtifactIdRef.current = null;
    }

    prevConversationIdRef.current = conversation?.conversationId ?? null;
  }, [conversation, resetArtifacts, resetCurrentArtifactId, setArtifactsVisible, scopedArtifacts]);

  // Show artifacts panel whenever we have artifacts
  useEffect(() => {
    // Debug log to confirm effect is running
    console.log('[useArtifacts] Effect triggered:', {
      hasArtifacts: !!scopedArtifacts,
      artifactCount: orderedArtifactIds.length,
      currentlyVisible: artifactsVisible,
    });

    if (!scopedArtifacts || orderedArtifactIds.length === 0) {
      console.log('[useArtifacts] No artifacts to display');
      return;
    }

    const latestArtifactId = orderedArtifactIds[orderedArtifactIds.length - 1];
    
    // Skip if we've already processed this artifact
    if (latestArtifactId === prevArtifactIdRef.current) {
      console.log('[useArtifacts] Already processed this artifact:', latestArtifactId);
      return;
    }

    const latestArtifact = scopedArtifacts[latestArtifactId];

    console.log('[useArtifacts] Processing new artifact:', {
      artifactId: latestArtifactId,
      hasContent: !!latestArtifact?.content,
      contentLength: latestArtifact?.content?.length,
      isVisible: artifactsVisible,
      previousArtifactId: prevArtifactIdRef.current,
    });

    // Show panel and set current artifact
    setCurrentArtifactId(latestArtifactId);
    setArtifactsVisible(true);
    prevArtifactIdRef.current = latestArtifactId;

    // Set appropriate view mode based on content type and structure
    if (latestArtifact?.content) {
      const content = latestArtifact.content;
      const type = latestArtifact.type ?? '';

      if (isLikelyPreviewContent(content, type)) {
        console.log('[useArtifacts] Preview content detected - switching to preview');
        setActiveTab('preview');
      } else if (isLikelyCode(content)) {
        console.log('[useArtifacts] Code content detected - switching to code view');
        setActiveTab('code');
      } else {
        // Default to preview for unknown content types
        console.log('[useArtifacts] Unknown content type - defaulting to preview');
        setActiveTab('preview');
      }
    }
  }, [scopedArtifacts, orderedArtifactIds, setCurrentArtifactId, setArtifactsVisible, latestMessage, artifactsVisible]);

  const currentArtifact = currentArtifactId != null ? scopedArtifacts?.[currentArtifactId] : null;

  const currentIndex = orderedArtifactIds.indexOf(currentArtifactId ?? '');
  const cycleArtifact = (direction: 'next' | 'prev') => {
    if (orderedArtifactIds.length === 0) return;
    
    let newIndex: number;
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % orderedArtifactIds.length;
    } else {
      newIndex = (currentIndex - 1 + orderedArtifactIds.length) % orderedArtifactIds.length;
    }
    console.log('[useArtifacts] Cycling artifact:', {
      direction,
      newIndex,
      newArtifactId: orderedArtifactIds[newIndex]
    });
    
    const nextArtifact = scopedArtifacts?.[orderedArtifactIds[newIndex]];
    setCurrentArtifactId(orderedArtifactIds[newIndex]);

    // Set appropriate tab for the new artifact
    if (nextArtifact?.content) {
      if (isLikelyPreviewContent(nextArtifact.content, nextArtifact.type ?? '')) {
        setActiveTab('preview');
      } else if (isLikelyCode(nextArtifact.content)) {
        setActiveTab('code');
      }
    }
  };

  const isMermaid = useMemo(() => {
    if (currentArtifact?.type == null) {
      return false;
    }
    const key = getKey(currentArtifact.type, currentArtifact.language);
    return key.includes('mermaid');
  }, [currentArtifact?.type, currentArtifact?.language]);

  return {
    activeTab,
    isMermaid,
    setActiveTab,
    currentIndex,
    isSubmitting,
    cycleArtifact,
    currentArtifact,
    orderedArtifactIds,
  };
}
