declare global {
  interface Window {
    dataLayer: any[];
  }
}

type ToolEvent = {
  tool_type: string;
  tool_data?: Record<string, unknown>;
  conversation_id?: string;
  message_id?: string;
  success?: boolean;
  error?: string;
};

type ArtifactEvent = {
  artifact_id: string;
  artifact_title: string;
  artifact_type: string;
};

export const trackToolUsage = ({
  tool_type,
  tool_data = {},
  conversation_id,
  message_id,
  success = true,
  error = '',
}: ToolEvent) => {
  if (typeof window === 'undefined') {
    return;
  }

  // Initialize dataLayer if it doesn't exist
  window.dataLayer = window.dataLayer || [];
  
  const eventData = {
    event: 'tool_usage',
    tool_type,
    ...tool_data,
    conversation_id,
    message_id,
    success,
    error,
    timestamp: new Date().toISOString(),
  };

  //console.log('Pushing to dataLayer:', eventData);
  
  // Push directly to dataLayer for immediate availability
  window.dataLayer.push(eventData);

  // Debug: Log current dataLayer state
  //console.log('Current dataLayer:', window.dataLayer);
};

export const trackArtifactUsage = ({
  artifact_id,
  artifact_title,
  artifact_type,
}: ArtifactEvent) => {
  if (typeof window === 'undefined') {
    return;
  }

  // Initialize dataLayer if it doesn't exist
  window.dataLayer = window.dataLayer || [];
  
  const eventData = {
    event: 'artifact_usage',
    artifact_id,
    artifact_title,
    artifact_type,
    timestamp: new Date().toISOString(),
  };
  
  // Push directly to dataLayer for immediate availability
  window.dataLayer.push(eventData);
};
