import { memo } from 'react';
import CodeArtifacts from './CodeArtifacts';

function Beta() {
  return (
    <div className="text-text-primary flex flex-col gap-3 p-1 text-sm">
      <div className="bordered-item">
        <CodeArtifacts />
      </div>
    </div>
  );
}

export default memo(Beta);
