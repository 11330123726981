import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';
import App from './App';
import './style.css';
import './mobile.css';
import { ApiErrorBoundaryProvider } from './hooks/ApiErrorBoundaryContext';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_CLIENT_DSN,
  debug: import.meta.env.NODE_ENV === 'production' ? false : true,
  integrations: [
    Sentry.feedbackIntegration({
      autoInject: false,
      showBranding: false,
      messagePlaceholder: 'See a bug or have feedback? Let us know what you think.',
      enableScreenshot: false
    }),
  ],
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ApiErrorBoundaryProvider>
    <Sentry.ErrorBoundary fallback={'An error has occurred'} showDialog>
      <App />
    </Sentry.ErrorBoundary>
  </ApiErrorBoundaryProvider>,
);
