import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WhitelistHeader } from './WhitelistHeader';
import { EmailList } from './EmailList';
import { WhitelistForm } from './WhitelistForm';
import { useWhitelist } from '~/hooks';
import ThemeSelector from '~/components/ui/ThemeSelector';
import type { Email, EmailData } from '~/types/whitelist';

type ApiError = {
  message: string;
};

export default function WhitelistManagement() {
  const navigate = useNavigate();
  const {
    emails,
    isLoading,
    isError,
    error,
    addEmail,
    editEmail,
    removeEmail,
    searchTerm,
    setSearchTerm,
    showForm,
    setShowForm,
    editMode,
    setEditMode,
    editingEmail,
    setEditingEmail,
    success,
    setSuccess,
  } = useWhitelist();

  const handleAddEmail = (email: string, company: string, notes: string) => {
    const emailData: EmailData = { email, company, notes };
    if (editMode && editingEmail) {
      editEmail.mutate({
        currentEmail: editingEmail.email,
        data: emailData,
      });
    } else {
      addEmail.mutate(emailData);
    }
    setSuccess(
      editMode ? 'Email updated successfully' : 'Email added successfully',
    );
    setTimeout(() => setSuccess(''), 3000);
    setShowForm(false);
  };

  const handleEditEmail = (item: Email) => {
    setEditMode(true);
    setEditingEmail(item);
    setShowForm(true);
  };

  const handleRemoveEmail = (email: string) => {
    removeEmail.mutate(email);
    setSuccess('Email removed successfully');
    setTimeout(() => setSuccess(''), 3000);
  };

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-3xl font-semibold text-gray-500">Loading...</div>
      </div>
    );
  }

  if (isError && typeof error === 'object' && error !== null) {
    const apiError = error as ApiError;
    return (
      <div className="flex items-center justify-center p-8">
        <div className="text-red-500">
          Error loading whitelisted emails:{' '}
          {apiError.message || 'Unknown error'}
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen w-full bg-[#F2F2F2] pb-20 dark:bg-[#0F172A]">
      <div className="mx-auto w-full px-8 py-8 lg:px-32">
        <WhitelistHeader
          onBack={() => navigate(-1)}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onAddClick={() => {
            setShowForm(true);
            setEditMode(false);
            setEditingEmail(null);
          }}
        />
        {success && (
          <div className="fixed right-4 top-4 rounded border border-green-400 bg-green-100 px-4 py-3 text-green-700 shadow-lg transition-opacity duration-300">
            {success}
          </div>
        )}
        {showForm && (
          <WhitelistForm
            onSubmit={handleAddEmail}
            editMode={editMode}
            editingEmail={editingEmail}
            onClose={() => setShowForm(false)}
            existingEmails={emails.map((emailObj) =>
              emailObj.email.toLowerCase(),
            )}
          />
        )}
        <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
          <EmailList
            emails={emails}
            searchTerm={searchTerm}
            onEdit={handleEditEmail}
            onRemove={handleRemoveEmail}
          />
        </div>
      </div>
      <div className="fixed bottom-0 left-0 z-10 w-full bg-[#F2F2F2] p-4 dark:bg-[#0F172A]">
        <ThemeSelector />
      </div>
    </div>
  );
}
