import { FileSources } from 'librechat-data-provider';
import type { TFile } from 'librechat-data-provider';

export const files: TFile[] = [
  {
    _id: '65b004acd70ce86b9146e9dd',
    file_id: 'file-CbxzlOiGvaG2uwhuAdKXdUpX',
    __v: 0,
    bytes: 18740,
    createdAt: '2024-01-23T18:25:48.153Z',
    filename: 'dataset.xlsx',
    filepath: 'https://api.openai.com/v1/files/file-CbxzlOiGvaG2uwhuAdKXdUpX',
    object: 'file',
    source: FileSources.openai,
    temp_file_id: '63214c34-2d2c-445f-9c60-5cf04c15607c',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    updatedAt: '2024-01-23T18:25:48.153Z',
    usage: 0,
    user: '652ac880c4102a77fe54c5db',
    embedded: false,
  },
  {
    _id: '65b004abd70ce86b9146e861',
    file_id: '86fe0534-803c-4e88-b730-73ec4187742f',
    __v: 0,
    bytes: 3147861,
    createdAt: '2024-01-23T18:25:47.698Z',
    filename: 'img-337c49c7-fb1f-4a14-939d-40d12de11d5c.png',
    filepath: '/images/652ac880c4102a77fe54c5db/img-337c49c7-fb1f-4a14-939d-40d12de11d5c.png',
    height: 1024,
    object: 'file',
    source: FileSources.local,
    type: 'image/png',
    updatedAt: '2024-01-23T18:25:47.698Z',
    usage: 0,
    user: '652ac880c4102a77fe54c5db',
    width: 1024,
    embedded: false,
  },
  {
    _id: '65b00495d70ce86b9146adc1',
    file_id: 'e301fdff-6fae-48d3-a9a2-c7fe66357890',
    __v: 0,
    bytes: 3147861,
    createdAt: '2024-01-23T18:25:25.324Z',
    filename: 'img-459c76d1-16b7-48f9-9ff7-85ba6464e204.png',
    filepath: '/images/652ac880c4102a77fe54c5db/img-459c76d1-16b7-48f9-9ff7-85ba6464e204.png',
    height: 1024,
    object: 'file',
    source: FileSources.local,
    type: 'image/png',
    updatedAt: '2024-01-23T18:25:25.324Z',
    usage: 0,
    user: '652ac880c4102a77fe54c5db',
    width: 1024,
    embedded: false,
  },
  {
    _id: '65b00494d70ce86b9146ace6',
    file_id: '63cf2058-3ad1-4712-afbe-6b475119c33a',
    __v: 0,
    bytes: 3147861,
    createdAt: '2024-01-23T18:25:25.035Z',
    filename: 'img-c3fb2935-e578-4d72-b397-d1dcb122af67.png',
    filepath: '/images/652ac880c4102a77fe54c5db/img-c3fb2935-e578-4d72-b397-d1dcb122af67.png',
    height: 1024,
    object: 'file',
    source: FileSources.local,
    type: 'image/png',
    updatedAt: '2024-01-23T18:25:25.035Z',
    usage: 0,
    user: '652ac880c4102a77fe54c5db',
    width: 1024,
    embedded: false,
  },
];
