import { useLayoutEffect, useRef } from 'react';
import darkTopVector from '/assets/vectors/darkTopVector.png';
import lightTopVector from '/assets/vectors/lightTopVector.svg';
import lightModeBackground from '/assets/authNoiseBackground/lightModeBackground.png';
import darkModeBackground from '/assets/authNoiseBackground/darkModeBackground.png';
import './css/background.css';

interface BackgroundLayoutProps {
  children: React.ReactNode;
}

export default function BackgroundLayout({ children }: BackgroundLayoutProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    function checkHeight() {
      const container = containerRef.current;
      if (!container) {
        return;
      }

      if (window.innerWidth >= 1024) {
        const height = container.offsetHeight;

        if (height >= 1100) {
          container.classList.add('show-bottom-vector');
        } else {
          container.classList.remove('show-bottom-vector');
        }

        if (height >= 900) {
          container.classList.add('show-side-vector');
        } else {
          container.classList.remove('show-side-vector');
        }
      } else {
        container.classList.add('show-bottom-vector', 'show-side-vector');
      }
    }

    checkHeight();

    window.addEventListener('resize', checkHeight);

    const observer = new MutationObserver(checkHeight);
    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener('resize', checkHeight);
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="background relative flex min-h-screen flex-col items-center justify-center bg-white py-32 align-middle dark:bg-[#0f172a]"
    >
      <div 
        className="absolute inset-0 z-0 bg-repeat dark:hidden"
        style={{ backgroundImage: `url(${lightModeBackground})` }}
      />
      <div
        className="absolute inset-0 z-0 hidden bg-repeat dark:block"
        style={{ backgroundImage: `url(${darkModeBackground})` }}
      />
      <img
        src={lightTopVector}
        alt="Light Top Vector"
        className="top-vector dark:hidden"
      />
      <img
        src={darkTopVector}
        alt="Dark Top Vector"
        className="top-vector hidden dark:block"
      />
      {children}
    </div>
  );
}
