import React from 'react';

type ErrorMessageProps = {
  children: React.ReactNode;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => {
  const processContent = (content: React.ReactNode): React.ReactNode => {
    if (typeof content !== 'string') {
      return content;
    }
    
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    const parts: string[] = content.split(emailRegex);
    const emails: string[] = content.match(emailRegex) || [];
    
    return parts.map((part: string, index: number): React.ReactNode => {
      if (emails.includes(part)) {
        return (
          <a
            key={index}
            href={`mailto:${part}`}
            className="underline hover:opacity-80"
          >
            {part}
          </a>
        );
      }
      return <React.Fragment key={index}>{part}</React.Fragment>;
    });
  };

  return (
    <div
      className="error-background max-w-[658px] mx-auto text-center rounded-lg border border-[#d71f1f] px-6 py-4 text-sm font-medium text-[#d71f1f]"
      role="alert"
    >
      {React.Children.map(children, child => 
        typeof child === 'string' ? processContent(child) : child,
      )}
    </div>
  );
};
