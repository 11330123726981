import React from 'react';
import { cn } from '~/utils';

type IconProps = {
  className?: string;
  size?: string;
};

const GitForkIcon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { className = 'icon-md', size = '1.2em' } = props;

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={cn('icon-fork', className)} // Add the common class for hover effect
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <defs>
        <linearGradient
          id="gradient-fill-gitfork"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#F214A1" />
          <stop offset="100%" stopColor="#BC0278" />
        </linearGradient>

        <linearGradient
          id="gradient-fill-vertical-line"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" stopColor="#F214A1" />
          <stop offset="100%" stopColor="#BC0278" />
        </linearGradient>
      </defs>

      <circle
        cx="12"
        cy="18"
        r="3"
        stroke="currentColor" // Default color controlled via CSS
        strokeWidth="2"
        fill="none"
      />
      <circle
        cx="6"
        cy="6"
        r="3"
        stroke="currentColor" // Default color controlled via CSS
        strokeWidth="2"
        fill="none"
      />
      <circle
        cx="18"
        cy="6"
        r="3"
        stroke="currentColor" // Default color controlled via CSS
        strokeWidth="2"
        fill="none"
      />

      <path
        d="M18 9v2c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1V9"
        stroke="currentColor" // Default color controlled via CSS
        strokeWidth="2"
        fill="none"
      />

      <path
        d="M12 12v3"
        stroke="currentColor" // Default color controlled via CSS
        strokeWidth="2"
        fill="none"
      />
    </svg>
  );
});

export default GitForkIcon;
