// Arabic phrases

export default {
  com_ui_examples: 'أمثلة',
  com_ui_happy_birthday: 'إنه عيد ميلادي الأول!',
  com_ui_new_chat: 'دردشة جديدة',
  com_ui_example_quantum_computing: 'شرح الحوسبة الكمومية ببساطة',
  com_ui_example_10_year_old_b_day: 'هل لديك أفكار إبداعية لعيد ميلاد طفل عمره 10 سنوات؟',
  com_ui_example_http_in_js: 'كيف أقوم بإجراء طلب HTTP في Javascript؟',
  com_ui_capabilities: 'القدرات',
  com_ui_capability_remember: 'يتذكر ما قاله المستخدم في وقت سابق من المحادثة',
  com_ui_capability_correction: 'يسمح للمستخدم بتقديم تصحيحات لاحقة',
  com_ui_capability_decline_requests: 'تم تدريبه على رفض الطلبات غير المناسبة',
  com_ui_limitations: 'القيود',
  com_ui_limitation_incorrect_info: 'قد يولد معلومات غير صحيحة أحيانًا',
  com_ui_limitation_harmful_biased: 'قد ينتج تعليمات ضارة أو محتوى متحيز أحيانًا',
  com_ui_limitation_limited_2021: 'معرفة محدودة بالعالم والأحداث بعد عام 2021',
  com_ui_input: 'إدخال',
  com_ui_close: 'إغلاق',
  com_ui_model: 'النموذج',
  com_ui_select_model: 'اختر نموذجًا',
  com_ui_use_prompt: 'استخدم الأمر',
  com_ui_prev: 'السابق',
  com_ui_next: 'التالي',
  com_ui_prompt_templates: 'قوالب الأوامر',
  com_ui_hide_prompt_templates: 'إخفاء قوالب الأوامر',
  com_ui_showing: 'عرض',
  com_ui_of: 'من',
  com_ui_entries: 'الإدخالات',
  com_ui_pay_per_call:
    'الذكاء الاصطناعي يمكن ان ينتج اخطاء الرجاء التاكد بنفسك من المعلومات المهمه.',
  com_ui_enter: 'أدخل',
  com_ui_submit: 'إرسال',
  com_ui_upload_success: 'تم تحميل الملف بنجاح',
  com_ui_cancel: 'إلغاء',
  com_ui_save: 'حفظ',
  com_ui_copy_to_clipboard: 'نسخ إلى الحافظة',
  com_ui_copied_to_clipboard: 'تم النسخ إلى الحافظة',
  com_ui_regenerate: 'إعادة توليد',
  com_ui_continue: 'استمر',
  com_ui_edit: 'تعديل',
  com_ui_success: 'نجاح',
  com_ui_all: 'الكل',
  com_ui_clear: 'مسح',
  com_ui_revoke: 'إلغاء',
  com_ui_revoke_info: 'إلغاء جميع بيانات الاعتماد المقدمة من المستخدم.',
  com_ui_import_conversation: 'استيراد',
  com_ui_import_conversation_info: 'استيراد محادثات من ملف JSON',
  com_ui_import_conversation_success: 'تم استيراد المحادثات بنجاح',
  com_ui_import_conversation_error: 'حدث خطأ أثناء استيراد محادثاتك',
  com_ui_confirm_action: 'تأكيد الإجراء',
  com_ui_chats: 'الدردشات',
  com_ui_share: 'مشاركة',
  com_ui_copy_link: 'نسخ الرابط',
  com_ui_update_link: 'رابط التحديث',
  com_ui_create_link: 'إنشاء رابط',
  com_ui_share_link_to_chat: 'شارك الرابط في الدردشة',
  com_ui_share_error: 'حدث خطأ أثناء مشاركة رابط الدردشة',
  com_ui_share_retrieve_error: 'حدث خطأ أثناء حذف الرابط المشترك.',
  com_ui_share_delete_error: 'حدث خطأ أثناء حذف الرابط المشترك.',
  com_ui_share_create_message: 'سيظل اسمك وأي رسائل تضيفها بعد المشاركة خاصة.',
  com_ui_share_created_message:
    'تم إنشاء رابط مشترك للدردشة الخاصة بك. يمكنك إدارة الدردشات المشتركة مسبقًا في أي وقت عبر الإعدادات.',
  com_ui_share_update_message: 'سيظل اسمك والتعليمات المخصصة وأي رسائل تضيفها بعد المشاركة خاصة.',
  com_ui_share_updated_message:
    'تم تحديث رابط مشترك للدردشة الخاصة بك. يمكنك إدارة الدردشات المشتركة مسبقًا في أي وقت عبر الإعدادات.',
  com_ui_shared_link_not_found: 'الرابط المشترك غير موجود',
  com_ui_delete: 'حذف',
  com_ui_delete_conversation: 'حذف الدردشة؟',
  com_ui_delete_confirm: 'سيتم حذف هذا',
  com_ui_rename: 'إعادة تسمية',
  com_ui_archive: 'أرشفة',
  com_ui_archive_error: 'فشل في أرشفة المحادثة',
  com_ui_unarchive: 'إلغاء الأرشفة',
  com_ui_unarchive_error: 'فشل في إلغاء الأرشفة',
  com_ui_more_options: 'المزيد',
  com_ui_bookmarks: 'الإشارات المرجعية',
  com_ui_bookmarks_rebuild: 'إعادة بناء',
  com_ui_bookmarks_new: 'إشارة مرجعية جديدة',
  com_ui_bookmark_delete_confirm: 'هل أنت متأكد أنك تريد حذف هذه الإشارة المرجعية؟',
  com_ui_bookmarks_title: 'عنوان',
  com_ui_bookmarks_count: 'العدد',
  com_ui_bookmarks_description: 'وصف',
  com_ui_bookmarks_create_success: 'تم إنشاء الإشارة المرجعية بنجاح',
  com_ui_bookmarks_update_success: 'تم تحديث الإشارة المرجعية بنجاح',
  com_ui_bookmarks_delete_success: 'تم حذف الإشارة المرجعية بنجاح',
  com_ui_bookmarks_create_error: 'حدث خطأ أثناء إنشاء الإشارة المرجعية',
  com_ui_bookmarks_update_error: 'حدث خطأ أثناء تحديث الإشارة المرجعية',
  com_ui_bookmarks_delete_error: 'حدث خطأ أثناء حذف الإشارة المرجعية',
  com_ui_bookmarks_add_to_conversation: 'أضف إلى المحادثة الحالية',
  com_auth_error_login:
    'تعذر تسجيل الدخول باستخدام المعلومات المقدمة. يرجى التحقق من بيانات الاعتماد الخاصة بك والمحاولة مرة أخرى.',
  com_auth_error_login_rl:
    'محاولات تسجيل الدخول الكثيرة في فترة زمنية قصيرة. يرجى المحاولة مرة أخرى لاحقًا.',
  com_auth_error_login_ban: 'تم حظر حسابك مؤقتًا بسبب انتهاكات لخدمتنا.',
  com_auth_error_login_server:
    'كان هناك خطأ في الخادم الداخلي. يرجى الانتظار بضع لحظات وحاول مرة أخرى.',
  com_auth_no_account: 'ليس لديك حساب؟',
  com_auth_sign_up: 'سجل الان',
  com_auth_sign_in: 'تسجيل الدخول',
  com_auth_google_login: 'تسجيل الدخول بواسطة Google',
  com_auth_facebook_login: 'تسجيل الدخول بواسطة Facebook',
  com_auth_github_login: 'تسجيل الدخول بواسطة Github',
  com_auth_discord_login: 'تسجيل الدخول بواسطة Discord',
  com_auth_email: 'البريد الإلكتروني',
  com_auth_email_required: 'البريد الإلكتروني مطلوب',
  com_auth_email_min_length: 'يجب أن يكون البريد الإلكتروني على الأقل 6 أحرف',
  com_auth_email_max_length: 'يجب ألا يزيد البريد الإلكتروني عن 120 حرفًا',
  com_auth_email_pattern: 'يجب أن تدخل عنوان بريد إلكتروني صالح',
  com_auth_email_address: 'عنوان البريد الإلكتروني',
  com_auth_password: 'كلمة المرور',
  com_auth_password_required: 'كلمة المرور مطلوبة',
  com_auth_password_min_length: 'يجب أن تكون كلمة المرور على الأقل 8 أحرف',
  com_auth_password_max_length: 'يجب أن تكون كلمة المرور أقل من 128 حرفًا',
  com_auth_password_forgot: 'نسيت كلمة المرور؟',
  com_auth_password_confirm: 'تأكيد كلمة المرور',
  com_auth_password_not_match: 'كلمات المرور لا تتطابق',
  com_auth_continue: 'استمر',
  com_auth_create_account: 'أنشئ حسابك',
  com_auth_error_create: 'كان هناك خطأ في محاولة تسجيل حسابك. يرجى المحاولة مرة أخرى.',
  com_auth_full_name: 'الاسم الكامل',
  com_auth_name_required: 'الاسم مطلوب',
  com_auth_name_min_length: 'يجب أن يكون الاسم على الأقل 3 أحرف',
  com_auth_name_max_length: 'يجب أن يكون الاسم أقل من 80 حرفًا',
  com_auth_username: 'اسم المستخدم (اختياري)',
  com_auth_username_required: 'اسم المستخدم مطلوب',
  com_auth_username_min_length: 'يجب أن يكون اسم المستخدم على الأقل 2 أحرف',
  com_auth_username_max_length: 'يجب أن يكون اسم المستخدم أقل من 20 حرفًا',
  com_auth_already_have_account: 'هل لديك حساب بالفعل؟',
  com_auth_login: 'تسجيل الدخول',
  com_auth_reset_password: 'إعادة تعيين كلمة المرور',
  com_auth_click: 'انقر',
  com_auth_here: 'هنا',
  com_auth_to_reset_your_password: 'لإعادة تعيين كلمة المرور الخاصة بك.',
  com_auth_reset_password_link_sent: 'تم إرسال البريد الإلكتروني',
  com_auth_reset_password_email_sent:
    'تم إرسال بريد إلكتروني إليك مع مزيد من التعليمات لإعادة تعيين كلمة المرور الخاصة بك.',
  com_auth_error_reset_password:
    'كانت هناك مشكلة في إعادة تعيين كلمة المرور الخاصة بك. لم يتم العثور على مستخدم بالعنوان البريدي الذي تم تقديمه. يرجى المحاولة مرة أخرى.',
  com_auth_reset_password_success: 'نجاح إعادة تعيين كلمة المرور',
  com_auth_login_with_new_password:
    'يمكنك الآن تسجيل الدخول باستخدام كلمة المرور الجديدة الخاصة بك.',
  com_auth_error_invalid_reset_token: 'رمز إعادة تعيين كلمة المرور هذا لم يعد صالحًا.',
  com_auth_click_here: 'انقر هنا',
  com_auth_to_try_again: 'للمحاولة مرة أخرى.',
  com_auth_submit_registration: 'إرسال التسجيل',
  com_auth_welcome_back: 'مرحبا بك مرة أخرى',
  com_endpoint_open_menu: 'افتح القائمة',
  com_endpoint_bing_enable_sydney: 'تمكين سيدني',
  com_endpoint_bing_to_enable_sydney: 'لتمكين سيدني',
  com_endpoint_bing_jailbreak: 'الهروب من السجن',
  com_endpoint_bing_context_placeholder:
    'يمكن أن يستخدم بينغ ما يصل إلى 7 آلاف رمز لـ \'السياق\'، والذي يمكنه الرجوع إليه للمحادثة. الحد الخاص ليس معروفًا ولكن قد يواجه أخطاء تجاوز 7 آلاف رمز',
  com_endpoint_bing_system_message_placeholder:
    'تحذير: يمكن أن يؤدي سوء استخدام هذه الميزة إلى حظرك من استخدام Bing! انقر على \'رسالة النظام\' للحصول على التعليمات الكاملة والرسالة الافتراضية إذا تم حذفها، والتي هي الإعداد المسبق \'سيدني\' الذي يعتبر آمنًا.',
  com_endpoint_system_message: 'رسالة النظام',
  com_endpoint_default_blank: 'الافتراضي: فارغ',
  com_endpoint_default_false: 'الافتراضي: خاطئ',
  com_endpoint_default_creative: 'الافتراضي: إبداعي',
  com_endpoint_default_empty: 'الافتراضي: فارغ',
  com_endpoint_default_with_num: 'الافتراضي: {0}',
  com_endpoint_context: 'السياق',
  com_endpoint_tone_style: 'نمط النبرة',
  com_endpoint_token_count: 'عدد الرموز',
  com_endpoint_output: 'الإخراج',
  com_endpoint_google_temp:
    'القيم الأعلى = أكثر عشوائية، بينما القيم الأقل = أكثر تركيزًا وحتمية. نوصي بتغيير هذا أو Top P ولكن ليس كلاهما.',
  com_endpoint_google_topp:
    'Top-p يغير كيفية اختيار النموذج للرموز للإخراج. يتم اختيار الرموز من الأكثر K (انظر معلمة topK) احتمالًا إلى   الأقل حتى يصبح مجموع احتمالاتهم يساوي قيمة top-p.',
  com_endpoint_google_topk:
    'Top-k يغير كيفية اختيار النموذج للرموز للإخراج. top-k من 1 يعني أن الرمز المحدد هو الأكثر احتمالية بين جميع   الرموز في مفردات النموذج (يسمى أيضًا الترميز الجشعي)، بينما top-k من 3 يعني أن الرمز التالي يتم اختياره من بين الرموز الثلاثة الأكثر احتمالية (باستخدام الحرارة).',
  com_endpoint_google_maxoutputtokens:
    'الحد الأقصى لعدد الرموز التي يمكن إنشاؤها في الرد. حدد قيمة أقل للردود الأقصر وقيمة أعلى للردود الأطول.',
  com_endpoint_google_custom_name_placeholder: 'قم بتعيين اسم مخصص لـ Google',
  com_endpoint_prompt_prefix_placeholder:
    'قم بتعيين تعليمات مخصصة أو سياق. يتم تجاهله إذا كان فارغًا.',
  com_endpoint_custom_name: 'اسم مخصص',
  com_endpoint_prompt_prefix: 'بادئة الأمر',
  com_endpoint_temperature: 'درجة الحرارة',
  com_endpoint_default: 'الافتراضي',
  com_endpoint_top_p: 'أعلى P',
  com_endpoint_top_k: 'أعلى K',
  com_endpoint_max_output_tokens: 'الحد الأقصى لعدد الرموز المنتجة',
  com_endpoint_openai_temp:
    'القيم الأعلى = أكثر عشوائية ، بينما القيم الأقل = أكثر تركيزًا وتحديدًا. نوصي بتغيير هذا أو Top P ولكن ليس كلاهما.',
  com_endpoint_openai_max:
    'الحد الأقصى للرموز لتوليد. إجمالي طول الرموز المدخلة والرموز المولدة محدود بطول سياق النموذج.',
  com_endpoint_openai_topp:
    'بديل للعينة مع درجة الحرارة، يسمى العينة النووية، حيث ينظر النموذج في نتائج الرموز مع كتلة احتمال top_p. لذا 0.1 يعني أن الرموز التي تشكل فقط 10% من كتلة الاحتمال تعتبر. نوصي بتغيير هذا أو درجة الحرارة ولكن ليس كلاهما.',
  com_endpoint_openai_freq:
    'رقم بين -2.0 و 2.0. القيم الموجبة تعاقب الرموز الجديدة بناءً على تكرارها الحالي في النص حتى الآن، مما يقلل من احتمالية تكرار النموذج لنفس السطر حرفيًا.',
  com_endpoint_openai_pres:
    'رقم بين -2.0 و 2.0. القيم الموجبة تعاقب الرموز الجديدة بناءً على ما إذا كانت تظهر في النص حتى الآن، مما يزيد احتمالية النموذج للحديث عن مواضيع جديدة.',
  com_endpoint_openai_custom_name_placeholder: 'قم بتعيين اسم مخصص لـ ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'قم بتعيين تعليمات مخصصة لتضمينها في رسالة النظام. الافتراضي: لا شيء',
  com_endpoint_anthropic_temp:
    'المدى من 0 إلى 1. استخدم درجة الحرارة الأقرب إلى 0 للمهام التحليلية / اختيارية متعددة، وأقرب إلى 1 للمهام الإبداعية والإنشائية. نوصي بتغيير هذا أو Top P ولكن ليس كلاهما.',
  com_endpoint_anthropic_topp:
    'Top-p يغير الطريقة التي يختار فيها النموذج الرموز للإخراج. يتم اختيار الرموز من الأكثر احتمالية k (انظر المعلمة topK) إلى الأقل حتى يساوي مجموع احتمالاتهم قيمة top-p.',
  com_endpoint_anthropic_topk:
    'يغير top-k الطريقة التي يختار فيها النموذج الرموز للإخراج. يعني top-k 1 أن الرمز المحدد هو الأكثر احتمالية من بين جميع الرموز في المفردات النموذجية (يسمى أيضا الترميز الطمع)، بينما يعني top-k من 3 أن الرمز القادم محدد من بين الرموز الثلاثة الأكثر احتمالية (باستخدام درجة الحرارة).',
  com_endpoint_anthropic_maxoutputtokens:
    'العدد الأقصى من الرموز التي يمكن إنشاؤها في الرد. حدد قيمة أقل للحصول على ردود أقصر وقيمة أعلى للحصول على ردود أطول.',
  com_endpoint_anthropic_custom_name_placeholder: 'قم بتعيين اسم مخصص لـ Anthropic',
  com_endpoint_frequency_penalty: 'عقوبة التكرار',
  com_endpoint_presence_penalty: 'عقوبة الوجود',
  com_endpoint_plug_use_functions: 'استخدام الوظائف',
  com_endpoint_plug_skip_completion: 'تجاوز الإكمال',
  com_endpoint_disabled_with_tools: 'معطل بواسطة الأدوات',
  com_endpoint_disabled_with_tools_placeholder: 'تم التعطيل باختيار الأدوات',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'قم بتعيين تعليمات مخصصة لتضمينها في رسالة النظام. الافتراضي: لا شيء',
  com_endpoint_import: 'استيراد',
  com_endpoint_set_custom_name: 'قم بتعيين اسم مخصص، في حالة إمكانية العثور على هذا الإعداد المسبق',
  com_endpoint_preset: 'إعداد مسبق',
  com_endpoint_presets: 'إعدادات مسبقة',
  com_endpoint_preset_name: 'اسم الإعداد المسبق',
  com_endpoint_new_topic: 'موضوع جديد',
  com_endpoint: 'نقطة النهاية',
  com_endpoint_hide: 'إخفاء',
  com_endpoint_show: 'إظهار',
  com_endpoint_examples: 'الإعدادات المسبقة ',
  com_endpoint_completion: 'إكمال',
  com_endpoint_agent: 'الوكيل',
  com_endpoint_show_what_settings: 'إظهار {0} الإعدادات',
  com_endpoint_save: 'حفظ',
  com_endpoint_export: 'تصدير',
  com_endpoint_save_as_preset: 'حفظ كإعداد مسبق',
  com_endpoint_presets_clear_warning:
    'هل أنت متأكد أنك تريد مسح جميع الإعدادات المسبقة؟ هذا لا يمكن التراجع عنه.',
  com_endpoint_not_implemented: 'لم يتم تنفيذه',
  com_endpoint_no_presets: 'لا يوجد إعداد مسبق بعد',
  com_endpoint_not_available: 'لا توجد نقطة نهاية متاحة',
  com_endpoint_view_options: 'عرض الخيارات',
  com_endpoint_save_convo_as_preset: 'حفظ المحادثة كإعداد مسبق',
  com_endpoint_my_preset: 'الإعداد المسبق الخاص بي',
  com_endpoint_agent_model: 'نموذج الوكيل (موصى به: GPT-3.5)',
  com_endpoint_completion_model: 'نموذج الإكمال (موصى به: GPT-4)',
  com_endpoint_func_hover: 'تمكين استخدام الإضافات كوظائف OpenAI',
  com_endpoint_skip_hover:
    'تمكين تجاوز خطوة الإكمال التي تقوم بمراجعة الإجابة النهائية والخطوات المولدة',
  com_endpoint_config_key: 'تعيين مفتاح API',
  com_endpoint_config_key_for: 'ضع API Key لـ',
  com_endpoint_config_key_name: 'مفتـاح',
  com_endpoint_config_value: 'أدخل القيمة لـ',
  com_endpoint_config_key_name_placeholder: 'قم بتعيين مفتاح API أولاً',
  com_endpoint_config_key_encryption: 'سيتم تشفير مفتاحك وحذفه في',
  com_endpoint_config_key_expiry: 'وقت الانتهاء',
  com_endpoint_config_key_import_json_key: 'استيراد مفتاح حساب الخدمة JSON.',
  com_endpoint_config_key_import_json_key_success: 'تم استيراد مفتاح حساب الخدمة JSON بنجاح',
  com_endpoint_config_key_import_json_key_invalid:
    'مفتاح حساب الخدمة JSON غير صالح، هل قمت باستيراد الملف الصحيح؟',
  com_endpoint_config_key_get_edge_key:
    'للحصول على رمز الوصول الخاص بك لـ Bing، قم بتسجيل الدخول إلى',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'استخدم أدوات المطور أو ملحق أثناء تسجيل الدخول إلى الموقع لنسخ محتوى ألـ_U cookie. إذا فشل هذا، اتبع هذه',
  com_endpoint_config_key_edge_instructions: 'التعليمات',
  com_endpoint_config_key_edge_full_key_string: ' لتقديم سلاسل كاملة للكوكيز.',
  com_endpoint_config_key_chatgpt:
    'للحصول على رمز الوصول الخاص بـ ChatGPT إصدار مجاني، قم بتسجيل الدخول إلى',
  com_endpoint_config_key_chatgpt_then_visit: 'ثم قم بزيارة',
  com_endpoint_config_key_chatgpt_copy_token: 'نسخ رمز الوصول.',
  com_endpoint_config_key_google_need_to: 'أنت بحاجة إلى',
  com_endpoint_config_key_google_vertex_ai: 'تمكين Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API على Google Cloud، ثم',
  com_endpoint_config_key_google_service_account: 'أنشئ حساب خدمة',
  com_endpoint_config_key_google_vertex_api_role:
    'تأكد من النقر على إنشاء ومتابعة" لمنح الدور "Vertex AI User" على الأقل. أخيرًا، قم بإنشاء مفتاح JSON للعمل على استيراده هنا.',
  com_nav_auto_scroll: 'التمرير التلقائي إلى أحدث عند الفتح',
  com_nav_plugin_store: 'متجر الإضافات',
  com_nav_plugin_search: 'ابحث عن الإضافات',
  com_nav_plugin_auth_error:
    'حدث خطأ أثناء محاولة المصادقة على هذا البرنامج المساعد. يرجى المحاولة مرة أخرى.',
  com_nav_export_filename: 'اسم الملف',
  com_nav_export_filename_placeholder: 'قم بتعيين اسم الملف',
  com_nav_export_type: 'النوع',
  com_nav_export_include_endpoint_options: 'تضمين خيارات النقاط النهائية',
  com_nav_enabled: 'تم التمكين',
  com_nav_not_supported: 'غير مدعوم',
  com_nav_export_all_message_branches: 'تصدير كل فروع الرسائل',
  com_nav_export_recursive_or_sequential: 'التراجع أو التسلسل؟',
  com_nav_export_recursive: 'تكراري',
  com_nav_export_conversation: 'تصدير المحادثة',
  com_nav_export: 'تصدير',
  com_nav_shared_links: 'روابط مشتركة',
  com_nav_shared_links_manage: 'الإدارة',
  com_nav_shared_links_empty: 'ليس لديك أي روابط مشتركة.',
  com_nav_shared_links_name: 'الاسم',
  com_nav_shared_links_date_shared: 'تاريخ المشترك',
  com_nav_theme: 'المظهر',
  com_nav_theme_system: 'النظام',
  com_nav_theme_dark: 'داكن',
  com_nav_theme_light: 'فاتح',
  com_nav_font_size: 'حجم الخط',
  com_nav_clear_all_chats: 'مسح كل الدردشات',
  com_nav_confirm_clear: 'تأكيد المسح',
  com_nav_close_sidebar: 'إغلاق القائمة الجانبية',
  com_nav_open_sidebar: 'افتح القائمة الجانبية',
  com_nav_log_out: 'تسجيل الخروج',
  com_nav_user: 'المستخدم',
  com_nav_archived_chats: 'الدردشات المؤرشفة',
  com_nav_archived_chats_manage: 'إدارة',
  com_nav_archived_chats_empty: 'ليس لديك أي دردشات مؤرشفة.',
  com_nav_archive_all_chats: 'الأرشفة على كل الدردشات',
  com_nav_archive_all: 'الأرشفة على كل الدردشات',
  com_nav_archive_name: 'الاسم',
  com_nav_archive_created_at: 'تاريخ الإنشاء',
  com_nav_clear_conversation: 'مسح المحادثات',
  com_nav_clear_conversation_confirm_message:
    'هل أنت متأكد أنك تريد مسح جميع المحادثات؟ هذا لا يمكن التراجع عنه.',
  com_nav_help_faq: 'مساعدة & الأسئلة الشائعة',
  com_nav_settings: 'الإعدادات',
  com_nav_search_placeholder: 'بحث في الرسائل',
  com_nav_info_bookmarks_rebuild:
    'إذا كان عدد الإشارات المرجعية غير صحيح، يرجى إعادة بناء معلومات الإشارة المرجعية. سيتم إعادة حساب عدد الإشارات المرجعية وستتم استعادة البيانات إلى حالتها الصحيحة.',
  com_nav_setting_general: 'عام',
  com_nav_setting_data: 'تحكم في البيانات',
  /* The following are AI translated */
  com_assistants_file_search: 'بحث الملفات',
  com_assistants_file_search_info:
    'لا يتم دعم إرفاق مخازن الكتل الرقمية لميزة البحث في الملفات بعد. يمكنك إرفاقها من ملعب المزود أو إرفاق ملفات إلى الرسائل للبحث في الملفات على أساس المحادثة.',
  com_assistants_non_retrieval_model:
    'البحث في الملفات غير مُمكّن على هذا النموذج. يرجى تحديد نموذج آخر.',
  com_ui_attach_error_openai: 'لا يمكن إرفاق ملفات المساعد إلى نقاط نهائية أخرى',
  com_ui_attach_warn_endpoint: 'قد يتم تجاهل الملفات غير المساعدة دون وجود أداة متوافقة',
  com_ui_assistant_deleted: 'تم حذف المساعد بنجاح',
  com_ui_assistant_delete_error: 'حدث خطأ أثناء حذف المساعد',
  com_ui_copied: 'تم النسخ',
  com_ui_copy_code: 'نسخ الكود',
  com_nav_source_chat: 'عرض محادثة المصدر',
  com_ui_date_today: 'اليوم',
  com_ui_date_yesterday: 'أمس',
  com_ui_date_previous_7_days: 'الأيام السبعة السابقة',
  com_ui_date_previous_30_days: 'الـ 30 يومًا السابقة',
  com_ui_date_january: 'يناير',
  com_ui_date_february: 'فبراير',
  com_ui_date_march: 'مارس',
  com_ui_date_april: 'أبريل',
  com_ui_date_may: 'مايو',
  com_ui_date_june: 'يونيو',
  com_ui_date_july: 'يوليو',
  com_ui_date_august: 'أغسطس',
  com_ui_date_september: 'سبتمبر',
  com_ui_date_october: 'أكتوبر',
  com_ui_date_november: 'نوفمبر',
  com_ui_date_december: 'ديسمبر',
  com_ui_nothing_found: 'لم يتم العثور على أي شيء',
  com_ui_go_to_conversation: 'انتقل إلى المحادثة',
  com_error_moderation:
    'يبدو أن المحتوى المقدم قد تم وضع علامة عليه من قبل نظام الرقابة لدينا لعدم توافقه مع إرشادات مجتمعنا. لا نستطيع المضي قدمًا في هذا الموضوع المحدد. إذا كانت لديك أسئلة أخرى أو مواضيع ترغب في استكشافها، يرجى تحرير رسالتك، أو إنشاء محادثة جديدة.',
  com_error_no_user_key: 'لم يتم العثور على مفتاح. يرجى تقديم مفتاح والمحاولة مرة أخرى.',
  com_error_no_base_url: 'لم يتم العثور على رابط أساسي. يرجى تقديم واحد والمحاولة مرة أخرى.',
  com_error_invalid_user_key: 'مفتاح غير صالح. يرجى تقديم مفتاح صالح والمحاولة مرة أخرى.',
  com_error_expired_user_key:
    'انتهت صلاحية المفتاح المقدم لـ {0} في {1}. يرجى تقديم مفتاح وحاول مرة أخرى.',
  com_files_no_results: 'لا توجد نتائج.',
  com_files_filter: 'فلترة الملفات...',
  com_files_number_selected: 'تم اختيار {0} من أصل {1} ملف(ملفات)',
  com_sidepanel_select_assistant: 'اختر مساعدًا',
  com_sidepanel_parameters: 'معلمات',
  com_sidepanel_assistant_builder: 'بانٍ المساعد',
  com_sidepanel_hide_panel: 'إخفاء اللوحة',
  com_sidepanel_attach_files: 'إرفاق الملفات',
  com_sidepanel_manage_files: 'إدارة الملفات',
  com_sidepanel_conversation_tags: 'الإشارات المرجعية',
  com_assistants_capabilities: 'قدرات',
  com_assistants_knowledge: 'المعرفة',
  com_assistants_completed_function: 'تم تشغيل {0}',
  com_assistants_knowledge_info:
    'إذا قمت بتحميل ملفات تحت معلومات، فقد تتضمن المحادثات مع المساعد الخاص بك محتويات الملف.',
  com_assistants_knowledge_disabled:
    'يجب إنشاء المساعد وتمكين المفسر البرمجي أو الاسترجاع وحفظهما قبل تحميل الملفات كمعرفة.',
  com_assistants_image_vision: 'رؤية الصورة',
  com_assistants_code_interpreter: 'مُفسِّر الشفرة',
  com_assistants_code_interpreter_files: 'الملفات التالية متاحة فقط لمفسر الشفرة:',
  com_assistants_retrieval: 'استرداد',
  com_assistants_search_name: 'البحث عن المساعدين بالاسم',
  com_ui_tools: 'أدوات المساعدين',
  com_assistants_actions: 'إجراءات',
  com_assistants_add_tools: 'إضافة أدوات',
  com_assistants_add_actions: 'إضافة إجراءات',
  com_assistants_available_actions: 'الإجراءات المتاحة',
  com_assistants_running_action: 'جارٍ تنفيذ الإجراء',
  com_assistants_completed_action: 'تحدث إلى {0}',
  com_assistants_function_use: 'المساعد استخدم {0}',
  com_assistants_domain_info: 'أرسل المساعد هذه المعلومات إلى {0}',
  com_assistants_delete_actions_success: 'تم حذف الإجراء من المساعد بنجاح',
  com_assistants_update_actions_success: 'تم إنشاء أو تحديث الإجراء بنجاح',
  com_assistants_update_actions_error: 'حدث خطأ أثناء إنشاء أو تحديث الإجراء.',
  com_assistants_delete_actions_error: 'حدث خطأ أثناء حذف الإجراء.',
  com_assistants_actions_info:
    'اسمح لمساعدك باسترداد المعلومات أو اتخاذ إجراءات عبر واجهات برمجة التطبيقات',
  com_assistants_name_placeholder: 'اختياري: اسم المساعد',
  com_assistants_instructions_placeholder: 'التعليمات النظامية التي يستخدمها المساعد',
  com_assistants_description_placeholder: 'اختياري: اشرح مساعدك هنا',
  com_assistants_actions_disabled: 'يجب عليك إنشاء مساعد قبل إضافة إجراءات.',
  com_assistants_update_success: 'تم التحديث بنجاح',
  com_assistants_update_error: 'حدث خطأ أثناء تحديث المساعد الافتراضي الخاص بك.',
  com_assistants_create_success: 'تم إنشاؤه بنجاح',
  com_assistants_create_error: 'حدث خطأ أثناء إنشاء المساعد الخاص بك.',
  com_ui_field_required: 'هذا الحقل مطلوب',
  com_ui_download_error: 'حدث خطأ أثناء تنزيل الملف. قد يكون الملف قد تم حذفه.',
  com_ui_attach_error_type: 'نوع ملف غير مدعوم للنقطة النهائية:',
  com_ui_attach_error_size: 'تم تجاوز حد حجم الملف للنقطة النهائية',
  com_ui_attach_error: 'تعذر إرفاق الملف. يرجى إنشاء أو تحديد محادثة، أو حاول تحديث الصفحة.',
  com_ui_experimental: 'ميزات تجريبية',
  com_ui_on: 'مفعل',
  com_ui_off: 'إيقاف',
  com_ui_yes: 'نعم',
  com_ui_no: 'لا',
  com_ui_ascending: 'تصاعدي',
  com_ui_descending: 'تنازلي',
  com_ui_show_all: 'عرض الكل',
  com_ui_name: 'اسم',
  com_ui_date: 'تاريخ',
  com_ui_storage: 'التخزين',
  com_ui_context: 'سياق',
  com_ui_size: 'الحجم',
  com_ui_host: 'مُضيف',
  com_ui_update: 'تحديث',
  com_ui_authentication: 'المصادقة',
  com_ui_instructions: 'تعليمات',
  com_ui_description: 'وصف',
  com_ui_error: 'خطأ',
  com_ui_select: 'اختر',
  com_ui_select_search_model: 'ابحث عن نموذج باسمه',
  com_ui_select_search_plugin: 'إضافة البحث عن الإضافات حسب الاسم',
  com_ui_stop: 'توقف',
  com_ui_upload_files: 'تحميل الملفات',
  com_ui_new_footer: 'جميع محادثات الذكاء الاصطناعي في مكان واحد.',
  com_ui_none_selected: 'لم يتم اختيار أي شيء',
  com_ui_upload_error: 'حدث خطأ أثناء تحميل ملفك',
  com_ui_save_submit: 'حفظ وإرسال',
  com_user_message: 'أنت',
  com_ui_fork: 'تفرع',
  com_ui_fork_info_1: 'استخدم هذا الإعداد لتفريع الرسائل بالسلوك المرغوب.',
  com_ui_fork_info_2:
    '"التفريع" يشير إلى إنشاء محادثة جديدة تبدأ/تنتهي من رسائل محددة في المحادثة الحالية، وإنشاء نسخة وفقًا للخيارات المحددة.',
  com_ui_fork_info_3:
    '"الرسالة المستهدفة" تشير إما إلى الرسالة التي تم فتح هذه النافذة المنبثقة منها، أو إذا قمت بتحديد "{0}"، آخر رسالة في المحادثة.',
  com_ui_fork_info_visible:
    'هذا الخيار يقوم بتفريع الرسائل المرئية فقط؛ بمعنى آخر، المسار المباشر إلى الرسالة المستهدفة، دون أي فروع.',
  com_ui_fork_info_branches:
    'هذا الخيار يقسم الرسائل المرئية، جنبًا إلى جنب مع الفروع ذات الصلة؛ بمعنى آخر، المسار المباشر إلى الرسالة المستهدفة، بما في ذلك الفروع على طول المسار.',
  com_ui_fork_info_target:
    'هذا الخيار يؤدي إلى تفريع جميع الرسائل التي تؤدي إلى الرسالة المستهدفة، بما في ذلك جيرانها؛ بعبارة أخرى، يتم تضمين جميع فروع الرسائل، سواء كانت مرئية أم لا أو على نفس المسار.',
  com_ui_fork_info_start:
    'إذا تم تحديده، فسيبدأ التفريع من هذه الرسالة إلى آخر رسالة في المحادثة، وفقًا للسلوك المحدد أعلاه.',
  com_ui_fork_info_remember:
    'حدد هذا الخيار لتذكر الإعدادات التي اخترتها لاستخدامها مستقبلاً، مما يجعل عملية تفريع المحادثات أسرع وفقًا لتفضيلاتك.',
  com_ui_fork_success: 'تم تفريع المحادثة بنجاح',
  com_ui_fork_processing: 'تجزئة المحادثة...',
  com_ui_fork_error: 'حدث خطأ أثناء تفريع المحادثة',
  com_ui_fork_change_default: 'خيار التفرع الافتراضي',
  com_ui_fork_default: 'استخدم خيار التفريع الافتراضي',
  com_ui_fork_remember: 'تذكر',
  com_ui_fork_split_target_setting: 'ابدأ التفرع من رسالة الهدف افتراضيًا',
  com_ui_fork_split_target: 'ابدأ التفرع هنا',
  com_ui_fork_remember_checked:
    'سيتم تذكر اختيارك بعد الاستخدام. يمكنك تغيير هذا في أي وقت من إعدادات البرنامج.',
  com_ui_fork_all_target: 'تضمين الكل إلى/من هنا',
  com_ui_fork_branches: 'تضمين الفروع ذات الصلة',
  com_ui_fork_visible: 'الرسائل المرئية فقط',
  com_ui_fork_from_message: 'اختر خيار التفرع',
  com_ui_mention: 'اذكر نقطة نهاية أو مساعدًا أو إعدادًا مسبقًا للتبديل إليه بسرعة',
  com_ui_import_conversation_file_type_error: 'نوع الملف غير مدعوم للاستيراد',
  com_ui_avatar: 'الصورة الرمزية',
  com_ui_unknown: 'غير معروف',
  com_ui_result: 'النتيجة',
  com_ui_image_gen: 'توليد الصور',
  com_ui_assistant: 'المساعد',
  com_ui_assistants: 'المساعدون',
  com_ui_attachment: 'مرفق',
  com_ui_assistants_output: 'إخراج المساعدين',
  com_ui_create: 'إنشاء',
  com_ui_delete_assistant_confirm:
    'هل أنت متأكد من رغبتك في حذف هذا المساعد؟ لا يمكن التراجع عن هذا الإجراء.',
  com_ui_preview: 'معاينة',
  com_ui_upload: 'تحميل',
  com_ui_connect: 'اتصال',
  com_ui_upload_delay:
    'تحميل "{0}" يستغرق وقتًا أطول من المتوقع. يرجى الانتظار حتى ينتهي فهرسة الملف للاسترجاع.',
  com_ui_privacy_policy: 'سياسة الخصوصية',
  com_ui_terms_of_service: 'شروط الخدمة',
  com_ui_min_tags: 'لا يمكن إزالة المزيد من القيم، الحد الأدنى المطلوب هو {0}.',
  com_ui_max_tags: 'الحد الأقصى المسموح به هو {0}، باستخدام أحدث القيم.',
  com_ui_accept: 'أوافق',
  com_ui_decline: 'لا أوافق',
  com_ui_terms_and_conditions: 'شروط الخدمة',
  com_ui_no_terms_content: 'لا يوجد محتوى لشروط الخدمة',
  com_auth_back_to_login: 'العودة إلى تسجيل الدخول',
  com_endpoint_message: 'رسالة',
  com_endpoint_message_not_appendable: 'عدّل رسالتك أو أعد إنشاءها.',
  com_endpoint_context_tokens: 'الحد الأقصى لرموز السياق',
  com_endpoint_context_info:
    'الحد الأقصى لعدد الرموز التي يمكن استخدامها للسياق. استخدم هذا للتحكم في عدد الرموز المرسلة لكل طلب. إذا لم يتم تحديده، سيتم استخدام الإعدادات الافتراضية للنظام بناءً على حجم سياق نماذج معروفة. قد يؤدي تعيين قيم أعلى إلى حدوث أخطاء و/أو تكلفة رموز أعلى.',
  com_endpoint_instructions_assistants_placeholder:
    'يتجاوز التعليمات الخاصة بالمساعد. هذا مفيد لتعديل السلوك على أساس كل مرة.',
  com_endpoint_prompt_prefix_assistants_placeholder:
    'ضع تعليمات أو سياق إضافي فوق التعليمات الرئيسية للمساعد. يتم تجاهله إذا كان فارغًا.',
  com_endpoint_prompt_prefix_assistants: 'التعليمات الإضافية',
  com_endpoint_instructions_assistants: 'تعليمات التجاوز',
  com_endpoint_stop: 'توقف التسلسلات',
  com_endpoint_stop_placeholder: 'اضغط على \'Enter\' لفصل القيم',
  com_endpoint_openai_max_tokens:
    'حقل `max_tokens` الاختياري، يمثل الحد الأقصى لعدد الرموز التي يمكن توليدها في إكمال المحادثة.\n\nإجمالي طول رموز الإدخال والرموز المولدة محدود بطول سياق النموذج. قد تواجه أخطاء إذا تجاوز هذا العدد الحد الأقصى لرموز السياق.',
  com_endpoint_openai_resend:
    'إعادة إرسال جميع الصور المرفقة مسبقًا. ملاحظة: قد يؤدي هذا إلى زيادة كبيرة في تكلفة الرموز وقد تواجه أخطاء مع العديد من مرفقات الصور.',
  com_endpoint_openai_resend_files:
    'إعادة إرسال جميع الملفات المرفقة مسبقًا. ملاحظة: سيؤدي هذا إلى زيادة تكلفة الرموز وقد تواجه أخطاء مع العديد من المرفقات.',
  com_endpoint_openai_detail:
    'دقة الطلبات للرؤية. "منخفضة" أرخص وأسرع، "عالية" أكثر تفصيلاً وتكلفة، و"تلقائي" سيختار تلقائيًا بين الاثنين بناءً على دقة الصورة.',
  com_endpoint_openai_stop: 'حتى 4 تسلسلات حيث ستتوقف الواجهة البرمجية عن توليد المزيد من الرموز.',
  com_endpoint_plug_resend_files: 'إعادة إرسال الملفات',
  com_endpoint_plug_resend_images: 'إعادة إرسال الصور',
  com_endpoint_plug_image_detail: 'تفاصيل الصورة',
  com_endpoint_preset_delete_confirm: 'هل أنت متأكد من أنك تريد حذف هذا الإعداد المسبق؟',
  com_endpoint_preset_clear_all_confirm: 'هل أنت متأكد أنك تريد حذف جميع إعداداتك المسبقة؟',
  com_endpoint_preset_import: 'تم استيراد الإعداد المسبق!',
  com_endpoint_preset_import_error:
    'حدث خطأ أثناء استيراد الإعداد المسبق الخاص بك. يرجى المحاولة مرة أخرى.',
  com_endpoint_preset_save_error:
    'حدث خطأ أثناء حفظ الإعداد المسبق الخاص بك. يرجى المحاولة مرة أخرى.',
  com_endpoint_preset_delete_error:
    'حدث خطأ أثناء حذف الإعداد المسبق الخاص بك. يرجى المحاولة مرة أخرى.',
  com_endpoint_preset_default_removed: 'لم يعد الإعداد المسبق الافتراضي',
  com_endpoint_preset_default_item: 'الافتراضي:',
  com_endpoint_preset_default_none: 'لا يوجد إعداد مسبق افتراضي نشط.',
  com_endpoint_preset_title: 'إعداد مسبق',
  com_endpoint_preset_saved: 'تم الحفظ!',
  com_endpoint_preset_default: 'أصبح الإعداد المسبق الافتراضي الآن.',
  com_endpoint_preset_selected: 'الإعداد المسبق نشط!',
  com_endpoint_preset_selected_title: 'مُحدَّد!',
  com_endpoint_assistant: 'المساعد',
  com_endpoint_use_active_assistant: 'استخدام المساعد النشط',
  com_endpoint_assistant_model: 'نموذج المساعد',
  com_endpoint_assistant_placeholder: 'يرجى تحديد مساعد من اللوحة الجانبية اليمنى',
  com_endpoint_config_placeholder: 'اضبط مفتاحك في قائمة الرأس للدردشة.',
  com_endpoint_config_click_here: 'انقر هنا',
  com_endpoint_config_google_service_key: 'مفتاح حساب خدمة Google',
  com_endpoint_config_google_cloud_platform: 'تكوين نقطة نهاية Google Cloud Platform',
  com_endpoint_config_google_api_key: 'مفتاح Google API',
  com_endpoint_config_google_gemini_api: 'تكوين نقطة نهاية Gemini API',
  com_endpoint_config_google_api_info:
    'للحصول على مفتاح Generative Language API الخاص بك (لـ Gemini)،',
  com_nav_welcome_assistant: 'يرجى اختيار مساعد',
  com_nav_welcome_message: 'كيف يمكنني مساعدتك اليوم؟',
  com_nav_hide_panel: 'إخفاء اللوحة الجانبية اليمنى',
  com_nav_modular_chat: 'تمكين تبديل النقاط النهائية أثناء المحادثة',
  com_nav_latex_parsing: 'تحليل LaTeX في الرسائل (قد يؤثر على الأداء)',
  com_nav_profile_picture: 'صورة الملف الشخصي',
  com_nav_change_picture: 'تغيير الصورة',
  com_nav_plugin_install: 'تثبيت',
  com_nav_plugin_uninstall: 'إلغاء تثبيت',
  com_ui_add: 'إضافة',
  com_nav_tool_remove: 'إزالة',
  com_nav_tool_dialog: 'أدوات المساعد',
  com_nav_tool_dialog_description: 'يجب حفظ المساعد لإبقاء اختيارات الأدوات.',
  com_show_agent_settings: 'إظهار إعدادات الوكيل',
  com_show_completion_settings: 'إظهار إعدادات الإكمال',
  com_hide_examples: 'إخفاء الأمثلة',
  com_show_examples: 'عرض أمثلة',
  com_nav_tool_search: 'أدوات البحث',
  com_nav_my_files: 'ملفاتي',
  com_nav_enter_to_send: 'اضغط على مفتاح الإدخال لإرسال الرسائل',
  com_nav_user_name_display: 'عرض اسم المستخدم في الرسائل',
  com_nav_save_drafts: 'حفظ المستخدمين',
  com_nav_show_code: 'إظهار الشفرة دائمًا عند استخدام مفسر الشفرة',
  com_nav_send_message: 'إرسال رسالة',
  com_nav_setting_beta: 'ميزات تجريبية',
  com_nav_setting_account: 'الحساب',
  com_nav_language: 'اللغة',
  com_nav_lang_auto: 'اكتشاف تلقائي',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_polish: 'Polski',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
  com_nav_lang_russian: 'Русский',
  com_nav_lang_japanese: '日本語',
  com_nav_lang_swedish: 'Svenska',
  com_nav_lang_korean: '한국어',
  com_nav_lang_vietnamese: 'Tiếng Việt',
  com_nav_lang_traditionalchinese: '繁體中文',
  com_nav_lang_arabic: 'العربية',
  com_nav_lang_turkish: 'Türkçe',
  com_nav_lang_dutch: 'Nederlands',
  com_nav_lang_indonesia: 'Indonesia',
  com_nav_lang_hebrew: 'עברית',
  com_nav_lang_finnish: 'Suomi',
  com_nav_convo_menu_options: 'خيارات قائمة المحادثة',
  com_ui_artifacts: 'المخرجات',
  com_ui_artifacts_toggle: 'تبديل واجهة العناصر',
  com_ui_custom_prompt_mode: 'وضع الأمر المخصص',
  com_nav_info_code_artifacts: 'تمكين عرض عناصر الكود التجريبية بجانب المحادثة',
  com_ui_include_shadcnui: 'تضمين تعليمات مكونات shadcn/ui',
  com_ui_artifact_click: 'انقر للفتح',
  com_nav_info_include_shadcnui:
    'عند التفعيل، سيتم تضمين تعليمات استخدام مكونات shadcn/ui. تعد shadcn/ui مجموعة من المكونات القابلة لإعادة الاستخدام والمبنية باستخدام Radix UI و Tailwind CSS. ملاحظة: هذه التعليمات مطولة، يجب تفعيلها فقط إذا كان إخبار نموذج اللغة الآلي (LLM) بالاستيرادات والمكونات الصحيحة مهمًا بالنسبة لك. لمزيد من المعلومات حول هذه المكونات، قم بزيارة: https://ui.shadcn.com/',
  com_nav_info_custom_prompt_mode:
    'عند التمكين، لن يتم تضمين النص التلقائي للنظام. يجب توفير جميع تعليمات إنشاء العناصر يدويًا في هذا الوضع.',
  com_a11y_ai_composing: 'الذكاء الاصطناعي ما زال يكتب',
  com_a11y_start: 'بدأ الذكاء الاصطناعي بالرد',
  com_warning_resubmit_unsupported:
    'إعادة إرسال رسالة الذكاء الاصطناعي غير مدعومة لنقطة النهاية هذه',
  com_a11y_end: 'انتهى الذكاء الاصطناعي من الرد',
  com_error_invalid_request_error:
    'رفضت خدمة الذكاء الاصطناعي الطلب بسبب خطأ. قد يكون هذا بسبب مفتاح API غير صالح أو طلب غير منسق بشكل صحيح.',
  com_error_files_empty: 'الملفات الفارغة غير مسموح بها',
  com_error_files_dupe: 'تم اكتشاف ملف مكرر.',
  com_error_input_length:
    'عدد الرموز في آخر رسالة طويل جداً، ويتجاوز الحد المسموح به ({0}). يرجى تقصير رسالتك، أو تعديل الحد الأقصى للسياق من معلمات المحادثة، أو تفريع المحادثة للمتابعة.',
  com_error_no_system_messages:
    'خدمة الذكاء الاصطناعي أو النموذج المحدد لا يدعم رسائل النظام. حاول استخدام التوجيهات المباشرة بدلاً من التعليمات المخصصة.',
  com_error_files_process: 'حدث خطأ أثناء معالجة الملف.',
  com_error_files_upload: 'حدث خطأ أثناء رفع الملف.',
  com_error_files_validation: 'حدث خطأ أثناء التحقق من صحة الملف.',
  com_error_files_upload_canceled:
    'تم إلغاء طلب تحميل الملف. ملاحظة: قد تكون عملية تحميل الملف لا تزال قيد المعالجة وستحتاج إلى حذفها يدويًا.',
  com_generated_files: 'الملفات المُنشأة:',
  com_download_expires: 'انقر هنا للتنزيل - تنتهي الصلاحية في {0}',
  com_download_expired: 'انتهت صلاحية التنزيل',
  com_click_to_download: '(انقر هنا للتنزيل)',
  com_assistants_conversation_starters_placeholder: 'أدخل بداية المحادثة',
  com_assistants_conversation_starters: 'بدء المحادثات',
  com_assistants_code_interpreter_info:
    'يُمكّن مُفسِّر الشفرة المساعد من كتابة وتشغيل الشفرة البرمجية. يمكن لهذه الأداة معالجة الملفات ذات البيانات والتنسيقات المتنوعة، وإنشاء ملفات مثل الرسوم البيانية',
  com_sidepanel_agent_builder: 'بانٍ المساعد',
  com_agents_name_placeholder: 'اختياري: اسم العميل',
  com_agents_description_placeholder: 'اختياري: اشرح عميلك هنا',
  com_agents_instructions_placeholder: 'التعليمات النظامية التي يستخدمها الوكيل',
  com_agents_search_name: 'البحث عن الوكلاء بالاسم',
  com_agents_create_error: 'حدث خطأ أثناء إنشاء الوكيل الخاص بك',
  com_agents_update_error: 'حدث خطأ أثناء تحديث الوكيل الخاص بك.',
  com_agents_allow_editing: 'السماح للمستخدمين الآخرين بتعديل الوكيل الخاص بك',
  com_agents_enable_file_search: 'تمكين البحث عن الملفات',
  com_agents_not_available: 'المساعد غير متوفر',
  com_agents_missing_provider_model: 'يرجى تحديد المزود والنموذج قبل إنشاء الوكيل',
  com_agents_no_access: 'ليس لديك صلاحية تعديل هذا الوكيل.',
  com_agents_file_search_info:
    'عند التمكين، سيتم إعلام الوكيل بأسماء الملفات المدرجة أدناه بالضبط، مما يتيح له استرجاع السياق ذي الصلة من هذه الملفات.',
  com_ui_agent_already_shared_to_all: 'هذا المساعد مشارك بالفعل مع جميع المستخدمين',
  com_agents_execute_code: 'تنفيذ الشفرة',
  com_ui_no_changes: 'لا توجد تغييرات للتحديث',
  com_ui_agent_editing_allowed: 'يمكن للمستخدمين الآخرين تعديل هذا الوكيل بالفعل',
  com_ui_error_connection: 'خطأ في الاتصال بالخادم، حاول تحديث الصفحة.',
  com_ui_endpoint: 'نقطة النهاية',
  com_ui_provider: 'مزود',
  com_ui_region: 'المنطقة',
  com_ui_model_parameters: 'معلمات النموذج',
  com_agents_file_search_disabled: 'يجب إنشاء الوكيل قبل تحميل الملفات للبحث في الملفات.',
  com_ui_model_save_success: 'تم حفظ معلمات النموذج بنجاح',
  com_ui_select_provider: 'اختر مزودًا',
  com_ui_select_region: 'اختر المنطقة',
  com_ui_select_search_region: 'ابحث عن المنطقة بالاسم',
  com_ui_select_provider_first: 'اختر مزود الخدمة أولاً',
  com_ui_prompt: 'موجه',
  com_ui_prompts: 'الأوامر',
  com_ui_prompt_name: 'اسم الأمر',
  com_ui_admin: 'مشرف',
  com_ui_delete_prompt: 'حذف المطالبة؟',
  com_ui_versions: 'الإصدارات',
  com_ui_simple: 'بسيط',
  com_ui_version_var: 'الإصدار {0}',
  com_ui_advanced: 'متقدم',
  com_ui_admin_settings: 'إعدادات المسؤول',
  com_ui_error_save_admin_settings: 'حدث خطأ أثناء حفظ إعدادات المسؤول.',
  com_ui_prompt_name_required: 'اسم الأمر مطلوب',
  com_ui_prompt_text_required: 'النص مطلوب',
  com_ui_prompt_preview_not_shared: 'لم يسمح المؤلف بالمشاركة لهذا الإجراء',
  com_ui_prompt_text: 'نص',
  com_ui_back_to_chat: 'العودة إلى الدردشة',
  com_ui_categories: 'الفئات',
  com_ui_filter_prompts_name: 'تصفية الأوامر حسب الاسم',
  com_ui_back_to_prompts: 'العودة إلى الأوامر',
  com_ui_manage: 'إدارة',
  com_ui_variables: 'متغيرات',
  com_ui_search_categories: 'بحث في الفئات',
  com_ui_special_variables: 'المتغيرات الخاصة:',
  com_ui_variables_info:
    'استخدم أقواس مزدوجة في نصك لإنشاء متغيرات، مثل `{{متغير كمثال}}`، لملئها لاحقاً عند استخدام النص البرمجي.',
  com_ui_special_variables_info:
    'استخدم `{{current_date}}` للتاريخ الحالي، و `{{current_user}}` لاسم حسابك المحدد.',
  com_ui_dropdown_variables: 'متغيرات القائمة المنسدلة:',
  com_ui_dropdown_variables_info:
    'إنشاء قوائم منسدلة مخصصة لمحادثاتك: `{{variable_name:option1|option2|option3}}`',
  com_ui_upload_invalid: 'ملف غير صالح للتحميل. يجب أن يكون صورة لا تتجاوز الحد المسموح به',
  com_ui_latest_footer: 'الذكاء الاصطناعي للجميع.',
  com_ui_upload_invalid_var: 'ملف غير صالح للتحميل. يجب أن يكون صورة لا يتجاوز حجمها {0} ميجابايت',
  com_ui_renaming_var: 'إعادة تسمية "{0}"',
  com_ui_add_model_preset: 'إضافة نموذج أو إعداد مسبق للرد الإضافي',
  com_ui_loading: 'جارِ التحميل...',
  com_ui_all_proper: 'الكل',
  com_assistants_max_starters_reached: 'تم الوصول إلى الحد الأقصى لبادئات المحادثة',
  com_ui_revoke_keys: 'إلغاء المفاتيح',
  com_ui_revoke_key_endpoint: 'إلغاء المفتاح لـ {0}',
  com_ui_revoke_key_confirm: 'هل أنت متأكد من إلغاء هذا المفتاح؟',
  com_ui_revoke_keys_confirm: 'هل أنت متأكد من أنك تريد إلغاء جميع المفاتيح؟',
  com_ui_chat_history: 'سجل الدردشة',
  com_ui_chat: 'دردشة',
  com_ui_controls: 'عناصر التحكم',
  com_ui_dashboard: 'لوحة التحكم',
  com_ui_agent: 'وكيل',
  com_ui_agent_deleted: 'تم حذف المساعد بنجاح',
  com_ui_agent_delete_error: 'حدث خطأ أثناء حذف المساعد',
  com_ui_read_aloud: 'قراءة بصوت عالٍ',
  com_ui_delete_agent_confirm: 'هل أنت متأكد من رغبتك في حذف هذا الوكيل؟',
  com_ui_agents: 'الوكلاء',
  com_ui_share_var: 'مشاركة {0}',
  com_ui_create_prompt: 'إنشاء أمر',
  com_ui_enter_var: 'أدخل {0}',
  com_ui_my_prompts: 'أوامري',
  com_ui_share_to_all_users: 'مشاركة مع جميع المستخدمين',
  com_ui_no_category: 'لا يوجد تصنيف',
  com_ui_shared_prompts: 'المطالبات المشتركة',
  com_ui_prompts_allow_use: 'السماح باستخدام الأوامر',
  com_ui_prompts_allow_create: 'السماح بإنشاء الأوامر',
  com_ui_prompt_update_error: 'حدث خطأ أثناء تحديث المطالبة',
  com_ui_prompts_allow_share_global: 'السماح بمشاركة الأوامر مع جميع المستخدمين',
  com_ui_prompt_shared_to_all: 'تمت مشاركة هذا المحتوى مع جميع المستخدمين',
  com_ui_description_placeholder: 'اختياري: أدخل وصفاً ليتم عرضه للموجه',
  com_ui_command_usage_placeholder: 'حدد موجهًا باستخدام الأمر أو الاسم',
  com_ui_prompt_already_shared_to_all: 'تم مشاركة هذا المحتوى مع جميع المستخدمين بالفعل',
  com_ui_no_prompt_description: 'لم يتم العثور على وصف',
  com_ui_command_placeholder: 'اختياري: أدخل أمرًا للموجه أو سيتم استخدام الاسم.',
  com_ui_delete_tool: 'حذف الأداة',
  com_ui_delete_tool_confirm: 'هل أنت متأكد من رغبتك في حذف هذه الأداة؟',
  com_ui_delete_action: 'حذف الإجراء',
  com_ui_delete_action_confirm: 'هل أنت متأكد من رغبتك في حذف هذا الإجراء؟',
  com_ui_locked: 'مقفل',
  com_ui_use_micrphone: 'استخدام الميكروفون',
  com_ui_bookmarks_create_exists: 'هذه الإشارة المرجعية موجودة بالفعل',
  com_ui_bookmarks_filter: 'تصفية الإشارات المرجعية...',
  com_ui_delete_confirm_prompt_version_var:
    'سيؤدي هذا إلى حذف النسخة المحددة لـ "{0}." إذا لم تكن هناك نسخ أخرى، سيتم حذف النص التلقائي.',
  com_ui_bookmarks_delete: 'حذف الإشارة المرجعية',
  com_ui_no_bookmarks: 'يبدو أنه لا توجد لديك إشارات مرجعية بعد. انقر على محادثة وأضف واحدة جديدة',
  com_ui_add_multi_conversation: 'إضافة محادثات متعددة',
  com_ui_no_conversation_id: 'لم يتم العثور على معرّف المحادثة',
  com_auth_error_login_unverified:
    'لم يتم التحقق من حسابك بعد. يرجى التحقق من بريدك الإلكتروني للحصول على رابط التحقق.',
  com_auth_registration_success_insecure: 'تم التسجيل بنجاح.',
  com_auth_registration_success_generic:
    'يرجى التحقق من بريدك الإلكتروني لتأكيد عنوان البريد الإلكتروني الخاص بك.',
  com_auth_email_verification_failed: 'فشل التحقق من البريد الإلكتروني',
  com_auth_reset_password_if_email_exists:
    'إذا كان هناك حساب مرتبط بهذا البريد الإلكتروني، فقد تم إرسال بريد إلكتروني يحتوي على تعليمات إعادة تعيين كلمة المرور. يرجى التحقق من مجلد البريد غير المرغوب فيه.',
  com_auth_email_verification_rate_limited: 'طلبات كثيرة جداً. يرجى المحاولة مرة أخرى لاحقاً',
  com_auth_email_resent_success: 'تم إعادة إرسال البريد الإلكتروني للتحقق بنجاح',
  com_auth_email_resent_failed: 'فشل في إعادة إرسال البريد الإلكتروني للتحقق',
  com_auth_email_verification_failed_token_missing: 'فشل التحقق، الرمز مفقود',
  com_auth_email_verification_invalid: 'التحقق من البريد الإلكتروني غير صالح',
  com_auth_email_verification_success: 'تم التحقق من البريد الإلكتروني بنجاح',
  com_auth_email_verification_in_progress: 'جارٍ التحقق من بريدك الإلكتروني، يرجى الانتظار',
  com_auth_email_verification_resend_prompt: 'لم يصلك البريد الإلكتروني؟',
  com_auth_email_verification_redirecting: 'جارٍ إعادة التوجيه خلال {0} ثوانٍ...',
  com_auth_email_resend_link: 'إعادة إرسال البريد الإلكتروني',
  com_endpoint_export_share: 'تصدير/مشاركة',
  com_endpoint_anthropic_prompt_cache:
    'يتيح تخزين الموجهات المؤقت إعادة استخدام السياق أو التعليمات الكبيرة عبر استدعاءات API، مما يقلل التكاليف ووقت الاستجابة',
  com_endpoint_search: 'البحث عن نقطة النهاية بالاسم',
  com_endpoint_prompt_cache: 'استخدام التخزين المؤقت للأوامر',
  com_endpoint_config_key_never_expires: 'لن ينتهي مفتاحك أبداً',
  com_nav_account_settings: 'إعدادات الحساب',
  com_nav_font_size_xs: 'صغير جداً',
  com_nav_font_size_sm: 'صغير',
  com_nav_font_size_base: 'متوسط',
  com_nav_font_size_lg: 'كبير',
  com_nav_font_size_xl: 'كبير جداً',
  com_nav_user_msg_markdown: 'عرض رسائل المستخدم بتنسيق markdown',
  com_nav_text_to_speech: 'تحويل النص إلى كلام',
  com_nav_automatic_playback: 'تشغيل تلقائي لآخر رسالة',
  com_nav_tool_dialog_agents: 'أدوات الوكيل',
  com_nav_speech_to_text: 'تحويل الكلام إلى نص',
  com_ui_roleplay: 'لعب الأدوار',
  com_ui_write: 'كتابة',
  com_ui_shop: 'تسوق',
  com_ui_idea: 'أفكار',
  com_ui_misc: 'متنوع',
  com_ui_code: 'كود',
  com_ui_finance: 'المالية',
  com_ui_travel: 'السفر',
  com_ui_select_file: 'اختر ملفًا',
  com_ui_teach_or_explain: 'تعلم',
  com_ui_drag_drop_file: 'اسحب وأفلت ملفًا هنا',
  com_ui_upload_image: 'تحميل صورة',
  com_ui_clear_all: 'مسح الكل',
  com_nav_chat_direction: 'اتجاه الدردشة',
  com_ui_select_a_category: 'لم يتم اختيار فئة',
  com_nav_auto_send_prompts: 'إرسال تلقائي للموجهات',
  com_nav_clear_cache_confirm_message: 'هل أنت متأكد أنك تريد مسح ذاكرة التخزين المؤقت؟',
  com_nav_stop_generating: 'إيقاف التوليد',
  com_nav_delete_account: 'حذف الحساب',
  com_nav_always_make_prod: 'جعل الإصدارات الجديدة للإنتاج دائماً',
  com_nav_delete_account_confirm: 'حذف الحساب - هل أنت متأكد؟',
  com_nav_delete_account_button: 'حذف حسابي نهائياً',
  com_nav_delete_account_email_placeholder: 'الرجاء إدخال البريد الإلكتروني للحساب',
  com_nav_delete_warning: 'تحذير: سيؤدي هذا إلى حذف حسابك بشكل نهائي.',
  com_nav_delete_data_info: 'سيتم حذف جميع بياناتك',
  com_nav_delete_account_confirm_placeholder: 'للمتابعة، اكتب "DELETE" في حقل الإدخال أدناه',
  com_nav_conversation_mode: 'وضع المحادثة',
  com_nav_auto_send_text_disabled: 'اضبط القيمة على -1 للتعطيل',
  com_nav_auto_send_text: 'إرسال النص تلقائيًا',
  com_nav_db_sensitivity: 'حساسية الديسيبل',
  com_nav_playback_rate: 'سرعة تشغيل الصوت',
  com_nav_audio_play_error: 'خطأ في تشغيل الصوت: {0}',
  com_nav_audio_process_error: 'خطأ في معالجة الصوت: {0}',
  com_nav_auto_transcribe_audio: 'النسخ التلقائي للصوت',
  com_nav_long_audio_warning: 'ستستغرق النصوص الطويلة وقتاً أطول للمعالجة',
  com_nav_tts_init_error: 'فشل في تهيئة تحويل النص إلى كلام: {0}',
  com_nav_source_buffer_error: 'خطأ في إعداد تشغيل الصوت. يرجى تحديث الصفحة.',
  com_nav_tts_unsupported_error: 'تحويل النص إلى كلام للمحرك المحدد غير مدعوم في هذا المتصفح',
  com_nav_media_source_init_error: 'تعذر تجهيز مشغل الصوت. يرجى التحقق من إعدادات المتصفح.',
  com_nav_speech_cancel_error: 'تعذر إيقاف تشغيل الصوت. قد تحتاج إلى تحديث الصفحة.',
  com_nav_voices_fetch_error: 'تعذر استرجاع خيارات الصوت. يرجى التحقق من اتصال الإنترنت الخاص بك.',
  com_nav_engine: 'المحرك',
  com_nav_edge: 'إيدج',
  com_nav_external: 'خارجي',
  com_nav_browser: 'المتصفح',
  com_nav_buffer_append_error: 'مشكلة في تدفق الصوت. قد يتم مقاطعة التشغيل.',
  com_nav_delete_cache_storage: 'حذف ذاكرة التخزين المؤقت للنص المنطوق',
  com_nav_voice_select: 'الصوت',
  com_nav_enable_cache_tts: 'تمكين ذاكرة التخزين المؤقت للنص المنطوق',
  com_nav_enable_cloud_browser_voice: 'استخدام الأصوات السحابية',
  com_nav_info_enter_to_send:
    'عند التفعيل، سيؤدي الضغط على مفتاح `ENTER` إلى إرسال رسالتك. عند التعطيل، سيؤدي الضغط على مفتاح Enter إلى إضافة سطر جديد، وستحتاج إلى الضغط على `CTRL + ENTER` / `⌘ + ENTER` لإرسال رسالتك.',
  com_nav_info_fork_split_target_setting:
    'عند التفعيل، سيبدأ التفريع من الرسالة المستهدفة إلى آخر رسالة في المحادثة، وفقاً للسلوك المحدد.',
  com_nav_info_save_draft:
    'عند التمكين، سيتم حفظ النص والمرفقات التي تدخلها في نموذج الدردشة تلقائياً كمسودات محلية. ستظل هذه المسودات متاحة حتى إذا قمت بإعادة تحميل الصفحة أو التبديل إلى محادثة مختلفة. يتم تخزين المسودات محلياً على جهازك ويتم حذفها بمجرد إرسال الرسالة.',
  com_nav_info_fork_change_default:
    '"الرسائل المرئية فقط" تتضمن فقط المسار المباشر إلى الرسالة المحددة. "تضمين الفروع ذات الصلة" يضيف الفروع على طول المسار. "تضمين الكل إلى/من هنا" يشمل جميع الرسائل والفروع المتصلة.',
  com_nav_info_revoke:
    'سيؤدي هذا الإجراء إلى إلغاء وإزالة جميع مفاتيح API التي قمت بتوفيرها. ستحتاج إلى إعادة إدخال بيانات الاعتماد هذه لمواصلة استخدام نقاط النهاية.',
  com_nav_info_user_name_display:
    'عند التفعيل، سيظهر اسم المستخدم الخاص بك فوق كل رسالة ترسلها. عند التعطيل، سترى فقط كلمة "أنت" فوق رسائلك',
  com_nav_chat_commands: 'أوامر الدردشة',
  com_nav_info_latex_parsing:
    'عند التمكين، سيتم عرض رموز LaTeX في الرسائل كمعادلات رياضية. تعطيل هذه الخاصية قد يحسن الأداء إذا كنت لا تحتاج إلى عرض LaTeX.',
  com_nav_info_delete_cache_storage:
    'سيؤدي هذا الإجراء إلى حذف جميع ملفات الصوت المخزنة مؤقتاً لخاصية تحويل النص إلى كلام (TTS) على جهازك. تُستخدم ملفات الصوت المخزنة مؤقتاً لتسريع تشغيل الصوت الذي تم إنشاؤه مسبقاً، ولكنها قد تستهلك مساحة تخزين على جهازك.',
  com_nav_chat_commands_info:
    'يتم تفعيل هذه الأوامر عن طريق كتابة رموز محددة في بداية رسالتك. يتم تشغيل كل أمر بواسطة البادئة المخصصة له. يمكنك تعطيلها إذا كنت تستخدم هذه الرموز بشكل متكرر في بداية رسائلك.',
  com_nav_commands: 'الأوامر',
  com_nav_commands_tab: 'إعدادات الأوامر',
  com_nav_at_command: 'أمر-@',
  com_nav_at_command_description:
    'تبديل الأمر "@" للتنقل بين نقاط النهاية والنماذج والإعدادات المسبقة وغيرها',
  com_nav_plus_command: '+- الأمر',
  com_nav_slash_command: '/-الأمر',
  com_nav_command_settings: 'إعدادات الأوامر',
  com_nav_command_settings_description: 'تخصيص الأوامر المتاحة في المحادثة',
  com_nav_plus_command_description: 'تبديل الأمر "+" لإضافة إعداد الردود المتعددة',
  com_nav_slash_command_description: 'تبديل الأمر "/" لاختيار موجه عبر لوحة المفاتيح',
  com_nav_setting_chat: 'دردشة',
  com_nav_no_search_results: 'لم يتم العثور على نتائج البحث',
  com_nav_setting_speech: 'الكلام',
};
