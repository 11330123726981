import React, { useState, useEffect } from 'react';
import type { WhitelistFormProps } from './types';

export const WhitelistForm: React.FC<WhitelistFormProps> = ({
  onSubmit,
  editMode,
  editingEmail,
  onClose,
  existingEmails,
}) => {
  const [email, setEmail] = useState(editingEmail?.email ?? '');
  const [company, setCompany] = useState(editingEmail?.company ?? '');
  const [notes, setNotes] = useState(editingEmail?.notes ?? '');
  const [error, setError] = useState('');

  useEffect(() => {
    if (editMode && editingEmail) {
      setEmail(editingEmail.email);
      setCompany(editingEmail.company ?? '');
      setNotes(editingEmail.notes ?? '');
    } else {
      setEmail('');
      setCompany('');
      setNotes('');
    }
  }, [editMode, editingEmail]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (email.length === 0) {
      setError('Email is required');
      return;
    }

    const isEmailExisting = editMode
      ? existingEmails.includes(email.toLowerCase()) &&
        email.toLowerCase() !== editingEmail?.email.toLowerCase()
      : existingEmails.includes(email.toLowerCase());

    if (isEmailExisting) {
      setError('This email is already on the whitelist');
      return;
    }

    onSubmit(email, company, notes);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40">
      <div className="dark:shadow-custom-dark relative w-full max-w-md rounded-lg bg-gray-200 p-6 shadow-lg dark:bg-[#0F172A]">
        <form onSubmit={handleSubmit}>
          <h2 className="mb-4 text-center text-xl font-semibold text-gray-800 dark:text-white">
            {editMode ? 'Edit Email' : 'Add New Email'}
          </h2>

          <div className="relative mb-4">
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError('');
              }}
              placeholder=" "
              className="form-input peer block w-full appearance-none rounded-lg px-4 py-3 text-base text-gray-800 focus:outline-none focus:ring-0 dark:text-white"
              required
            />
            <label
              htmlFor="email"
              className={`pointer-events-none absolute left-3 top-1/2 origin-[0] -translate-y-1/2 transform text-sm text-[#0f172a] opacity-80 duration-100 ${
                email ? 'left-3 top-2 -translate-y-4 scale-75' : ''
              } peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:left-3 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 dark:text-white`}
            >
              Email Address
            </label>
            {error && <p className="ml-1 mt-1 text-sm text-red-500">{error}</p>}
          </div>

          <div className="relative mb-4">
            <input
              id="company"
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              placeholder=" "
              className="form-input peer block w-full appearance-none rounded-lg px-4 py-3 text-base text-gray-800 focus:outline-none focus:ring-0 dark:text-white"
            />
            <label
              htmlFor="company"
              className={`pointer-events-none absolute left-3 top-1/2 origin-[0] -translate-y-1/2 transform text-sm text-[#0f172a] opacity-80 duration-100 ${
                company ? 'left-3 top-2 -translate-y-4 scale-75' : ''
              } peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:left-3 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 dark:text-white`}
            >
              Company
            </label>
          </div>

          <div className="relative mb-4">
            <textarea
              id="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder=" "
              className="form-input peer block w-full appearance-none rounded-lg px-4 py-3 text-base text-gray-800 focus:outline-none focus:ring-0 dark:text-white"
              rows={3}
            />
            <label
              htmlFor="notes"
              className={`pointer-events-none absolute left-3 top-2 origin-[0] transform text-sm text-[#0f172a] opacity-80 duration-100 ${
                notes ? '-translate-y-4 scale-75' : 'top-2'
              } peer-placeholder-shown:left-4 peer-placeholder-shown:top-2 peer-focus:left-3 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 dark:text-white`}
            >
              Notes (optional)
            </label>
          </div>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="rounded-lg bg-gray-500 px-3 py-2 text-sm text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="auth-button rounded-lg px-3 py-2 text-sm text-white hover:text-[#0F172A]"
            >
              {editMode ? 'Update' : 'Add Email'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
