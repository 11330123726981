import { PlusCircle } from 'lucide-react';
import { isAssistantsEndpoint } from 'librechat-data-provider';
import type { TConversation } from 'librechat-data-provider';
import { useChatContext, useAddedChatContext } from '~/Providers';
import { TooltipAnchor } from '~/components';
import { mainTextareaId } from '~/common';
import { useLocalize } from '~/hooks';
import { cn } from '~/utils';

function AddMultiConvo() {
  const { conversation } = useChatContext();
  const { setConversation: setAddedConvo } = useAddedChatContext();
  const localize = useLocalize();

  const clickHandler = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { title: _t, ...convo } = conversation ?? ({} as TConversation);
    setAddedConvo({
      ...convo,
      title: '',
    });

    const textarea = document.getElementById(mainTextareaId);
    if (textarea) {
      textarea.focus();
    }
  };

  if (!conversation) {
    return null;
  }

  if (isAssistantsEndpoint(conversation.endpoint)) {
    return null;
  }

  return (
    <TooltipAnchor
      id="add-multi-conversation-button"
      aria-label={localize('com_ui_add_multi_conversation')}
      description={localize('com_ui_add_multi_conversation')}
      tabIndex={0}
      role="button"
      onClick={clickHandler}
      data-testid="parameters-button"
      className="relative flex hidden h-9 w-9 items-center justify-center whitespace-nowrap rounded-lg border border-[#9A9A9A] hover:bg-[#F2F2F2] focus:ring-0 focus:ring-offset-0 md:flex dark:border-[#435880] dark:hover:bg-[#1c2846]"
    >
      <PlusCircle size={16} aria-label="Plus Icon" />
    </TooltipAnchor>
  );
}

export default AddMultiConvo;
