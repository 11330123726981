import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

const SentryWidget = () => {
  useEffect(() => {
    const feedback = Sentry.getFeedback();
    const button = document.getElementById('sentry-feedback-button');
    if (feedback && button) {
      feedback.attachTo(button, {
        formTitle: 'Feedback',
      });
    } else {
      console.error(
        'Sentry Feedback integration or target button is not available.',
      );
    }
  }, []);

  return (
    <button
      id="sentry-feedback-button"
      className="flex items-center rounded-lg border border-[#E8E6E6] bg-[#CFCFCF] px-2 py-2 text-black hover:bg-[#E8E6E6] md:gap-2 md:px-3 dark:border-[#435880] dark:bg-[#1C2846] dark:text-white dark:hover:bg-[#435880]"
    >
      <span className="text-[12px] font-normal">Feedback</span>{' '}
    </button>
  );
};

export default SentryWidget;
