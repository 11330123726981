// German Phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_error_moderation:
    'Es scheint, dass der eingereichte Inhalt von unserem Moderationssystem als nicht mit unseren Community-Richtlinien vereinbar gekennzeichnet wurde. Wir können mit diesem spezifischen Thema nicht fortfahren. Wenn Sie andere Fragen oder Themen haben, die Sie erkunden möchten, bearbeiten Sie bitte Ihre Nachricht oder erstellen Sie eine neue Konversation.',
  com_error_no_user_key:
    'Kein API-Key gefunden. Bitte gebe einen API-Key ein und versuche es erneut.',
  com_error_no_base_url: 'Keine Basis-URL gefunden. Bitte gebe eine ein und versuche es erneut.',
  com_error_invalid_user_key:
    'Ungültiger API-Key angegeben. Bitte gebe einen gültigen API-Key ein und versuche es erneut.',
  com_error_expired_user_key:
    'Der angegebene API-Key für {0} ist am {1} abgelaufen. Bitte gebe einen neuen API-Key ein und versuche es erneut.',
  com_files_no_results: 'Keine Ergebnisse.',
  com_files_filter: 'Dateien filtern...',
  com_files_number_selected: '{0} von {1} Datei(en) ausgewählt',
  com_sidepanel_select_assistant: 'Assistenten auswählen',
  com_sidepanel_parameters: 'Parameter',
  com_sidepanel_assistant_builder: 'Assistenten-Ersteller',
  com_sidepanel_hide_panel: 'Seitenleiste ausblenden',
  com_sidepanel_attach_files: 'Dateien anhängen',
  com_sidepanel_manage_files: 'Dateien verwalten',
  com_sidepanel_conversation_tags: 'Lesezeichen',
  com_assistants_capabilities: 'Fähigkeiten',
  com_assistants_file_search: 'Dateisuche',
  com_assistants_file_search_info:
    'Die Dateisuche ermöglicht dem Assistenten, Wissen aus Dateien zu nutzen, die du oder deine Benutzer hochladen. Sobald eine Datei hochgeladen wurde, entscheidet der Assistent automatisch, wann er basierend auf Benutzeranfragen Inhalte abruft. Das Anhängen von Vektor-Speichern für die Dateisuche wird noch nicht unterstützt. Sie können sie vom Provider-Playground aus anhängen oder Dateien für die Dateisuche auf Thread-Basis an Nachrichten anhängen.',
  com_assistants_code_interpreter_info:
    'Der Code-Interpreter ermöglicht es dem Assistenten, Code zu schreiben und auszuführen. Dieses Tool kann Dateien mit verschiedenen Daten und Formatierungen verarbeiten und Dateien wie Grafiken generieren.',
  com_assistants_knowledge: 'Wissen',
  com_assistants_knowledge_info:
    'Wenn du Dateien unter Wissen hochlädst, kannst du bei Gesprächen mit deinem Assistenten Dateiinhalte einbeziehen.',
  com_assistants_knowledge_disabled:
    'Der Assistent muss erstellt und Code-Interpreter oder Abruf müssen aktiviert und gespeichert werden, bevor Dateien als Wissen hochgeladen werden können.',
  com_assistants_image_vision: 'Bildanalyse',
  com_assistants_code_interpreter: 'Code-Interpreter',
  com_assistants_code_interpreter_files: 'Die folgenden Dateien sind nur für den Code-Interpreter:',
  com_assistants_retrieval: 'Abruf',
  com_assistants_search_name: 'Assistenten nach Namen suchen',
  com_ui_tools: 'Werkzeuge',
  com_assistants_actions: 'Aktionen',
  com_assistants_add_tools: 'Werkzeuge hinzufügen',
  com_assistants_add_actions: 'Aktionen hinzufügen',
  com_assistants_non_retrieval_model:
    'Die Dateisuche ist für dieses KI-Modell nicht aktiviert. Bitte wähle ein anderes Modell aus.',
  com_assistants_available_actions: 'Verfügbare Aktionen',
  com_assistants_running_action: 'Aktion wird ausgeführt',
  com_assistants_completed_action: 'Mit {0} gesprochen',
  com_assistants_completed_function: '{0} ausgeführt',
  com_assistants_function_use: 'Assistent hat {0} verwendet',
  com_assistants_domain_info: 'Assistent hat diese Information an {0} gesendet',
  com_assistants_delete_actions_success: 'Aktion erfolgreich vom Assistenten gelöscht',
  com_assistants_update_actions_success: 'Aktion erfolgreich erstellt oder aktualisiert',
  com_assistants_update_actions_error:
    'Bei der Erstellung oder Aktualisierung der Aktion ist ein Fehler aufgetreten.',
  com_assistants_delete_actions_error: 'Beim Löschen der Aktion ist ein Fehler aufgetreten.',
  com_assistants_actions_info:
    'Lasse deinen Assistenten Informationen abrufen oder Aktionen über APIs ausführen',
  com_assistants_name_placeholder: 'Optional: Der Name des Assistenten',
  com_assistants_instructions_placeholder: 'Die Systemanweisungen, die der Assistent verwendet',
  com_assistants_description_placeholder: 'Optional: Beschreibe deinen Assistenten hier',
  com_assistants_actions_disabled:
    'Du müsst einen Assistenten erstellen, bevor du Aktionen hinzufügen kannst.',
  com_assistants_update_success: 'Erfolgreich aktualisiert',
  com_assistants_update_error:
    'Bei der Aktualisierung deines Assistenten ist ein Fehler aufgetreten.',
  com_assistants_create_success: 'Erfolgreich erstellt',
  com_assistants_create_error: 'Bei der Erstellung deines Assistenten ist ein Fehler aufgetreten.',
  com_ui_date_today: 'Heute',
  com_ui_date_yesterday: 'Gestern',
  com_ui_date_previous_7_days: 'Letzte 7 Tage',
  com_ui_date_previous_30_days: 'Letzte 30 Tage',
  com_ui_date_january: 'Januar',
  com_ui_date_february: 'Februar',
  com_ui_date_march: 'März',
  com_ui_date_april: 'April',
  com_ui_date_may: 'Mai',
  com_ui_date_june: 'Juni',
  com_ui_date_july: 'Juli',
  com_ui_date_august: 'August',
  com_ui_date_september: 'September',
  com_ui_date_october: 'Oktober',
  com_ui_date_november: 'November',
  com_ui_date_december: 'Dezember',
  com_ui_field_required: 'Dieses Feld ist erforderlich',
  com_ui_download_error:
    'Fehler beim Herunterladen der Datei. Die Datei wurde möglicherweise gelöscht.',
  com_ui_attach_error_type: 'Nicht unterstützter Dateityp für Endpunkt:',
  com_ui_attach_error_openai:
    'Assistentendateien können nicht an andere Endpunkte angehängt werden',
  com_ui_attach_warn_endpoint:
    'Nicht-Assistentendateien werden möglicherweise ohne kompatibles Werkzeug ignoriert',
  com_ui_attach_error_size: 'Dateigrößenlimit für Endpunkt überschritten:',
  com_ui_attach_error:
    'Datei kann nicht angehängt werden. Erstelle oder wähle einen Chat oder versuche, die Seite zu aktualisieren.',
  com_ui_examples: 'Beispiele',
  com_ui_new_chat: 'Neuer Chat',
  com_ui_happy_birthday: 'Es ist mein 1. Geburtstag!',
  com_ui_experimental: 'Experimentelle Funktionen',
  com_ui_on: 'An',
  com_ui_off: 'Aus',
  com_ui_yes: 'Ja',
  com_ui_no: 'Nein',
  com_ui_ascending: 'Aufsteigend',
  com_ui_descending: 'Absteigend',
  com_ui_show_all: 'Alle anzeigen',
  com_ui_name: 'Name',
  com_ui_date: 'Datum',
  com_ui_storage: 'Speicher',
  com_ui_context: 'Kontext',
  com_ui_size: 'Größe',
  com_ui_host: 'Host',
  com_ui_update: 'Aktualisieren',
  com_ui_authentication: 'Authentifizierung',
  com_ui_instructions: 'Anweisungen',
  com_ui_description: 'Beschreibung',
  com_ui_error: 'Fehler',
  com_ui_error_connection: 'Verbindungsfehler zum Server. Versuche, die Seite zu aktualisieren.',
  com_ui_select: 'Auswählen',
  com_ui_input: 'Eingabe',
  com_ui_close: 'Schließen',
  com_ui_model: 'KI-Modell',
  com_ui_select_model: 'Ein KI-Modell auswählen',
  com_ui_select_search_model: 'KI-Modell nach Namen suchen',
  com_ui_select_search_plugin: 'Plugin nach Namen suchen',
  com_ui_use_prompt: 'Prompt verwenden',
  com_ui_prev: 'Zurück',
  com_ui_next: 'Weiter',
  com_ui_stop: 'Stopp',
  com_ui_upload_files: 'Dateien hochladen',
  com_ui_prompt: 'Prompt',
  com_ui_prompts: 'Prompts',
  com_ui_prompt_name: 'Prompt-Name',
  com_ui_delete_prompt: 'Prompt löschen?',
  com_ui_admin: 'Admin',
  com_ui_simple: 'Einfach',
  com_ui_versions: 'Versionen',
  com_ui_version_var: 'Version {0}',
  com_ui_advanced: 'Erweitert',
  com_ui_admin_settings: 'Admin-Einstellungen',
  com_ui_error_save_admin_settings:
    'Beim Speichern Ihrer Admin-Einstellungen ist ein Fehler aufgetreten.',
  com_ui_prompt_preview_not_shared:
    'Der Autor hat die Zusammenarbeit für diesen Prompt nicht erlaubt.',
  com_ui_prompt_name_required: 'Prompt-Name ist erforderlich',
  com_ui_prompt_text_required: 'Text ist erforderlich',
  com_ui_prompt_text: 'Text',
  com_ui_back_to_chat: 'Zurück zum Chat',
  com_ui_back_to_prompts: 'Zurück zu den Prompts',
  com_ui_categories: 'Kategorien',
  com_ui_filter_prompts_name: 'Prompts nach Namen filtern',
  com_ui_search_categories: 'Kategorien durchsuchen',
  com_ui_manage: 'Verwalten',
  com_ui_variables: 'Variablen',
  com_ui_variables_info:
    'Verwende doppelte geschweifte Klammern in Ihrem Text, um Variablen zu erstellen, z.B. {{Beispielvariable}}, die du später beim Verwenden des Prompts ausfüllen kannst.',
  com_ui_special_variables: 'Spezielle Variablen:',
  com_ui_special_variables_info:
    'Verwende `{{current_date}}` für das aktuelle Datum und `{{current_user}}` für deinen angegebenen Kontonamen.',
  com_ui_showing: 'Anzeige von',
  com_ui_of: 'von',
  com_ui_entries: 'Einträgen',
  com_ui_pay_per_call:
    'Alle KI-Chats an einem Ort. Bezahle pro Anfrage an die KI und nicht pro Monat',
  com_ui_new_footer: 'Alle KI-Chats an einem Ort.',
  com_ui_latest_footer: 'Alle KIs für alle.',
  com_ui_enter: 'Eingabe',
  com_ui_submit: 'Absenden',
  com_ui_none_selected: 'Nichts ausgewählt',
  com_ui_upload_success: 'Datei erfolgreich hochgeladen',
  com_ui_upload_error: 'Beim Hochladen Ihrer Datei ist ein Fehler aufgetreten',
  com_ui_upload_invalid:
    'Ungültige Datei zum Hochladen. Muss ein Bild sein und das Limit nicht überschreiten',
  com_ui_upload_invalid_var:
    'Ungültige Datei zum Hochladen. Muss ein Bild sein und {0} MB nicht überschreiten',
  com_ui_cancel: 'Abbrechen',
  com_ui_save: 'Speichern',
  com_ui_renaming_var: '"{0}" umbenennen',
  com_ui_save_submit: 'Speichern & Absenden',
  com_user_message: 'Du',
  com_ui_read_aloud: 'Vorlesen',
  com_ui_copied: 'Kopiert!',
  com_ui_copy_code: 'Code kopieren',
  com_ui_copy_to_clipboard: 'In die Zwischenablage kopieren',
  com_ui_copied_to_clipboard: 'In die Zwischenablage kopiert',
  com_ui_fork: 'Abzweigen',
  com_ui_fork_info_1:
    'Verwende diese Einstellung, um Nachrichten mit dem gewünschten Verhalten abzuzweigen.',
  com_ui_fork_info_2:
    '"Abzweigen" bezieht sich auf das Erstellen einer neuen Konversation, die von bestimmten Nachrichten in der aktuellen Konversation ausgeht/endet und eine Kopie gemäß den ausgewählten Optionen erstellt.',
  com_ui_fork_info_3:
    'Die "Zielnachricht" bezieht sich entweder auf die Nachricht, von der aus dieses Popup geöffnet wurde, oder, wenn du "{0}" aktivierst, auf die letzte Nachricht in der Konversation.',
  com_ui_fork_info_visible:
    'Diese Option zweigt nur die sichtbaren Nachrichten ab; mit anderen Worten, den direkten Pfad zur Zielnachricht, ohne jegliche Verzweigungen.',
  com_ui_fork_info_branches:
    'Diese Option zweigt die sichtbaren Nachrichten zusammen mit zugehörigen Verzweigungen ab; mit anderen Worten, den direkten Pfad zur Zielnachricht, einschließlich der Verzweigungen entlang des Pfades.',
  com_ui_fork_info_target:
    'Diese Option zweigt alle Nachrichten ab, die zur Zielnachricht führen, einschließlich ihrer Nachbarn; mit anderen Worten, alle Nachrichtenverzweigungen werden einbezogen, unabhängig davon, ob sie sichtbar sind oder sich auf demselben Pfad befinden.',
  com_ui_fork_info_start:
    'Wenn aktiviert, beginnt das Abzweigen von dieser Nachricht bis zur letzten Nachricht in der Konversation, gemäß dem oben ausgewählten Verhalten.',
  com_ui_fork_info_remember:
    'Aktiviere dies, um sich die von dir ausgewählten Optionen für zukünftige Verwendung zu merken, um das Abzweigen von Konversationen nach deinen Vorlieben zu beschleunigen.',
  com_ui_fork_success: 'Konversation erfolgreich abgezweigt',
  com_ui_fork_processing: 'Konversation wird abgezweigt...',
  com_ui_fork_error: 'Beim Abzweigen der Konversation ist ein Fehler aufgetreten',
  com_ui_fork_change_default: 'Standard-Abzweigungsoption',
  com_ui_fork_default: 'Standard-Abzweigungsoption verwenden',
  com_ui_fork_remember: 'Merken',
  com_ui_fork_split_target_setting: 'Abzweigung standardmäßig von der Zielnachricht beginnen',
  com_ui_fork_split_target: 'Abzweigung hier beginnen',
  com_ui_fork_remember_checked:
    'Ihre Auswahl wird nach der Verwendung gespeichert. Du kannst dies jederzeit in den Einstellungen ändern.',
  com_ui_fork_all_target: 'Alle bis/von hier einbeziehen',
  com_ui_fork_branches: 'Zugehörige Verzweigungen einbeziehen',
  com_ui_fork_visible: 'Nur sichtbare Nachrichten',
  com_ui_fork_from_message: 'Wähle eine Abzweigungsoption',
  com_ui_mention:
    'Erwähne einen Endpunkt, Assistenten oder eine Voreinstellung, um schnell dorthin zu wechseln',
  com_ui_add_model_preset:
    'Ein KI-Modell oder eine Voreinstellung für eine zusätzliche Antwort hinzufügen',
  com_ui_regenerate: 'Neu generieren',
  com_ui_continue: 'Fortfahren',
  com_ui_edit: 'Bearbeiten',
  com_ui_loading: 'Lädt...',
  com_ui_success: 'Erfolg',
  com_ui_all: 'alle',
  com_ui_all_proper: 'Alle',
  com_ui_clear: 'Löschen',
  com_ui_revoke: 'Widerrufen',
  com_ui_revoke_info: 'Benutzer-API-Keys widerrufen',
  com_ui_import_conversation: 'Importieren',
  com_ui_nothing_found: 'Nichts gefunden',
  com_ui_go_to_conversation: 'Zur Konversation gehen',
  com_ui_import_conversation_info: 'Konversationen aus einer JSON-Datei importieren',
  com_ui_import_conversation_success: 'Konversationen erfolgreich importiert',
  com_ui_import_conversation_error:
    'Beim Importieren Ihrer Konversationen ist ein Fehler aufgetreten',
  com_ui_import_conversation_file_type_error: 'Nicht unterstützter Importtyp',
  com_ui_confirm_action: 'Aktion bestätigen',
  com_ui_chat: 'Chat',
  com_ui_dashboard: 'Dashboard',
  com_ui_chats: 'Chats',
  com_ui_avatar: 'Avatar',
  com_ui_unknown: 'Unbekannt',
  com_ui_result: 'Ergebnis',
  com_ui_image_gen: 'Bildgenerierung',
  com_ui_assistant: 'Assistent',
  com_ui_assistant_deleted: 'Assistent erfolgreich gelöscht',
  com_ui_assistant_delete_error: 'Beim Löschen des Assistenten ist ein Fehler aufgetreten',
  com_ui_assistants: 'Assistenten',
  com_ui_attachment: 'Anhang',
  com_ui_assistants_output: 'Assistenten-Ausgabe',
  com_ui_delete: 'Löschen',
  com_ui_create: 'Erstellen',
  com_ui_create_prompt: 'Prompt erstellen',
  com_ui_share: 'Teilen',
  com_ui_share_var: '{0} teilen',
  com_ui_copy_link: 'Link kopieren',
  com_ui_update_link: 'Link aktualisieren',
  com_ui_create_link: 'Link erstellen',
  com_ui_share_to_all_users: 'Mit allen Benutzern teilen',
  com_ui_my_prompts: 'Meine Prompts',
  com_ui_no_category: 'Keine Kategorie',
  com_ui_shared_prompts: 'Geteilte Prompts',
  com_ui_prompts_allow_use: 'Verwendung von Prompts erlauben',
  com_ui_prompts_allow_create: 'Erstellung von Prompts erlauben',
  com_ui_prompts_allow_share_global: 'Teilen von Prompts mit allen Benutzern erlauben',
  com_ui_prompt_shared_to_all: 'Dieser Prompt wird mit allen Benutzern geteilt',
  com_ui_prompt_update_error: 'Beim Aktualisieren des Prompts ist ein Fehler aufgetreten',
  com_ui_prompt_already_shared_to_all: 'Dieser Prompt wird bereits mit allen Benutzern geteilt',
  com_ui_description_placeholder: 'Optional: Gib eine Beschreibung für den Prompt ein',
  com_ui_command_placeholder: 'Optional: Gib einen Promptbefehl ein oder den Namen.',
  com_ui_command_usage_placeholder: 'Wähle einen Prompt nach Befehl oder Name aus',
  com_ui_no_prompt_description: 'Keine Beschreibung gefunden.',
  com_ui_share_link_to_chat: 'Link zum Chat teilen',
  com_ui_share_error: 'Beim Teilen des Chat-Links ist ein Fehler aufgetreten',
  com_ui_share_retrieve_error: 'Beim Abrufen der geteilten Links ist ein Fehler aufgetreten',
  com_ui_share_delete_error: 'Beim Löschen des geteilten Links ist ein Fehler aufgetreten',
  com_ui_share_create_message:
    'Ihr Name und alle Nachrichten, die du nach dem Teilen hinzufügst, bleiben privat.',
  com_ui_share_created_message:
    'Ein geteilter Link zu Ihrem Chat wurde erstellt. Verwalte zuvor geteilte Chats jederzeit über die Einstellungen.',
  com_ui_share_update_message:
    'Ihr Name, benutzerdefinierte Anweisungen und alle Nachrichten, die du nach dem Teilen hinzufügen, bleiben privat.',
  com_ui_share_updated_message:
    'Ein geteilter Link zu Ihrem Chat wurde aktualisiert. Verwalte zuvor geteilte Chats jederzeit über die Einstellungen.',
  com_ui_shared_link_not_found: 'Geteilter Link nicht gefunden',
  com_ui_delete_conversation: 'Chat löschen?',
  com_ui_delete_confirm: 'Dies wird löschen:',
  com_ui_delete_tool: 'Werkzeug löschen',
  com_ui_delete_tool_confirm: 'Bist du sicher, dass du dieses Werkzeug löschen möchtest?',
  com_ui_delete_action: 'Aktion löschen',
  com_ui_delete_action_confirm: 'Bist du sicher, dass du diese Aktion löschen möchtest?',
  com_ui_delete_confirm_prompt_version_var:
    'Dies wird die ausgewählte Version für "{0}" löschen. Wenn keine anderen Versionen existieren, wird der Prompt gelöscht.',
  com_ui_delete_assistant_confirm:
    'Bist du sicher, dass du diesen Assistenten löschen möchtest? Dies kann nicht rückgängig gemacht werden.',
  com_ui_rename: 'Umbenennen',
  com_ui_archive: 'Archivieren',
  com_ui_archive_error: 'Konversation konnte nicht archiviert werden',
  com_ui_unarchive: 'Aus Archiv holen',
  com_ui_unarchive_error: 'Konversation konnte nicht aus dem Archiv geholt werden',
  com_ui_more_options: 'Mehr',
  com_ui_preview: 'Vorschau',
  com_ui_upload: 'Hochladen',
  com_ui_connect: 'Verbinden',
  com_ui_locked: 'Gesperrt',
  com_ui_upload_delay:
    'Das Hochladen von "{0}" dauert etwas länger. Bitte warte, während die Datei für den Abruf indexiert wird.',
  com_ui_privacy_policy: 'Datenschutzerklärung',
  com_ui_terms_of_service: 'Nutzungsbedingungen',
  com_ui_use_micrphone: 'Mikrofon verwenden',
  com_ui_min_tags: 'Es können nicht mehr Werte entfernt werden, mindestens {0} sind erforderlich.',
  com_ui_max_tags: 'Die maximale Anzahl ist {0}, es werden die neuesten Werte verwendet.',
  com_ui_bookmarks: 'Lesezeichen',
  com_ui_bookmarks_rebuild: 'Erneuern',
  com_ui_bookmarks_new: 'Neues Lesezeichen',
  com_ui_bookmark_delete_confirm: 'Bist du sicher, dass du dieses Lesezeichen löschen möchtest?',
  com_ui_bookmarks_title: 'Titel',
  com_ui_bookmarks_count: 'Anzahl',
  com_ui_bookmarks_description: 'Beschreibung',
  com_ui_bookmarks_create_success: 'Lesezeichen erfolgreich erstellt',
  com_ui_bookmarks_update_success: 'Lesezeichen erfolgreich aktualisiert',
  com_ui_bookmarks_delete_success: 'Lesezeichen erfolgreich gelöscht',
  com_ui_bookmarks_create_error: 'Beim Erstellen des Lesezeichens ist ein Fehler aufgetreten',
  com_ui_bookmarks_update_error: 'Beim Aktualisieren des Lesezeichens ist ein Fehler aufgetreten',
  com_ui_bookmarks_delete_error: 'Beim Löschen des Lesezeichens ist ein Fehler aufgetreten',
  com_ui_bookmarks_add_to_conversation: 'Zur aktuellen Konversation hinzufügen',
  com_ui_bookmarks_filter: 'Lesezeichen filtern...',
  com_ui_accept: 'Ich akzeptiere',
  com_ui_decline: 'Ich akzeptiere nicht',
  com_ui_terms_and_conditions: 'Allgemeine Geschäftsbedingungen',
  com_ui_no_terms_content: 'Keine Inhalte der Allgemeinen Geschäftsbedingungen zum Anzeigen',
  com_auth_error_login:
    'Anmeldung mit den angegebenen Informationen nicht möglich. Bitte überprüfe deine Anmeldedaten und versuche es erneut.',
  com_auth_error_login_rl:
    'Zu viele Anmeldeversuche in kurzer Zeit. Bitte versuche es später erneut.',
  com_auth_error_login_ban:
    'Dein Konto wurde aufgrund von Verstößen gegen unseren Dienst vorübergehend gesperrt.',
  com_auth_error_login_server:
    'Es gab einen internen Serverfehler. Bitte warte einen Moment und versuche es erneut.',
  com_auth_error_login_unverified:
    'Dein Konto wurde nicht verifiziert. Bitte überprüfe deine E-Mail auf einen Verifizierungslink.',
  com_auth_no_account: 'Du hast noch kein Konto?',
  com_auth_sign_up: 'Registrieren',
  com_auth_sign_in: 'Anmelden',
  com_auth_google_login: 'Mit Google fortfahren',
  com_auth_facebook_login: 'Mit Facebook fortfahren',
  com_auth_github_login: 'Mit Github fortfahren',
  com_auth_discord_login: 'Mit Discord fortfahren',
  com_auth_email: 'E-Mail',
  com_auth_email_required: 'E-Mail ist erforderlich',
  com_auth_email_min_length: 'E-Mail muss mindestens 6 Zeichen lang sein',
  com_auth_email_max_length: 'E-Mail sollte nicht länger als 120 Zeichen sein',
  com_auth_email_pattern: 'Du musst eine gültige E-Mail-Adresse eingeben',
  com_auth_email_address: 'E-Mail-Adresse',
  com_auth_password: 'Passwort',
  com_auth_password_required: 'Passwort ist erforderlich',
  com_auth_password_min_length: 'Passwort muss mindestens 8 Zeichen lang sein',
  com_auth_password_max_length: 'Passwort muss weniger als 128 Zeichen lang sein',
  com_auth_password_forgot: 'Passwort vergessen?',
  com_auth_password_confirm: 'Passwort bestätigen',
  com_auth_password_not_match: 'Passwörter stimmen nicht überein',
  com_auth_continue: 'Fortfahren',
  com_auth_create_account: 'Erstelle dein Konto',
  com_auth_error_create:
    'Bei der Registrierung deines Kontos ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  com_auth_full_name: 'Vollständiger Name',
  com_auth_name_required: 'Name ist erforderlich',
  com_auth_name_min_length: 'Name muss mindestens 3 Zeichen lang sein',
  com_auth_name_max_length: 'Name darf nicht länger als 80 Zeichen sein',
  com_auth_username: 'Benutzername (optional)',
  com_auth_username_required: 'Benutzername ist erforderlich',
  com_auth_username_min_length: 'Benutzername muss mindestens 2 Zeichen lang sein',
  com_auth_username_max_length: 'Benutzername darf nicht länger als 20 Zeichen sein',
  com_auth_already_have_account: 'Hast du bereits ein Konto?',
  com_auth_login: 'Anmelden',
  com_auth_registration_success_insecure: 'Registrierung erfolgreich.',
  com_auth_registration_success_generic:
    'Bitte überprüfe deine E-Mail, um deine E-Mail-Adresse zu verifizieren.',
  com_auth_reset_password: 'Setze dein Passwort zurück',
  com_auth_click: 'Klicke',
  com_auth_here: 'HIER',
  com_auth_to_reset_your_password: 'um Ihr Passwort zurückzusetzen.',
  com_auth_reset_password_link_sent: 'E-Mail gesendet',
  com_auth_reset_password_if_email_exists:
    'Wenn ein Konto mit dieser E-Mail-Adresse existiert, wurde eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts gesendet. Bitte überprüfe auch deinen Spam-Ordner.',
  com_auth_reset_password_email_sent:
    'Wenn der Benutzer registriert ist, wird eine E-Mail an das Postfach gesendet.',
  com_auth_reset_password_success: 'Passwort erfolgreich zurückgesetzt',
  com_auth_login_with_new_password: 'Du kannst dich jetzt mit deinem neuen Passwort anmelden.',
  com_auth_error_invalid_reset_token: 'Dieser Passwort-Reset-Token ist nicht mehr gültig.',
  com_auth_click_here: 'Klicke hier',
  com_auth_to_try_again: 'um es erneut zu versuchen.',
  com_auth_submit_registration: 'Registrierung absenden',
  com_auth_welcome_back: 'Willkommen zurück',
  com_auth_back_to_login: 'Zurück zur Anmeldung',
  com_auth_email_verification_failed: 'E-Mail-Verifizierung fehlgeschlagen',
  com_auth_email_verification_rate_limited: 'Zu viele Anfragen. Bitte versuche es später erneut',
  com_auth_email_verification_success: 'E-Mail erfolgreich verifiziert',
  com_auth_email_resent_success: 'Verifizierungs-E-Mail erfolgreich erneut gesendet',
  com_auth_email_resent_failed: 'Erneutes Senden der Verifizierungs-E-Mail fehlgeschlagen',
  com_auth_email_verification_failed_token_missing: 'Verifizierung fehlgeschlagen, Token fehlt',
  com_auth_email_verification_invalid: 'Ungültige E-Mail-Verifizierung',
  com_auth_email_verification_in_progress: 'Ihre E-Mail wird verifiziert, bitte warten',
  com_auth_email_verification_resend_prompt: 'Keine E-Mail erhalten?',
  com_auth_email_resend_link: 'E-Mail erneut senden',
  com_auth_email_verification_redirecting: 'Weiterleitung in {0} Sekunden...',
  com_endpoint_open_menu: 'Menü öffnen',
  com_endpoint_bing_enable_sydney: 'Sydney aktivieren',
  com_endpoint_bing_to_enable_sydney: 'Um Sydney zu aktivieren',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing kann bis zu 7k Token für \'Kontext\' verwenden, auf die es sich im Gespräch beziehen kann. Das spezifische Limit ist nicht bekannt, kann aber bei Überschreitung von 7k Token zu Fehlern führen',
  com_endpoint_bing_system_message_placeholder:
    'WARNUNG: Missbrauch dieser Funktion kann zu einer SPERRE für die Nutzung von Bing führen! Klicke auf \'Systemnachricht\' für vollständige Anweisungen und die Standardnachricht, wenn ausgelassen, was die \'Sydney\'-Voreinstellung ist, die als sicher gilt.',
  com_endpoint_system_message: 'Systemnachricht',
  com_endpoint_message: 'Nachricht an',
  com_endpoint_message_not_appendable: 'Bearbeite deine Nachricht oder generiere neu.',
  com_endpoint_default_blank: 'Standard: leer',
  com_endpoint_default_false: 'Standard: falsch',
  com_endpoint_default_creative: 'Standard: kreativ',
  com_endpoint_default_empty: 'Standard: leer',
  com_endpoint_default_with_num: 'Standard: {0}',
  com_endpoint_context: 'Kontext',
  com_endpoint_tone_style: 'Tonstil',
  com_endpoint_token_count: 'Token-Anzahl',
  com_endpoint_output: 'Antwort',
  com_endpoint_context_tokens: 'Max. Kontext-Token',
  com_endpoint_context_info: `Die maximale Anzahl von Token, die für den Kontext verwendet werden können. Verwende dies zur Kontrolle, wie viele Token pro Anfrage gesendet werden.
  Wenn nicht angegeben, werden Systemstandards basierend auf der bekannten Kontextgröße der Modelle verwendet. Das Setzen höherer Werte kann zu Fehlern und/oder höheren Token-Kosten führen.`,
  com_endpoint_google_temp:
    'Höhere Werte = zufälliger, während niedrigere Werte = fokussierter und deterministischer. Wir empfehlen, entweder dies oder Top P zu ändern, aber nicht beides.',
  com_endpoint_google_topp:
    'Top-p ändert, wie das Modell Token für die Antwort auswählt. Token werden von den wahrscheinlichsten K (siehe topK-Parameter) bis zu den am wenigsten wahrscheinlichen ausgewählt, bis die Summe ihrer Wahrscheinlichkeiten dem Top-p-Wert entspricht.',
  com_endpoint_google_topk:
    'Top-k ändert, wie das Modell Token für die Antwort auswählt. Ein Top-k von 1 bedeutet, dass das ausgewählte Token das wahrscheinlichste unter allen Token im Vokabular des Modells ist (auch Greedy-Decoding genannt), während ein Top-k von 3 bedeutet, dass das nächste Token aus den 3 wahrscheinlichsten Token ausgewählt wird (unter Verwendung der Temperatur).',
  com_endpoint_google_maxoutputtokens:
    'Maximale Anzahl von Token, die in der Antwort generiert werden können. Gib einen niedrigeren Wert für kürzere Antworten und einen höheren Wert für längere Antworten an. Hinweis: Modelle können möglicherweise vor Erreichen dieses Maximums stoppen.',
  com_endpoint_google_custom_name_placeholder:
    'Lege einen benutzerdefinierten Namen für Google fest',
  com_endpoint_prompt_prefix_placeholder:
    'Lege benutzerdefinierte Anweisungen oder Kontext fest. Wird ignoriert, wenn leer.',
  com_endpoint_instructions_assistants_placeholder:
    'Überschreibt die Anweisungen des Assistenten. Dies ist nützlich, um das Verhalten auf Basis einzelner Ausführungen zu modifizieren.',
  com_endpoint_prompt_prefix_assistants_placeholder:
    'Lege zusätzliche Anweisungen oder Kontext zusätzlich zu den Hauptanweisungen des Assistenten fest. Wird ignoriert, wenn leer.',
  com_endpoint_custom_name: 'Benutzerdefinierter Name',
  com_endpoint_prompt_prefix: 'Benutzerdefinierte Anweisungen',
  com_endpoint_prompt_prefix_assistants: 'Zusätzliche Anweisungen',
  com_endpoint_instructions_assistants: 'Anweisungen überschreiben',
  com_endpoint_temperature: 'Temperatur',
  com_endpoint_default: 'Standard',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Max. Antwort-Token',
  com_endpoint_stop: 'Stop-Sequenzen',
  com_endpoint_plug_image_detail: 'Bild-Detail',
  com_endpoint_plug_resend_files: 'Anhänge erneut senden',
  com_endpoint_frequency_penalty: 'Frequency Penalty',
  com_endpoint_presence_penalty: 'Presence Penalty',
  com_endpoint_plug_skip_completion: 'Fertigstellung überspringen',
  com_endpoint_stop_placeholder: 'Trenne Stoppwörter durch Drücken der `Enter`-Taste',
  com_endpoint_openai_max_tokens: `Optionales \`max_tokens\`-Feld, das die maximale Anzahl von Token darstellt, die in der Chat-Vervollständigung generiert werden können.
    Die Gesamtlänge der Eingabe-Token und der generierten Token ist durch die Kontextlänge des Modells begrenzt. Du kannst Fehler erleben, wenn diese Zahl die maximalen Kontext-Token überschreitet.`,
  com_endpoint_openai_temp:
    'Entspricht der Kreativität der KI. Höhere Werte = zufälliger und kreativer, während niedrigere Werte = unkreativer und deterministischer. Wir empfehlen, entweder dies oder Top P zu ändern, aber nicht beides. Temperaturen über 1 sind nicht empfehlenswert.',
  com_endpoint_openai_max:
    'Die maximale Anzahl zu generierender Token. Die Gesamtlänge der Eingabe-Token und der generierten Token ist durch die Kontextlänge des Modells begrenzt.',
  com_endpoint_openai_topp:
    'Eine Alternative zum Sampling mit Temperatur, genannt Nucleus-Sampling, bei dem das Modell die Ergebnisse der Token mit Top-p-Wahrscheinlichkeitsmasse berücksichtigt. So bedeutet 0,1, dass nur die Token betrachtet werden, die die Top 10% der Wahrscheinlichkeitsmasse ausmachen. Wir empfehlen, entweder dies oder die Temperatur zu ändern, aber nicht beides.',
  com_endpoint_openai_freq:
    'Zahl zwischen -2,0 und 2,0. Positive Werte bestrafen neue Token basierend auf ihrer bestehenden Häufigkeit im Text, wodurch die Wahrscheinlichkeit des Modells verringert wird, dieselbe Zeile wörtlich zu wiederholen.',
  com_endpoint_openai_pres:
    'Zahl zwischen -2,0 und 2,0. Positive Werte bestrafen neue Token basierend darauf, ob sie im bisherigen Text vorkommen, wodurch die Wahrscheinlichkeit des Modells erhöht wird, über neue Themen zu sprechen.',
  com_endpoint_openai_resend:
    'Alle im Chat zuvor angehängten Bilder mit jeder neuen Nachricht erneut senden. Hinweis: Dies kann die Kosten der Anfrage  aufgrund höherer Token-Anzahl erheblich erhöhen und du kannst bei vielen Bildanhängen Fehler erleben.',
  com_endpoint_openai_resend_files:
    'Alle im Chat zuvor angehängten Dateien mit jeder neuen Nachricht erneut senden. Hinweis: Dies wird die Kosten der Anfrage aufgrund höherer Token-Anzahl erheblich erhöhen und du kannst bei vielen Anhängen Fehler erleben.',
  com_endpoint_openai_detail:
    'Die Auflösung für Bilderkennungs-Anfragen. "Niedrig" ist günstiger und schneller, "Hoch" ist detaillierter und teurer, und "Auto" wählt automatisch zwischen den beiden basierend auf der Bildauflösung.',
  com_endpoint_openai_stop: 'Bis zu 4 Sequenzen, bei denen die API keine weiteren Token generiert.',
  com_endpoint_openai_custom_name_placeholder:
    'Lege einen benutzerdefinierten Namen für die KI fest.',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Lege benutzerdefinierte Anweisungen fest, die in die Systemnachricht an die KI aufgenommen werden sollen. Standard: keine',
  com_endpoint_anthropic_temp:
    'Reicht von 0 bis 1. Verwende Temperaturen näher an 0 für analytische / Multiple-Choice-Aufgaben und näher an 1 für kreative und generative Aufgaben. Wir empfehlen, entweder dies oder Top P zu ändern, aber nicht beides.',
  com_endpoint_anthropic_topp:
    'Top-p ändert, wie das Modell Token für die Ausgabe auswählt. Token werden von den wahrscheinlichsten K (siehe topK-Parameter) bis zu den am wenigsten wahrscheinlichen ausgewählt, bis die Summe ihrer Wahrscheinlichkeiten dem Top-p-Wert entspricht.',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Lege benutzerdefinierte Anweisungen fest, die in die Systemnachricht aufgenommen werden sollen. Standard: keine',
  com_endpoint_import: 'Importieren',
  com_endpoint_set_custom_name:
    'Lege einen benutzerdefinierten Namen fest, falls du diese Voreinstellung wiederfinden möchtest',
  com_endpoint_preset_delete_confirm:
    'Bist du sicher, dass du diese Voreinstellung löschen möchtest?',
  com_endpoint_preset_clear_all_confirm:
    'Bist du sicher, dass du alle deine Voreinstellungen löschen möchtest?',
  com_endpoint_preset_import: 'Voreinstellung erfolgreich importiert!',
  com_endpoint_preset_import_error:
    'Beim Importieren deiner Voreinstellung ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  com_endpoint_preset_save_error:
    'Beim Speichern deiner Voreinstellung ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  com_endpoint_preset_delete_error:
    'Beim Löschen deiner Voreinstellung ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  com_endpoint_preset_default_removed: 'ist nicht mehr die Standardvoreinstellung.',
  com_endpoint_preset_default_item: 'Standard:',
  com_endpoint_preset_default_none: 'Keine Standardvoreinstellung aktiv.',
  com_endpoint_preset_title: 'Voreinstellung',
  com_endpoint_preset_saved: 'Gespeichert!',
  com_endpoint_preset_default: 'ist jetzt die Standardvoreinstellung.',
  com_endpoint_preset: 'Voreinstellung',
  com_endpoint_presets: 'Voreinstellungen',
  com_endpoint_preset_selected: 'Voreinstellung aktiv!',
  com_endpoint_preset_selected_title: 'Aktiv!',
  com_endpoint_preset_name: 'Voreinstellungsname',
  com_endpoint_new_topic: 'Neues Thema',
  com_endpoint: 'Endpunkt',
  com_endpoint_hide: 'Ausblenden',
  com_endpoint_show: 'Anzeigen',
  com_endpoint_examples: ' Voreinstellungen',
  com_endpoint_completion: 'Vervollständigung',
  com_endpoint_agent: 'Agent',
  com_endpoint_show_what_settings: '{0} Einstellungen anzeigen',
  com_endpoint_export: 'Exportieren',
  com_endpoint_export_share: 'Exportieren/Teilen',
  com_endpoint_assistant: 'Assistent',
  com_endpoint_use_active_assistant: 'Aktiven Assistenten verwenden',
  com_endpoint_assistant_model: 'Assistentenmodell',
  com_endpoint_save_as_preset: 'Voreinstellung speichern',
  com_endpoint_presets_clear_warning:
    'Bist du sicher, dass du alle Voreinstellungen löschen möchtest? Dies ist nicht rückgängig zu machen.',
  com_endpoint_not_implemented: 'Nicht implementiert',
  com_endpoint_no_presets:
    'Noch keine Voreinstellungen, verwende die KI-Einstellungsschaltfläche, um eine zu erstellen',
  com_endpoint_not_available: 'Kein Endpunkt verfügbar',
  com_endpoint_view_options: 'Optionen anzeigen',
  com_endpoint_save_convo_as_preset: 'Konversation als Voreinstellung speichern',
  com_endpoint_my_preset: 'Meine Voreinstellung',
  com_endpoint_agent_model: 'Agentenmodell (Empfohlen: GPT-4o-mini)',
  com_endpoint_completion_model: 'Vervollständigungsmodell (Empfohlen: GPT-4o)',
  com_endpoint_func_hover: 'Verwendung von Plugins als OpenAI-Funktionen aktivieren',
  com_endpoint_skip_hover:
    'Aktiviere das Überspringen des Vervollständigungsschritts, der die endgültige Antwort und die generierten Schritte überprüft',
  com_endpoint_config_key: 'API-Key festlegen',
  com_endpoint_assistant_placeholder:
    'Bitte wähle einen Assistenten aus dem rechten Seitenpanel aus',
  com_endpoint_config_placeholder: 'Lege deinen API-Key im Kopfzeilenmenü fest, um zu chatten.',
  com_endpoint_config_key_for: 'API-Key festlegen für',
  com_endpoint_config_key_name: 'API-Key',
  com_endpoint_config_value: 'Wert eingeben für',
  com_endpoint_config_key_name_placeholder: 'Zuerst API-Key festlegen',
  com_endpoint_config_key_encryption: 'Dein API-Key wird verschlüsselt und gelöscht um',
  com_endpoint_config_key_never_expires: 'Dein API-Key läuft nie ab',
  com_endpoint_config_key_expiry: 'die Ablaufzeit',
  com_endpoint_config_click_here: 'Klicke hier',
  com_endpoint_config_google_service_key: 'Google Service Account Key',
  com_endpoint_config_google_cloud_platform: '(von Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Google API-Key',
  com_endpoint_config_google_gemini_api: '(Gemini API)',
  com_endpoint_config_google_api_info:
    'Um deinen Generative Language API-Key (für Gemini) zu erhalten,',
  com_endpoint_config_key_import_json_key: 'Service Account JSON-Key importieren.',
  com_endpoint_config_key_import_json_key_success:
    'Service Account JSON-Key erfolgreich importiert',
  com_endpoint_config_key_import_json_key_invalid:
    'Ungültiger Service Account JSON-Key. Hast du die richtige Datei importiert?',
  com_endpoint_config_key_get_edge_key:
    'Um deinen Zugriffstoken für Bing zu erhalten, melde dich an bei',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Verwende die Entwicklertools oder eine Erweiterung, während du auf der Seite angemeldet sind, um den Inhalt des _U-Cookies zu kopieren. Wenn dies fehlschlägt, folge diesen',
  com_endpoint_config_key_edge_instructions: 'Anweisungen',
  com_endpoint_config_key_edge_full_key_string:
    'um die vollständigen Cookie-Zeichenfolgen bereitzustellen.',
  com_endpoint_config_key_chatgpt:
    'Um deinen Zugriffstoken für ChatGPT "Kostenlose Version" zu erhalten, melde dich an bei',
  com_endpoint_config_key_chatgpt_then_visit: 'dann besuche',
  com_endpoint_config_key_chatgpt_copy_token: 'Zugriffstoken kopieren.',
  com_endpoint_config_key_google_need_to: 'Du musst',
  com_endpoint_config_key_google_vertex_ai: 'Vertex AI aktivieren',
  com_endpoint_config_key_google_vertex_api: 'API auf Google Cloud, dann',
  com_endpoint_config_key_google_service_account: 'Ein Service-Konto erstellen',
  com_endpoint_config_key_google_vertex_api_role:
    'Stelle sicher, dass du auf "Erstellen und Fortfahren" klickst, um mindestens die Rolle "Vertex AI User" zu vergeben. Erstelle zuletzt einen JSON-Key zum Importieren hier.',
  com_nav_welcome_assistant: 'Bitte wähle einen Assistenten aus',
  com_nav_welcome_message: 'Wie kann ich dir heute helfen?',
  com_nav_auto_scroll: 'Automatisch zur neuesten Nachricht scrollen, wenn der Chat geöffnet wird',
  com_nav_hide_panel: 'Rechte Seitenleiste verstecken',
  com_nav_modular_chat: 'Ermöglicht das Wechseln der Endpunkte mitten im Gespräch',
  com_nav_latex_parsing: 'LaTeX in Nachrichten parsen (kann die Leistung beeinflussen)',
  com_nav_text_to_speech: 'Text zu Sprache',
  com_nav_automatic_playback: 'Automatische Wiedergabe der neuesten Nachricht',
  com_nav_speech_to_text: 'Sprache zu Text',
  com_nav_profile_picture: 'Profilbild',
  com_nav_change_picture: 'Bild ändern',
  com_nav_plugin_store: 'Plugin-Store',
  com_nav_plugin_install: 'Installieren',
  com_nav_plugin_uninstall: 'Deinstallieren',
  com_ui_add: 'Hinzufügen',
  com_nav_tool_remove: 'Entfernen',
  com_nav_tool_dialog: 'Assistenten-Werkzeuge',
  com_ui_misc: 'Sonstiges',
  com_ui_roleplay: 'Rollenspiel',
  com_ui_write: 'Schreiben',
  com_ui_idea: 'Ideen',
  com_ui_shop: 'Einkaufen',
  com_ui_finance: 'Finanzen',
  com_ui_code: 'Code',
  com_ui_travel: 'Reisen',
  com_ui_teach_or_explain: 'Lernen',
  com_ui_select_file: 'Datei auswählen',
  com_ui_drag_drop_file: 'Datei hier hinziehen und ablegen',
  com_ui_upload_image: 'Ein Bild hochladen',
  com_ui_select_a_category: 'Kategorie wählen',
  com_nav_tool_dialog_description:
    'Assistent muss gespeichert werden, um Werkzeugauswahlen zu speichern.',
  com_show_agent_settings: 'Agenteneinstellungen anzeigen',
  com_show_completion_settings: 'Vervollständigungseinstellungen anzeigen',
  com_hide_examples: 'Beispiele ausblenden',
  com_show_examples: 'Beispiele anzeigen',
  com_nav_plugin_search: 'Plugins suchen',
  com_nav_tool_search: 'Werkzeuge suchen',
  com_nav_plugin_auth_error:
    'Bei dem Versuch, dieses Plugin zu authentifizieren, ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  com_nav_export_filename: 'Dateiname',
  com_nav_export_filename_placeholder: 'Lege den Dateinamen fest',
  com_nav_export_type: 'Typ',
  com_nav_export_include_endpoint_options: 'Endpunktoptionen einbeziehen',
  com_nav_enabled: 'Aktiviert',
  com_nav_not_supported: 'Nicht unterstützt',
  com_nav_export_all_message_branches: 'Alle Nachrichtenzweige exportieren',
  com_nav_export_recursive_or_sequential: 'Rekursiv oder sequentiell?',
  com_nav_export_recursive: 'Rekursiv',
  com_nav_export_conversation: 'Konversation exportieren',
  com_nav_export: 'Exportieren',
  com_nav_shared_links: 'Geteilte Links',
  com_nav_shared_links_manage: 'Verwalten',
  com_nav_shared_links_empty: 'Du hast keine geteilten Links.',
  com_nav_shared_links_name: 'Name',
  com_nav_shared_links_date_shared: 'Datum geteilt',
  com_nav_source_chat: 'Quell-Chat anzeigen',
  com_nav_my_files: 'Meine Dateien',
  com_nav_theme: 'Design',
  com_nav_theme_system: 'System',
  com_nav_theme_dark: 'Dunkel',
  com_nav_theme_light: 'Hell',
  com_nav_font_size: 'Schriftgröße',
  com_nav_enter_to_send: 'Enter drücken, um Nachrichten zu senden',
  com_nav_user_name_display: 'Benutzernamen in Nachrichten anzeigen',
  com_nav_save_drafts: 'Entwürfe lokal speichern',
  com_nav_chat_direction: 'Chat-Richtung',
  com_nav_show_code: 'Code immer anzeigen, wenn der Code-Interpreter verwendet wird',
  com_nav_auto_send_prompts: 'Prompts automatisch senden',
  com_nav_always_make_prod: 'Neue Versionen direkt produktiv nehmen',
  com_nav_clear_all_chats: 'Alle Chats löschen',
  com_nav_confirm_clear: 'Löschen bestätigen',
  com_nav_close_sidebar: 'Seitenleiste schließen',
  com_nav_open_sidebar: 'Seitenleiste öffnen',
  com_nav_send_message: 'Nachricht senden',
  com_nav_log_out: 'Abmelden',
  com_nav_user: 'BENUTZER',
  com_nav_archived_chats: 'Archivierte Chats',
  com_nav_archived_chats_manage: 'Verwalten',
  com_nav_archived_chats_empty: 'Du hast keine archivierten Chats.',
  com_nav_archive_all_chats: 'Alle Chats archivieren',
  com_nav_archive_all: 'Alle archivieren',
  com_nav_archive_name: 'Name',
  com_nav_archive_created_at: 'Archivierungsdatum',
  com_nav_clear_conversation: 'Konversationen löschen',
  com_nav_clear_conversation_confirm_message:
    'Bist du sicher, dass du alle Konversationen löschen möchtest? Dies ist nicht rückgängig zu machen.',
  com_nav_help_faq: 'Hilfe & FAQ',
  com_nav_settings: 'Einstellungen',
  com_nav_search_placeholder: 'Nachrichten durchsuchen',
  com_nav_delete_account: 'Konto löschen',
  com_nav_delete_account_confirm: 'Konto löschen - bist du sicher?',
  com_nav_delete_account_button: 'Mein Konto dauerhaft löschen',
  com_nav_delete_account_email_placeholder: 'Bitte gebe deine Konto-E-Mail ein',
  com_nav_delete_account_confirm_placeholder:
    'Um fortzufahren, tippe "LÖSCHEN" in das Eingabefeld unten',
  com_nav_delete_warning: 'WARNUNG: Dies wird dein Konto dauerhaft löschen.',
  com_nav_delete_data_info: 'Alle deine Daten werden gelöscht.',
  com_nav_conversation_mode: 'Konversationsmodus',
  com_nav_auto_send_text: 'Text automatisch senden',
  com_nav_auto_send_text_disabled: '-1 setzen zum Deaktivieren',
  com_nav_auto_transcribe_audio: 'Audio automatisch transkribieren',
  com_nav_db_sensitivity: 'Dezibel-Empfindlichkeit',
  com_nav_playback_rate: 'Audio-Wiedergabegeschwindigkeit',
  com_nav_audio_play_error: 'Fehler beim Abspielen des Audios: {0}',
  com_nav_audio_process_error: 'Fehler bei der Verarbeitung des Audios: {0}',
  com_nav_long_audio_warning: 'Längere Texte benötigen mehr Zeit zur Verarbeitung.',
  com_nav_engine: 'Engine',
  com_nav_browser: 'Browser',
  com_nav_external: 'Extern',
  com_nav_delete_cache_storage: 'TTS-Cache-Speicher löschen',
  com_nav_enable_cache_tts: 'TTS-Caching aktivieren',
  com_nav_voice_select: 'Stimme',
  com_nav_enable_cloud_browser_voice: 'Cloud-basierte Stimmen verwenden',
  com_nav_info_enter_to_send:
    'Wenn aktiviert, sendet das Drücken von `ENTER` Ihre Nachricht. Wenn deaktiviert, fügt das Drücken von Enter eine neue Zeile hinzu, und du musst `STRG + ENTER` drücken, um deine Nachricht zu senden.',
  com_nav_info_save_draft:
    'Wenn aktiviert, werden der Text und die Anhänge, die du in das Chat-Formular eingibst, automatisch lokal als Entwürfe gespeichert. Diese Entwürfe sind auch verfügbar, wenn du die Seite neu lädst oder zu einer anderen Konversation wechseln. Entwürfe werden lokal auf deinem Gerät gespeichert und werden gelöscht, sobald die Nachricht gesendet wird.',
  com_nav_info_fork_change_default:
    '`Nur sichtbare Nachrichten` umfasst nur den direkten Pfad zur ausgewählten Nachricht. `Zugehörige Verzweigungen einbeziehen` fügt Verzweigungen entlang des Pfades hinzu. `Alle bis/von hier einbeziehen` umfasst alle verbundenen Nachrichten und Verzweigungen.',
  com_nav_info_fork_split_target_setting:
    'Wenn aktiviert, beginnt das Abzweigen von der Zielnachricht bis zur letzten Nachricht in der Konversation, gemäß dem ausgewählten Verhalten.',
  com_nav_info_user_name_display:
    'Wenn aktiviert, wird der Benutzername des Absenders über jeder Nachricht angezeigt, die du sendest. Wenn deaktiviert, siehst du nur "Du" über deinen Nachrichten.',
  com_nav_info_latex_parsing:
    'Wenn aktiviert, wird LaTeX-Code in Nachrichten als mathematische Gleichungen gerendert. Das Deaktivieren kann die Leistung verbessern, wenn du keine LaTeX-Darstellung benötigst.',
  com_nav_info_revoke:
    'Diese Aktion wird alle API-Keys, die du bereitgestellt hast, widerrufen und entfernen. Du musst diese Anmeldedaten erneut eingeben, um diese Endpunkte weiterhin zu nutzen.',
  com_nav_info_delete_cache_storage:
    'Diese Aktion löscht alle zwischengespeicherten TTS (Text-zu-Sprache) Audiodateien, die auf Ihrem Gerät gespeichert sind. Zwischengespeicherte Audiodateien werden verwendet, um die Wiedergabe von zuvor generierten TTS-Audiodateien zu beschleunigen, können aber Speicherplatz auf deinem Gerät verbrauchen.',
  com_nav_info_bookmarks_rebuild:
    'Wenn die Lesezeichenanzahl falsch ist, bitte baue die Lesezeicheninformationen neu auf. Die Lesezeichenanzahl wird neu berechnet und die Daten werden in ihren korrekten Zustand zurückversetzt.',
  com_nav_setting_general: 'Allgemein',
  com_nav_setting_chat: 'Chat',
  com_nav_setting_beta: 'Beta-Funktionen',
  com_nav_setting_data: 'Datensteuerung',
  com_nav_setting_account: 'Konto',
  com_nav_setting_speech: 'Sprache',
  com_nav_language: 'Sprache',
  com_nav_lang_auto: 'Automatisch erkennen',
  com_ui_clear_all: 'Auswahl löschen',
  com_ui_dropdown_variables: 'Dropdown-Variablen:',
  com_ui_dropdown_variables_info:
    'Erstellen Sie benutzerdefinierte Dropdown-Menüs für Ihre Eingabeaufforderungen: `{{variable_name:option1|option2|option3}}`',
  com_nav_plus_command: '+-Befehl',
  com_nav_plus_command_description:
    'Schaltet den Befehl "+" zum Hinzufügen einer Mehrfachantwort-Einstellung um',
  com_nav_slash_command: '/-Befehl',
  com_nav_slash_command_description:
    'Schaltet den Befehl "/" zur Auswahl einer Eingabeaufforderung über die Tastatur um',
  com_nav_command_settings: 'Befehlseinstellungen',
  com_nav_convo_menu_options: 'Optionen des Gesprächsmenüs',
  com_ui_artifacts: 'Artefakte',
  com_ui_artifacts_toggle: 'Artefakte-Funktion einschalten',
  com_nav_info_code_artifacts:
    'Aktiviert die Anzeige experimenteller Code-Artefakte neben dem Chat',
  com_ui_include_shadcnui: 'Anweisungen für shadcn/ui-Komponenten einschließen',
  com_nav_info_include_shadcnui:
    'Wenn aktiviert, werden Anweisungen zur Verwendung von shadcn/ui-Komponenten eingeschlossen. shadcn/ui ist eine Sammlung wiederverwendbarer Komponenten, die mit Radix UI und Tailwind CSS erstellt wurden. Hinweis: Dies sind umfangreiche Anweisungen, die Sie nur aktivieren sollten, wenn es Ihnen wichtig ist, das KI-Modell über die korrekten Importe und Komponenten zu informieren. Weitere Informationen zu diesen Komponenten finden Sie unter: https://ui.shadcn.com/',
  com_ui_custom_prompt_mode: 'Benutzerdefinierter Promptmodus für Artefakte',
  com_nav_info_custom_prompt_mode:
    'Wenn aktiviert, wird die Standard-Systemaufforderung für Artefakte nicht eingeschlossen. Alle Anweisungen zur Erzeugung von Artefakten müssen in diesem Modus manuell bereitgestellt werden.',
  com_ui_artifact_click: 'Zum Öffnen klicken',
  com_a11y_start: 'Die KI hat mit ihrer Antwort begonnen.',
  com_a11y_ai_composing: 'Die KI erstellt noch ihre Antwort.',
  com_a11y_end: 'Die KI hat ihre Antwort beendet.',
  com_nav_commands: 'Befehle',
  com_nav_commands_tab: 'Befehlseinstellungen',
  com_nav_at_command: '@-Befehl',
  com_nav_at_command_description:
    'Schaltet den Befehl "@" zum Wechseln von Endpunkten, Modellen, Voreinstellungen usw. um.',
  com_nav_command_settings_description:
    'Passen Sie an, welche Tastaturbefehle im Chat verfügbar sind',
  com_warning_resubmit_unsupported:
    'Das erneute Senden der KI-Nachricht wird für diesen Endpunkt nicht unterstützt.',
  com_error_invalid_request_error:
    'Der KI-Dienst hat die Anfrage aufgrund eines Fehlers abgelehnt. Dies könnte durch einen ungültigen API-Schlüssel oder eine falsch formatierte Anfrage verursacht worden sein.',
  com_error_input_length:
    'Die Token-Anzahl der letzten Nachricht ist zu hoch und überschreitet das Token-Limit ({0}). Bitte kürze deine Nachricht, passe die maximale Kontextgröße in den Gesprächsparametern an oder erstelle eine Abzweigung des Gesprächs, um fortzufahren.',
  com_error_files_dupe: 'Doppelte Datei erkannt.',
  com_error_no_system_messages:
    'Der ausgewählte KI-Dienst oder das Modell unterstützt keine Systemnachrichten. Verwende stattdessen Prompts anstelle von benutzerdefinierten Anweisungen.',
  com_error_files_empty: 'Leere Dateien sind nicht zulässig',
  com_error_files_validation: 'Bei der Validierung der Datei ist ein Fehler aufgetreten.',
  com_error_files_process: 'Bei der Verarbeitung der Datei ist ein Fehler aufgetreten.',
  com_error_files_upload: 'Beim Hochladen der Datei ist ein Fehler aufgetreten',
  com_generated_files: 'Generierte Dateien:',
  com_error_files_upload_canceled:
    'Die Datei-Upload-Anfrage wurde abgebrochen. Hinweis: Der Upload-Vorgang könnte noch im Hintergrund laufen und die Datei muss möglicherweise manuell gelöscht werden.',
  com_download_expired: 'Download abgelaufen',
  com_click_to_download: '(hier klicken zum Herunterladen)',
  com_download_expires: '(hier klicken zum Herunterladen - läuft ab am {0})',
  com_sidepanel_agent_builder: 'Agenten Ersteller',
  com_assistants_conversation_starters_placeholder: 'Gib einen Gesprächseinstieg ein',
  com_agents_name_placeholder: 'Optional: Der Name des Agenten',
  com_assistants_conversation_starters: 'Gesprächseinstiege',
  com_agents_description_placeholder: 'Optional: Beschreibe hier deinen Agenten',
  com_agents_search_name: 'Agenten nach Namen suchen',
  com_agents_instructions_placeholder: 'Die Systemanweisungen, die der Agent verwendet',
  com_agents_create_error: 'Bei der Erstellung deines Agenten ist ein Fehler aufgetreten.',
  com_agents_update_error: 'Beim Aktualisieren deines Agenten ist ein Fehler aufgetreten.',
  com_agents_missing_provider_model:
    'Bitte wählen Sie einen Anbieter und ein Modell aus, bevor Sie einen Agenten erstellen.',
  com_agents_allow_editing: 'Anderen Nutzenden die Bearbeitung deines Agenten erlauben',
  com_agents_not_available: 'Agent nicht verfügbar',
  com_agents_no_access: 'Du hast keine Berechtigung, diesen Agenten zu bearbeiten.',
  com_agents_enable_file_search: 'Dateisuche aktivieren',
  com_agents_file_search_info:
    'Wenn aktiviert, wird der Agent über die unten aufgelisteten exakten Dateinamen informiert und kann dadurch relevante Informationen aus diesen Dateien abrufen',
  com_agents_file_search_disabled:
    'Der Agent muss erstellt werden, bevor Dateien für die Dateisuche hochgeladen werden können.',
  com_ui_agent_editing_allowed: 'Andere Nutzende können diesen Agenten bereits bearbeiten',
  com_ui_no_changes: 'Keine Änderungen zum Aktualisieren',
  com_ui_agent_already_shared_to_all: 'Dieser Assistent ist bereits für alle Nutzenden freigegeben',
  com_ui_endpoint: 'Endpunkt',
  com_ui_region: 'Region',
  com_ui_model_parameters: 'Modell-Parameter',
  com_agents_execute_code: 'Code ausführen',
  com_ui_provider: 'Anbieter',
  com_ui_model_save_success: 'Modellparameter erfolgreich gespeichert',
  com_ui_select_region: 'Wähle eine Region',
  com_ui_select_search_region: 'Region nach Name suchen',
  com_ui_select_provider_first: 'Wähle zuerst einen Anbieter',
  com_ui_select_provider: 'Wähle einen Anbieter',
  com_ui_revoke_keys: 'Schlüssel widerrufen',
  com_assistants_max_starters_reached: 'Maximale Anzahl an Gesprächseinstiegen erreicht',
  com_ui_revoke_keys_confirm: 'Bist du sicher, dass du alle Schlüssel widerrufen möchtest?',
  com_ui_revoke_key_confirm: 'Bist du sicher, dass du diesen Schlüssel widerrufen möchtest?',
  com_ui_revoke_key_endpoint: 'API-Schlüssel für {0} widerrufen',
  com_ui_chat_history: 'Chatverlauf',
  com_ui_controls: 'Steuerung',
  com_ui_agent: 'Agent',
  com_ui_agent_deleted: 'Assistent erfolgreich gelöscht',
  com_ui_agent_delete_error: 'Beim Löschen des Assistenten ist ein Fehler aufgetreten',
  com_ui_agents: 'Agenten',
  com_ui_delete_agent_confirm: 'Bist du sicher, dass du diesen Agenten löschen möchtest?',
  com_ui_bookmarks_create_exists: 'Dieses Lesezeichen existiert bereits',
  com_ui_bookmarks_delete: 'Lesezeichen löschen',
  com_ui_no_bookmarks:
    'Du hast noch keine Lesezeichen. Klicke auf einen Chat und füge ein neues hinzu',
  com_ui_enter_var: '{0} eingeben',
  com_ui_no_conversation_id: 'Keine Chat-ID gefunden',
  com_endpoint_anthropic_maxoutputtokens:
    'Maximale Anzahl von Token, die in der Antwort erzeugt werden können. Gib einen niedrigeren Wert für kürzere Antworten und einen höheren Wert für längere Antworten an. Hinweis: Die Modelle können auch vor Erreichen dieses Maximums stoppen.',
  com_endpoint_prompt_cache: 'Prompt-Zwischenspeicherung verwenden',
  com_endpoint_anthropic_topk:
    'Top-k ändert, wie das Modell Token für die Ausgabe auswählt. Ein Top-k von 1 bedeutet, dass das ausgewählte Token das wahrscheinlichste unter allen Token im Vokabular des Modells ist (auch "Greedy Decoding" genannt), während ein Top-k von 3 bedeutet, dass das nächste Token aus den 3 wahrscheinlichsten Token ausgewählt wird (unter Verwendung der Temperatur).',
  com_endpoint_anthropic_custom_name_placeholder:
    'Lege einen benutzerdefinierten Namen für Anthropic fest',
  com_endpoint_anthropic_prompt_cache:
    'Prompt-Caching ermöglicht die Wiederverwendung von umfangreichen Kontexten oder Anweisungen über mehrere API-Aufrufe hinweg, wodurch Kosten und Latenzzeiten reduziert werden',
  com_endpoint_plug_use_functions: 'Funktionen verwenden',
  com_ui_add_multi_conversation: 'Mehrere Chats hinzufügen',
  com_endpoint_plug_resend_images: 'Bilder erneut senden',
  com_endpoint_disabled_with_tools_placeholder: 'Deaktiviert mit ausgewählten Werkzeugen',
  com_endpoint_disabled_with_tools: 'Mit Werkzeugen deaktiviert',
  com_endpoint_search: 'Endpunkt nach Namen suchen',
  com_nav_font_size_xs: 'Sehr klein',
  com_nav_font_size_sm: 'Klein',
  com_nav_account_settings: 'Kontoeinstellungen',
  com_nav_font_size_base: 'Mittel',
  com_nav_font_size_lg: 'Groß',
  com_nav_font_size_xl: 'Sehr groß',
  com_nav_user_msg_markdown: 'Benutzernachrichten als Markdown darstellen',
  com_nav_tool_dialog_agents: 'Agent-Tools',
  com_nav_clear_cache_confirm_message: 'Bist du sicher, dass du den Cache löschen möchtest?',
  com_nav_tts_init_error: 'Text-zu-Sprache-Initialisierung fehlgeschlagen: {0}',
  com_nav_stop_generating: 'Generierung stoppen',
  com_nav_tts_unsupported_error:
    'Die Text-zu-Sprache-Funktion für die ausgewählte Engine wird in diesem Browser nicht unterstützt',
  com_nav_source_buffer_error:
    'Fehler beim Einrichten der Audiowiedergabe. Bitte lade die Seite neu.',
  com_nav_buffer_append_error:
    'Problem beim Audio-Streaming. Die Wiedergabe könnte unterbrochen werden.',
  com_nav_speech_cancel_error:
    'Die Audiowiedergabe konnte nicht gestoppt werden. Bitte lade die Seite neu.',
  com_nav_media_source_init_error:
    'Der Audio-Player konnte nicht initialisiert werden. Bitte überprüfe deine Browser-Einstellungen.',
  com_nav_voices_fetch_error:
    'Die Sprachoptionen konnten nicht abgerufen werden. Bitte überprüfe deine Internetverbindung.',
  com_nav_edge: 'Edge',
  com_nav_no_search_results: 'Keine Suchergebnisse gefunden',
  com_nav_chat_commands: 'Chat-Befehle',
  com_nav_chat_commands_info:
    'Diese Befehle werden aktiviert, indem du bestimmte Zeichen am Anfang deiner Nachricht eingibst. Jeder Befehl wird durch sein festgelegtes Präfix ausgelöst. Du kannst sie deaktivieren, wenn du diese Zeichen häufig zum Beginn deiner Nachrichten verwendest.',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_polish: 'Polski',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
  com_nav_lang_russian: 'Русский',
  com_nav_lang_japanese: '日本語',
  com_nav_lang_swedish: 'Svenska',
  com_nav_lang_korean: '한국어',
  com_nav_lang_vietnamese: 'Tiếng Việt',
  com_nav_lang_traditionalchinese: '繁體中文',
  com_nav_lang_arabic: 'العربية',
  com_nav_lang_turkish: 'Türkçe',
  com_nav_lang_dutch: 'Nederlands',
  com_nav_lang_indonesia: 'Indonesia',
  com_nav_lang_hebrew: 'עברית',
  com_nav_lang_finnish: 'Suomi',
};
