export default function CodePaths({ fill }) {
  const gradientId = 'artifactGradient';

  return (
    <>
      {fill === 'gradient' && (
        <defs>
          <linearGradient id={gradientId} gradientTransform="rotate(91.969)">
            <stop offset="0%" stopColor="#F214A1" />
            <stop offset="100%" stopColor="#BC0278" />
          </linearGradient>
        </defs>
      )}
      <path
        d="M21.333 23L26.333 18L21.333 13"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.667 13L9.66699 18L14.667 23"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}
