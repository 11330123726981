// Chinese phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_nav_convo_menu_options: '对话菜单选项',
  com_ui_artifacts: 'Artifacts',
  com_ui_artifacts_toggle: '切换至 Artifacts UI',
  com_nav_info_code_artifacts: '启用在对话旁显示的实验性代码工件',
  com_ui_include_shadcnui: '包含 shadcn/ui 组件指令',
  com_nav_info_include_shadcnui:
    '启用后，将包含使用 shadcn/ui 组件的指令。shadcn/ui 是一组使用 Radix UI 和 Tailwind CSS 构建的可重复使用的组件。注意：这些指令较长，仅在您需要向 LLM 提供正确的导入和组件信息时才应启用。有关这些组件的更多信息，请访问：https://ui.shadcn.com/',
  com_ui_custom_prompt_mode: '自定义提示词模式',
  com_nav_info_custom_prompt_mode:
    '启用后，默认的工件系统提示将不会包含在内。在此模式下，必须手动提供所有生成工件的指令。',
  com_ui_artifact_click: '点击以打开',
  com_a11y_start: 'AI 已开始回复。',
  com_a11y_ai_composing: 'AI 仍在撰写中。',
  com_a11y_end: 'AI 已完成回复。',
  com_error_moderation:
    '很抱歉，您提交的内容被我们的审核系统标记为不符合社区指引。我们无法就此特定主题继续交流。如果您有任何其他问题或想探讨的话题，请编辑您的消息或开启新的对话。',
  com_error_no_user_key: '没有找到密钥。请提供密钥后重试。',
  com_error_no_base_url: '未找到基础 URL，请提供一个后重试。',
  com_error_invalid_user_key: '提供的密钥无效。请提供有效的密钥后重试。',
  com_error_expired_user_key: '您提供的 {0} 密钥已于 {1} 过期。请提供新的密钥并重试。',
  com_error_input_length:
    '最新消息的令牌数过长，超出了令牌限制（分别为 {0}）。请缩短您的消息、调整对话参数中的最大上下文大小，或分叉对话以继续。',
  com_files_no_results: '无结果。',
  com_files_filter: '筛选文件...',
  com_files_number_selected: '已选择 {0} 个文件（共 {1} 个文件）',
  com_sidepanel_select_assistant: '选择助手',
  com_sidepanel_parameters: '参数',
  com_sidepanel_assistant_builder: '助手生成器',
  com_sidepanel_hide_panel: '隐藏侧边栏',
  com_sidepanel_attach_files: '附加文件',
  com_sidepanel_manage_files: '管理文件',
  com_sidepanel_conversation_tags: '书签',
  com_assistants_capabilities: '功能',
  com_assistants_file_search: '文件搜索',
  com_assistants_file_search_info:
    '暂不支持为文件搜索附加向量存储。您可以从提供程序游乐场附加它们，或者在线程基础上为文件搜索附加文件。',
  com_assistants_code_interpreter_info:
    '代码解释器使助手能够编写和运行代码。该工具可以处理具有多种数据和格式的文件，并生成图表等文件。',
  com_assistants_knowledge: '知识',
  com_assistants_knowledge_info: '如果您在 “知识” 中上传文件，与助手的对话可能包括文件内容。',
  com_assistants_knowledge_disabled:
    '必须创建助手，且启用并保存代码解释器或检索，才能将文件作为知识上传。',
  com_assistants_image_vision: '识图',
  com_assistants_code_interpreter: '代码解释器',
  com_assistants_code_interpreter_files: '以下文件仅适用于代码解释器：',
  com_assistants_retrieval: '检索',
  com_assistants_search_name: '根据名称搜索助手',
  com_ui_tools: '工具',
  com_assistants_actions: '操作',
  com_assistants_add_tools: '添加工具',
  com_assistants_add_actions: '添加操作',
  com_assistants_non_retrieval_model: '此模型未启用文件搜索功能。请选择其他模型。',
  com_assistants_available_actions: '可用操作',
  com_assistants_running_action: '正在运行操作',
  com_assistants_completed_action: '与 {0} 聊天',
  com_assistants_completed_function: '运行 {0}',
  com_assistants_function_use: '助手使用了 {0}',
  com_assistants_domain_info: '助手将此信息发送到了 {0}',
  com_assistants_delete_actions_success: '已成功从助手删除操作',
  com_assistants_update_actions_success: '已成功创建或更新操作',
  com_assistants_update_actions_error: '创建或更新操作时出现错误。',
  com_assistants_delete_actions_error: '删除操作时出现错误。',
  com_assistants_actions_info: '让您的助手通过 API 检索信息或执行操作',
  com_assistants_name_placeholder: '（选填）助手的名称',
  com_assistants_instructions_placeholder: '助手使用的系统指令',
  com_assistants_description_placeholder: '（选填）在此处描述您的助手',
  com_assistants_actions_disabled: '您需要先创建助手，然后才能添加操作。',
  com_assistants_update_success: '更新成功',
  com_assistants_update_error: '更新助手时出现错误。',
  com_assistants_create_success: '已成功创建',
  com_assistants_create_error: '创建助手时出现错误。',
  com_assistants_conversation_starters: '对话启动器',
  com_assistants_conversation_starters_placeholder: '输入对话启动器',
  com_sidepanel_agent_builder: '代理构建器',
  com_agents_name_placeholder: '可选：代理名称',
  com_agents_description_placeholder: '可选：在此描述您的代理',
  com_agents_instructions_placeholder: '代理使用的系统指令',
  com_agents_search_name: '根据名称搜索代理',
  com_agents_update_error: '更新代理时出现错误。',
  com_agents_create_error: '更新代理时出现错误。',
  com_ui_date_today: '今天',
  com_ui_date_yesterday: '昨天',
  com_ui_date_previous_7_days: '过去 7 天',
  com_ui_date_previous_30_days: '过去 30 天',
  com_ui_date_january: '一月',
  com_ui_date_february: '二月',
  com_ui_date_march: '三月',
  com_ui_date_april: '四月',
  com_ui_date_may: '五月',
  com_ui_date_june: '六月',
  com_ui_date_july: '七月',
  com_ui_date_august: '八月',
  com_ui_date_september: '九月',
  com_ui_date_october: '十月',
  com_ui_date_november: '十一月',
  com_ui_date_december: '十二月',
  com_ui_field_required: '此字段为必填项',
  com_ui_download_error: '下载文件时出现错误，该文件可能已被删除。',
  com_ui_attach_error_type: '渠道不支持的文件类型：',
  com_ui_attach_error_openai: '无法将助手文件附加到其他渠道',
  com_ui_attach_warn_endpoint: '不兼容的工具可能会忽略非助手文件',
  com_ui_attach_error_size: '超出渠道规定的文件大小：',
  com_ui_attach_error: '无法附加文件，请创建或选择一个对话，或尝试刷新页面。',
  com_ui_examples: '示例',
  com_ui_new_chat: '创建新对话',
  com_ui_happy_birthday: '这是我的第一个生日！',
  com_ui_experimental: '实验性',
  com_ui_on: '开启',
  com_ui_off: '关闭',
  com_ui_yes: '是的',
  com_ui_no: '否',
  com_ui_ascending: '升序',
  com_ui_descending: '降序',
  com_ui_show_all: '展开全部',
  com_ui_name: '名称',
  com_ui_date: '日期',
  com_ui_storage: '存储',
  com_ui_context: '上下文',
  com_ui_size: '大小',
  com_ui_host: '主机',
  com_ui_update: '更新',
  com_ui_authentication: '认证',
  com_ui_instructions: '指令',
  com_ui_description: '描述',
  com_ui_error: '错误',
  com_ui_error_connection: '连接服务器出现错误，请尝试刷新页面。',
  com_ui_select: '选择',
  com_ui_input: '输入',
  com_ui_close: '关闭',
  com_ui_endpoint: '渠道',
  com_ui_provider: '提供商',
  com_ui_model: '模型',
  com_ui_model_parameters: '模型参数',
  com_ui_model_save_success: '已成功保存模型参数',
  com_ui_select_model: '模型选择',
  com_ui_select_provider: '选择提供商',
  com_ui_select_provider_first: '首先选择提供商',
  com_ui_select_search_model: '根据名称搜索模型',
  com_ui_select_search_plugin: '根据名称搜索插件',
  com_ui_use_prompt: '使用提示词',
  com_ui_prev: '上一页',
  com_ui_next: '下一页',
  com_ui_stop: '停止',
  com_ui_upload_files: '上传文件',
  com_ui_prompt: '提示词',
  com_ui_prompts: '提示词',
  com_ui_prompt_name: '提示词名称',
  com_ui_delete_prompt: '删除提示词？',
  com_ui_admin: '管理',
  com_ui_simple: '基本',
  com_ui_versions: '版本',
  com_ui_version_var: '版本 {0}',
  com_ui_advanced: '进阶',
  com_ui_admin_settings: '管理员设置',
  com_ui_error_save_admin_settings: '保存您的管理员设置时出现错误。',
  com_ui_prompt_preview_not_shared: '作者未允许对此提示词进行协作。',
  com_ui_prompt_name_required: '提示词名称为必填项',
  com_ui_prompt_text_required: '文本为必填项',
  com_ui_prompt_text: '文本',
  com_ui_back_to_chat: '返回对话',
  com_ui_back_to_prompts: '返回提示词',
  com_ui_categories: '类别',
  com_ui_filter_prompts_name: '根据名称筛选提示词',
  com_ui_search_categories: '搜索类别',
  com_ui_manage: '管理',
  com_ui_variables: '变量',
  com_ui_variables_info:
    '在您的文本中使用双大括号创建变量，例如 `{{example variable}}`，以便在使用提示词时填充。',
  com_ui_special_variables: '特殊变量：',
  com_ui_special_variables_info:
    '使用 `{{current_date}}` 获取当前日期，使用 `{{current_user}}` 获取您的账户名称。',
  com_ui_dropdown_variables: '下拉变量：',
  com_ui_dropdown_variables_info:
    '为您的提示词创建自定义下拉菜单：`{{variable_name:option1|option2|option3}}`',
  com_ui_showing: '显示',
  com_ui_of: '/',
  com_ui_entries: '项',
  com_ui_pay_per_call: '所有对话都将集中在一处。按会话次数计费，而不是按月付费',
  com_ui_new_footer: '所有对话都将集中在一处。',
  com_ui_latest_footer: 'Every AI for Everyone.',
  com_ui_enter: '进入',
  com_ui_submit: '提交',
  com_ui_none_selected: '未选择任何项目',
  com_ui_upload_success: '上传文件成功',
  com_ui_upload_error: '上传文件错误',
  com_ui_upload_invalid: '上传的文件无效。必须是图像，且不得超过大小限制',
  com_ui_upload_invalid_var: '上传的文件无效。必须是图像，且不得超过 {0} MB。',
  com_ui_cancel: '取消',
  com_ui_save: '保存',
  com_ui_renaming_var: '重命名 “{0}”',
  com_ui_save_submit: '保存并提交',
  com_user_message: '您',
  com_ui_read_aloud: '大声朗读',
  com_ui_copied: '已复制！',
  com_ui_copy_code: '复制代码',
  com_ui_copy_to_clipboard: '复制到剪贴板',
  com_ui_copied_to_clipboard: '已复制到剪贴板',
  com_ui_fork: '分叉',
  com_ui_fork_info_1: '使用此设置可以分叉消息，以获得所需的行为。',
  com_ui_fork_info_2:
    '“分叉” 是指从当前对话中选择特定消息作为起点/终点，根据所选选项创建一个新的对话副本。',
  com_ui_fork_info_3:
    '“目标消息” 是指此弹出窗口所打开的消息，或者如果您选中 “{0}”，则是对话中最新的消息。',
  com_ui_fork_info_visible:
    '此选项仅分叉可见的消息；换句话说，是直接路径到目标消息，没有任何分支。',
  com_ui_fork_info_branches:
    '此选项会分叉可见消息及相关分支；换句话说，包括沿路径的直接路线到目标消息，以及路径上的分支。',
  com_ui_fork_info_target:
    '此选项会分叉所有导向目标消息的消息分支，包括其相邻消息；换句话说，无论是否可见或在同一路径上，所有消息分支都会被包含在内。',
  com_ui_fork_info_start:
    '如果勾选，则根据上述选择的行为，从此消息开始到对话中最新的消息将被分叉。',
  com_ui_fork_info_remember: '选中此项可记住您的选择，以便下次分叉对话时更快捷地使用您偏好的选项。',
  com_ui_fork_success: '对话分叉成功',
  com_ui_fork_processing: '正在分叉对话...',
  com_ui_fork_error: '分叉对话时出现错误',
  com_ui_fork_change_default: '默认分叉选项',
  com_ui_fork_default: '使用默认分叉选项',
  com_ui_fork_remember: '记住',
  com_ui_fork_split_target_setting: '默认以目标消息开始分叉',
  com_ui_fork_split_target: '在此分叉',
  com_ui_fork_remember_checked: '您的选择将在使用后被记住。您可以随时在设置中更改。',
  com_ui_fork_all_target: '包含所有目标',
  com_ui_fork_branches: '包含相关分支',
  com_ui_fork_visible: '仅可见消息',
  com_ui_fork_from_message: '选择分叉选项',
  com_ui_mention: '提及一个端点、助手或预设以快速切换到它',
  com_ui_add_model_preset: '添加一个模型或预设以获得额外的回复',
  com_assistants_max_starters_reached: '已达到对话启动器的最大数量',
  com_ui_regenerate: '重新生成',
  com_ui_continue: '继续',
  com_ui_edit: '编辑',
  com_ui_loading: '加载中...',
  com_ui_success: '成功',
  com_ui_all: '所有',
  com_ui_all_proper: '所有',
  com_ui_clear: '清除',
  com_ui_revoke: '撤销',
  com_ui_revoke_info: '撤销所有用户提供的凭据',
  com_ui_import_conversation: '导入',
  com_ui_nothing_found: '未找到任何内容',
  com_ui_go_to_conversation: '转到对话',
  com_ui_import_conversation_info: '从 JSON 文件导入对话',
  com_ui_import_conversation_success: '对话导入成功',
  com_ui_import_conversation_error: '导入对话时发生错误',
  com_ui_import_conversation_file_type_error: '不支持的导入类型',
  com_ui_confirm_action: '确认执行',
  com_ui_chat: '对话',
  com_ui_chat_history: '对话历史',
  com_ui_controls: '管理',
  com_ui_dashboard: '仪表板',
  com_ui_chats: '聊天',
  com_ui_avatar: '头像',
  com_ui_unknown: '未知',
  com_ui_result: '结果',
  com_ui_image_gen: '图片生成',
  com_ui_assistant: '助手',
  com_ui_assistant_deleted: '助手已成功删除',
  com_ui_assistant_delete_error: '删除助手时出现错误',
  com_ui_assistants: '助手',
  com_ui_attachment: '附件',
  com_ui_assistants_output: '助手输出',
  com_ui_agent: '代理',
  com_ui_agent_deleted: '代理已成功删除',
  com_ui_agent_delete_error: '删除代理时出现错误',
  com_ui_agents: '代理',
  com_ui_delete_agent_confirm: '您确定要删除此代理吗？',
  com_ui_delete: '删除',
  com_ui_create: '创建',
  com_ui_create_prompt: '创建提示词',
  com_ui_share: '共享',
  com_ui_share_var: '共享 {0}',
  com_ui_enter_var: '输入 {0}',
  com_ui_copy_link: '复制链接',
  com_ui_update_link: '更新链接',
  com_ui_create_link: '创建链接',
  com_ui_share_to_all_users: '共享给所有用户',
  com_ui_my_prompts: '我的提示词',
  com_ui_no_category: '无类别',
  com_ui_shared_prompts: '共享提示词',
  com_ui_prompts_allow_use: '允许使用提示词',
  com_ui_prompts_allow_create: '允许创建提示词',
  com_ui_prompts_allow_share_global: '允许向所有用户共享提示词',
  com_ui_prompt_shared_to_all: '此提示词已共享给所有用户',
  com_ui_prompt_update_error: '更新提示词时出现错误',
  com_ui_prompt_already_shared_to_all: '此提示词已共享给所有用户',
  com_ui_description_placeholder: '可选：输入要显示的提示词描述',
  com_ui_command_placeholder: '可选：输入提示词的命令，否则将使用名称',
  com_ui_command_usage_placeholder: '通过命令或名称选择提示词',
  com_ui_no_prompt_description: '未找到描述。',
  com_ui_share_link_to_chat: '共享链接到聊天',
  com_ui_share_error: '共享聊天链接时发生错误',
  com_ui_share_retrieve_error: '检索共享链接时出现错误',
  com_ui_share_delete_error: '删除共享链接时出现错误',
  com_ui_share_create_message: '您的姓名以及您在共享后添加的任何消息将保持私密。',
  com_ui_share_created_message: '已创建到您聊天的共享链接。可以通过设置随时管理以前共享的聊天。',
  com_ui_share_update_message: '您的姓名、自定义指令以及您在共享后添加的任何消息将保持私密。',
  com_ui_share_updated_message: '已更新到您聊天的共享链接。可以通过设置随时管理以前共享的聊天。',
  com_ui_shared_link_not_found: '未找到共享链接',
  com_ui_delete_conversation: '删除对话？',
  com_ui_delete_confirm: '这将删除',
  com_ui_delete_tool: '删除工具',
  com_ui_delete_tool_confirm: '您确定要删除此工具吗？',
  com_ui_delete_action: '删除操作',
  com_ui_delete_action_confirm: '您确定要删除此操作吗？',
  com_ui_delete_confirm_prompt_version_var:
    '这将删除选中版本的 “{0}”。如果没有其他版本，该提示词将被删除。',
  com_ui_delete_assistant_confirm: '您确定要删除此助手吗？该操作无法撤销。',
  com_ui_rename: '重命名',
  com_ui_archive: '归档',
  com_ui_archive_error: '归档对话失败',
  com_ui_unarchive: '取消归档',
  com_ui_unarchive_error: '取消归档对话失败',
  com_ui_more_options: '更多',
  com_ui_preview: '预览',
  com_ui_upload: '上传',
  com_ui_connect: '连接',
  com_ui_locked: '已锁定',
  com_ui_upload_delay: '上传 “{0}” 时比预期花了更长时间。文件正在进行检索索引，请稍候。',
  com_ui_privacy_policy: '隐私政策',
  com_ui_terms_of_service: '服务政策',
  com_ui_use_micrphone: '使用麦克风',
  com_ui_min_tags: '无法再移除更多值，至少需要保留 {0} 个。',
  com_ui_max_tags: '最多允许 {0} 个，用最新值。',
  com_ui_bookmarks: '书签',
  com_ui_bookmarks_new: '新书签',
  com_ui_bookmark_delete_confirm: '您确定要删除此书签吗？',
  com_ui_bookmarks_title: '标题',
  com_ui_bookmarks_count: '计数',
  com_ui_bookmarks_description: '描述',
  com_ui_bookmarks_create_success: '书签创建成功',
  com_ui_bookmarks_update_success: '书签更新成功',
  com_ui_bookmarks_delete_success: '书签删除成功',
  com_ui_bookmarks_create_exists: '书签已存在',
  com_ui_bookmarks_create_error: '创建书签时出现错误',
  com_ui_bookmarks_update_error: '更新书签时出现错误',
  com_ui_bookmarks_delete_error: '删除书签时出现错误',
  com_ui_bookmarks_add_to_conversation: '添加到当前对话',
  com_ui_bookmarks_filter: '筛选书签...',
  com_ui_no_bookmarks: '似乎您还没有书签。点击一个对话并添加一个新的书签',
  com_ui_no_conversation_id: '未找到对话 ID',
  com_auth_error_login: '无法登录，请确认提供的账户密码正确，并重新尝试。',
  com_auth_error_login_rl: '尝试登录次数过多，请稍后再试。',
  com_auth_error_login_ban: '根据我们的服务规则，您的帐号被暂时禁用。',
  com_auth_error_login_server: '内部服务器错误，请稍后再试。',
  com_auth_error_login_unverified: '您的账户尚未验证。请检查您的邮件以获取验证链接。',
  com_auth_no_account: '还没有账户？',
  com_auth_sign_up: '注册',
  com_auth_sign_in: '登录',
  com_auth_google_login: '使用 Google 登录',
  com_auth_facebook_login: '使用 Facebook 登录',
  com_auth_github_login: '使用 GitHub 登录',
  com_auth_discord_login: '使用 Discord 登录',
  com_auth_email: '邮箱',
  com_auth_email_required: '邮箱为必填项',
  com_auth_email_min_length: '邮箱地址至少 6 个字符',
  com_auth_email_max_length: '邮箱地址最多 120 个字符',
  com_auth_email_pattern: '请输入正确的邮箱地址',
  com_auth_email_address: '邮箱',
  com_auth_password: '密码',
  com_auth_password_required: '密码为必填项',
  com_auth_password_min_length: '密码至少 8 个字符',
  com_auth_password_max_length: '密码最多 128 个字符',
  com_auth_password_forgot: '忘记密码？',
  com_auth_password_confirm: '确认密码',
  com_auth_password_not_match: '密码不一致',
  com_auth_continue: '继续',
  com_auth_create_account: '创建账户',
  com_auth_error_create: '注册账户过程中出现错误，请重试。',
  com_auth_full_name: '姓名',
  com_auth_name_required: '姓名为必填项',
  com_auth_name_min_length: '姓名至少 3 个字符',
  com_auth_name_max_length: '姓名最多 80 个字符',
  com_auth_username: '用户名（可选）',
  com_auth_username_required: '用户名为必填项',
  com_auth_username_min_length: '用户名至少 2 个字符',
  com_auth_username_max_length: '用户名最多 20 个字符',
  com_auth_already_have_account: '已有账户？',
  com_auth_login: '登录',
  com_auth_registration_success_insecure: '注册成功。',
  com_auth_registration_success_generic: '请检查您的邮件以验证您的邮件地址。',
  com_auth_reset_password: '重置密码',
  com_auth_click: '点击',
  com_auth_here: '这里',
  com_auth_to_reset_your_password: '重置密码。',
  com_auth_reset_password_link_sent: '密码重置链接已发送',
  com_auth_reset_password_if_email_exists:
    '如果存在使用该邮件地址的帐户，则会发送一封包含密码重置说明的邮件。请务必检查您的垃圾邮件文件夹。',
  com_auth_reset_password_email_sent: '如果用户已注册，将会发送一封邮件到收件箱。',
  com_auth_reset_password_success: '密码重置成功',
  com_auth_login_with_new_password: '现在你可以使用你的新密码登录。',
  com_auth_error_invalid_reset_token: '重置密码的密钥已失效。',
  com_auth_click_here: '点击这里',
  com_auth_to_try_again: '再试一次。',
  com_auth_submit_registration: '注册提交',
  com_auth_welcome_back: '欢迎',
  com_auth_back_to_login: '返回登录',
  com_auth_email_verification_failed: '邮箱验证失败',
  com_auth_email_verification_rate_limited: '请求过多。请稍后再试',
  com_auth_email_verification_success: '邮箱验证成功',
  com_auth_email_resent_success: '验证邮件重新发送成功',
  com_auth_email_resent_failed: '验证邮件重新发送失败',
  com_auth_email_verification_failed_token_missing: '验证失败，令牌失效',
  com_auth_email_verification_invalid: '无效的邮箱验证',
  com_auth_email_verification_in_progress: '正在验证您的邮箱，请稍候',
  com_auth_email_verification_resend_prompt: '未收到邮件？',
  com_auth_email_resend_link: '重新发送邮件',
  com_auth_email_verification_redirecting: '在 {0} 秒后重定向...',
  com_endpoint_open_menu: '打开菜单',
  com_endpoint_bing_enable_sydney: '启用 Sydney',
  com_endpoint_bing_to_enable_sydney: '启用 Sydney',
  com_endpoint_bing_jailbreak: '越狱',
  com_endpoint_bing_context_placeholder:
    '必应可以使用多达 7000 个词元作为 “上下文（context）”，参照这些内容进行对话。其具体限制并不清楚，但可能会在超过 7000 个词元时出现错误',
  com_endpoint_bing_system_message_placeholder:
    '警告：滥用此功能可能导致你被禁止使用必应！点击 “系统消息” 查看完整的使用指南，如果你忽略了默认消息，那么将会使用被视为安全的 “Sydney” 预设。',
  com_endpoint_system_message: '系统消息',
  com_endpoint_message: '发送消息给',
  com_endpoint_message_not_appendable: '编辑您的消息内容或重新生成。',
  com_endpoint_default_blank: '初始值：空白',
  com_endpoint_default_false: '初始值：否',
  com_endpoint_default_creative: '初始值：创意',
  com_endpoint_default_empty: '初始值：空',
  com_endpoint_default_with_num: '初始值：{0}',
  com_endpoint_context: '上下文',
  com_endpoint_tone_style: '语气',
  com_endpoint_token_count: '词元数',
  com_endpoint_output: '输出',
  com_endpoint_context_tokens: '最大上下文词元数',
  com_endpoint_context_info: `可用于上下文的最大词元数。用于控制每个请求发送的词元数量。
  如果未指定，将根据已知模型的上下文大小使用系统默认值。设置较高的值可能会导致错误和/或更高的词元成本。`,
  com_endpoint_google_temp:
    '值越高表示输出越随机，值越低表示输出越确定。建议不要同时改变此值和 Top-p。',
  com_endpoint_google_topp:
    'top-p（核采样）会改变模型选择输出词的方式。从概率最大的 K（参见topK参数）向最小的 K 选择，直到它们的概率之和等于 top-p 值。',
  com_endpoint_google_topk:
    'top-k 会改变模型选择输出词的方式。top-k 为 1 意味着所选词是模型词汇中概率最大的（也称为贪心解码），而 top-k 为 3 意味着下一个词是从 3 个概率最大的词中选出的（使用随机性）。',
  com_endpoint_google_maxoutputtokens:
    '响应中可以生成的最大令牌数。指定较低的值以获得较短的响应，指定较高的值以获得较长的响应。注意：模型可能会在达到此最大值之前停止。',
  com_endpoint_google_custom_name_placeholder: '为 Google 设置一个名称',
  com_endpoint_prompt_prefix_placeholder: '自定义指令和上下文，默认为空。',
  com_endpoint_instructions_assistants_placeholder:
    '覆盖助手的指令。这对于需要逐次修改行为非常有用。',
  com_endpoint_prompt_prefix_assistants_placeholder:
    '在助手的主要指令之上设置额外的指令或上下文。如果为空，则忽略。',
  com_endpoint_custom_name: '自定义名称',
  com_endpoint_prompt_prefix: '自定义指令',
  com_endpoint_prompt_prefix_assistants: '额外指令',
  com_endpoint_instructions_assistants: '覆写指令',
  com_endpoint_temperature: '随机性',
  com_endpoint_default: '初始值',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: '最大输出词元数',
  com_endpoint_stop: '停止序列',
  com_endpoint_stop_placeholder: '按 `Enter` 键分隔多个值',
  com_endpoint_openai_max_tokens: `可选的 \`max_tokens\` 字段，表示在聊天补全中可生成的最大词元数量。

    输入词元和生成词元的总长度受模型上下文长度的限制。如果该数值超过最大上下文词元数，您可能会遇到错误。`,
  com_endpoint_openai_temp:
    '值越高表示输出越随机，值越低表示输出越确定。建议不要同时改变此值和 Top P。',
  com_endpoint_openai_max: '最大生成词元数。输入词元长度由模型的上下文长度决定。',
  com_endpoint_openai_topp:
    '相较于随机性的另一个取样方法，称为核采样，模型选取输出词元中大于 top_p（概率密度在整个概率分布中的比例）的结果。比如 top_p=0.1 表示只有概率占比为前 10% 的词元才会被考虑作为输出。建议不要同时改变此值和随机性。',
  com_endpoint_openai_freq:
    '值介于 -2.0 到 2.0 之间。正值将惩罚当前已频繁使用的词元，从而降低重复用词的可能性。',
  com_endpoint_openai_pres:
    '值介于 -2.0 到 2.0 之间。正值将惩罚当前已经使用的词元，从而增加讨论新话题的可能性。',
  com_endpoint_openai_resend:
    '重新发送所有先前附加的图像。注意：这会显着增加词元成本，并且可能会遇到很多关于图像附件的错误。',
  com_endpoint_openai_resend_files:
    '重新发送所有先前附加的文件。注意：这会显着增加词元成本，并且可能会遇到很多关于图像附件的错误。',
  com_endpoint_openai_detail:
    '发送给Vision的图像分辨率。 “Low” 更便宜且更快，“High” 更详细但更昂贵，“Auto” 将基于图像分辨率自动在两者之间进行选择。',
  com_endpoint_openai_stop: '最多 4 个序列，API 将停止生成更多词元。',
  com_endpoint_openai_custom_name_placeholder: '为 AI 设置一个名称',
  com_endpoint_openai_prompt_prefix_placeholder: '在系统消息中添加自定义指令，默认为空',
  com_endpoint_anthropic_temp:
    '值介于 0 到 1 之间。对于分析性/选择性任务，值应更接近 0；对于创造性和生成性任务，值应更接近 1。我们建议更改该参数或 Top P，但不要同时更改这两个参数。',
  com_endpoint_anthropic_topp:
    'top-p（核采样）会改变模型选择输出词元的方式。从概率最大的 K（参见topK参数）向最小的 K 选择，直到它们的概率之和等于 top-p 值。',
  com_endpoint_anthropic_topk:
    'top-k 会改变模型选择输出词元的方式。top-k 为 1 意味着所选词是模型词汇中概率最大的（也称为贪心解码），而 top-k 为 3 意味着下一个词是从 3 个概率最大的词中选出的（使用随机性）。',
  com_endpoint_anthropic_maxoutputtokens:
    '响应中可以生成的最大令牌数。指定较低的值以获得较短的响应，指定较高的值以获得较长的响应。注意：模型可能会在达到此最大值之前停止。',
  com_endpoint_anthropic_prompt_cache:
    '提示词缓存允许在 API 调用中重用大型上下文或指令，从而降低成本和延迟',
  com_endpoint_prompt_cache: '使用提示词缓存',
  com_endpoint_anthropic_custom_name_placeholder: '为 Anthropic 设置一个名称',
  com_endpoint_frequency_penalty: '频率惩罚度',
  com_endpoint_presence_penalty: '话题新鲜度',
  com_endpoint_plug_use_functions: '使用函数',
  com_endpoint_plug_resend_files: '重发文件',
  com_endpoint_plug_resend_images: '重发图片',
  com_endpoint_plug_image_detail: '图片细节',
  com_endpoint_plug_skip_completion: '跳过补全',
  com_endpoint_disabled_with_tools: '系统禁用',
  com_endpoint_disabled_with_tools_placeholder: '系统禁用',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    '在消息开头添加系统级提示词，默认为空',
  com_endpoint_import: '导入',
  com_endpoint_set_custom_name: '设置一个自定义名称，以便您检索此预设',
  com_endpoint_preset_delete_confirm: '您确定要删除此预设吗？',
  com_endpoint_preset_clear_all_confirm: '您确定要删除所有预设吗？',
  com_endpoint_preset_import: '成功导入预设！',
  com_endpoint_preset_import_error: '导入预设时发生错误，请重试。',
  com_endpoint_preset_save_error: '保存预设时发生错误，请重试。',
  com_endpoint_preset_delete_error: '删除预设时发生错误，请重试。',
  com_endpoint_preset_default_removed: '不再是默认预设。',
  com_endpoint_preset_default_item: '默认：',
  com_endpoint_preset_default_none: '无默认预设可用。',
  com_endpoint_preset_title: '预设',
  com_endpoint_preset_saved: '保存成功！',
  com_endpoint_preset_default: '现在是默认预设。',
  com_endpoint_preset: '预设',
  com_endpoint_presets: '预设',
  com_endpoint_preset_selected: '预设可用！',
  com_endpoint_preset_selected_title: '可用！',
  com_endpoint_preset_name: '预设名',
  com_endpoint_new_topic: '新主题',
  com_endpoint: '渠道',
  com_endpoint_hide: '隐藏',
  com_endpoint_show: '显示',
  com_endpoint_examples: ' 预设',
  com_endpoint_completion: '补全',
  com_endpoint_agent: '代理',
  com_endpoint_show_what_settings: '显示 {0} 设置',
  com_endpoint_export: '导出',
  com_endpoint_export_share: '导出/共享',
  com_endpoint_assistant: '助手',
  com_endpoint_use_active_assistant: '使用激活的助手',
  com_endpoint_assistant_model: '助手模型',
  com_endpoint_save_as_preset: '保存为预设',
  com_endpoint_presets_clear_warning: '确定要清除所有预设吗？此操作不可逆。',
  com_endpoint_not_implemented: '未实现功能',
  com_endpoint_no_presets: '暂无预设，使用设置按钮创建一个',
  com_endpoint_not_available: '无可用渠道',
  com_endpoint_view_options: '查看选项',
  com_endpoint_save_convo_as_preset: '保存对话为预设',
  com_endpoint_my_preset: '我的预设',
  com_endpoint_agent_model: '代理模型（推荐: GPT-3.5）',
  com_endpoint_completion_model: '补全模型（推荐: GPT-4）',
  com_endpoint_func_hover: '将插件作为 OpenAI 函数使用',
  com_endpoint_skip_hover: '跳过补全步骤，检查最终答案和生成步骤',
  com_endpoint_config_key: '设置 API 密钥',
  com_endpoint_assistant_placeholder: '请从右侧面板中选择助手',
  com_endpoint_config_placeholder: '在顶部菜单设置 API 密钥。',
  com_endpoint_config_key_for: '设置 API 密钥：',
  com_endpoint_config_key_name: '密钥',
  com_endpoint_config_value: '输入值：',
  com_endpoint_config_key_name_placeholder: '请先设置 API 密钥',
  com_endpoint_config_key_encryption: '您的密钥将被加密并删除于：',
  com_endpoint_config_key_never_expires: '您的密钥将永远有效',
  com_endpoint_config_key_expiry: '过期时间',
  com_endpoint_config_click_here: '点击此处',
  com_endpoint_config_google_service_key: 'Google 服务账户密钥',
  com_endpoint_config_google_cloud_platform: '（从谷歌云平台）',
  com_endpoint_config_google_api_key: 'Google API KEY',
  com_endpoint_config_google_gemini_api: '（Gemini API）',
  com_endpoint_config_google_api_info: '获取您的生成式语言 API 密钥（Gemini），',
  com_endpoint_config_key_import_json_key: '导入服务账户 JSON 密钥。',
  com_endpoint_config_key_import_json_key_success: '成功导入服务账户 JSON 密钥',
  com_endpoint_config_key_import_json_key_invalid:
    '无效的服务账户 JSON 密钥，您是否导入正确的文件？',
  com_endpoint_config_key_get_edge_key: '为获得 Bing 访问令牌（Access token），请登录：',
  com_endpoint_config_key_get_edge_key_dev_tool:
    '登录网站后，使用开发工具或扩展程序复制 _U cookie 的内容。如果失败，请按照以下步骤操作：',
  com_endpoint_config_key_edge_instructions: '操作步骤',
  com_endpoint_config_key_edge_full_key_string: '提供完整的 cookie 字符串。',
  com_endpoint_config_key_chatgpt: '为获得 ChatGPT 的访问令牌（Access token）, 请登录：',
  com_endpoint_config_key_chatgpt_then_visit: '然后访问',
  com_endpoint_config_key_chatgpt_copy_token: '复制 access token。',
  com_endpoint_config_key_google_need_to: '您需要',
  com_endpoint_config_key_google_vertex_ai: '在 Google Cloud 上启用 Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API，然后',
  com_endpoint_config_key_google_service_account: '创建一个服务账户',
  com_endpoint_config_key_google_vertex_api_role:
    '确保单击 “创建并继续” 以至少授予 “Vertex AI 用户” 角色。最后，创建一个要在此处导入的 JSON 密钥。',
  com_nav_account_settings: '账户设置',
  com_nav_font_size: '消息字体大小',
  com_nav_font_size_xs: '超小号',
  com_nav_font_size_sm: '小号',
  com_nav_font_size_base: '中号',
  com_nav_font_size_lg: '大号',
  com_nav_font_size_xl: '超大号',
  com_nav_welcome_assistant: '请选择助手',
  com_nav_welcome_message: '我今天能帮你做什么？',
  com_nav_auto_scroll: '打开对话时自动滚动到最新消息',
  com_nav_hide_panel: '隐藏最右侧面板',
  com_nav_modular_chat: '启用在对话中切换渠道',
  com_nav_latex_parsing: '解析消息中的 LaTeX（可能会影响性能）',
  com_nav_text_to_speech: '文本转语音',
  com_nav_automatic_playback: '自动播放最新消息',
  com_nav_speech_to_text: '语音转文本',
  com_nav_profile_picture: '个人资料头像',
  com_nav_change_picture: '修改头像',
  com_nav_plugin_store: '插件商店',
  com_nav_plugin_install: '安装',
  com_nav_plugin_uninstall: '卸载',
  com_ui_add: '添加',
  com_nav_tool_remove: '移除',
  com_nav_tool_dialog: '助手工具',
  com_ui_misc: '杂项',
  com_ui_roleplay: '角色扮演',
  com_ui_write: '写作',
  com_ui_idea: '灵感',
  com_ui_shop: '购物',
  com_ui_finance: '财务',
  com_ui_code: '代码',
  com_ui_travel: '旅行',
  com_ui_teach_or_explain: '学习',
  com_ui_select_file: '选择文件',
  com_ui_drag_drop_file: '将文件拖放到此处',
  com_ui_upload_image: '上传图片',
  com_ui_select_a_category: '未选择类别',
  com_ui_clear_all: '全部清除',
  com_nav_tool_dialog_description: '必须保存助手才能保留工具选择。',
  com_show_agent_settings: '显示代理设置',
  com_show_completion_settings: '显示补全设置',
  com_hide_examples: '隐藏示例',
  com_show_examples: '显示示例',
  com_nav_plugin_search: '搜索插件',
  com_nav_tool_search: '搜索工具',
  com_nav_plugin_auth_error: '尝试验证此插件时出现错误。请重试。',
  com_nav_export_filename: '文件名',
  com_nav_export_filename_placeholder: '设置文件名',
  com_nav_export_type: '类型',
  com_nav_export_include_endpoint_options: '包含渠道选项',
  com_nav_enabled: '启用',
  com_nav_not_supported: '未支持',
  com_nav_export_all_message_branches: '导出所有消息分支',
  com_nav_export_recursive_or_sequential: '递归或顺序？',
  com_nav_export_recursive: '递归',
  com_nav_export_conversation: '导出对话',
  com_nav_export: '导出',
  com_nav_shared_links: '共享链接',
  com_nav_shared_links_manage: '管理',
  com_nav_shared_links_empty: '您没有共享链接。',
  com_nav_shared_links_name: '名称',
  com_nav_shared_links_date_shared: '共享日期',
  com_nav_source_chat: '查看源代码对话',
  com_nav_my_files: '我的文件',
  com_nav_theme: '主题',
  com_nav_theme_system: '系统设置',
  com_nav_theme_dark: '暗色主题',
  com_nav_theme_light: '亮色主题',
  com_nav_enter_to_send: '按下 Enter 键发送消息',
  com_nav_user_name_display: '在消息中显示用户名',
  com_nav_save_drafts: '在本地保存草稿',
  com_nav_chat_direction: '对话方向',
  com_nav_show_code: '使用代码解释器时始终显示代码',
  com_nav_auto_send_prompts: '自动发送提示词',
  com_nav_always_make_prod: '始终应用新版本',
  com_nav_clear_all_chats: '清除所有对话',
  com_nav_confirm_clear: '确认清除',
  com_nav_close_sidebar: '关闭侧边栏',
  com_nav_open_sidebar: '打开侧边栏',
  com_nav_send_message: '发送消息',
  com_nav_log_out: '注销',
  com_nav_user: '默认用户',
  com_nav_archived_chats: '归档的对话',
  com_nav_archived_chats_manage: '管理',
  com_nav_archived_chats_empty: '您没有归档的对话。',
  com_nav_archive_all_chats: '归档所有对话',
  com_nav_archive_all: '归档所有',
  com_nav_archive_name: '名称',
  com_nav_archive_created_at: '归档时间',
  com_nav_clear_conversation: '清除对话',
  com_nav_clear_conversation_confirm_message: '您确定要删除所有对话吗？该操作无法撤销。',
  com_nav_help_faq: '帮助',
  com_nav_settings: '设置',
  com_nav_search_placeholder: '搜索消息',
  com_nav_delete_account: '删除账户',
  com_nav_delete_account_confirm: '删除账户 - 您确定吗？',
  com_nav_delete_account_button: '永久删除我的账户',
  com_nav_delete_account_email_placeholder: '请输入您的账户邮箱',
  com_nav_delete_account_confirm_placeholder: '要继续，请在下面的输入框中输入 “DELETE”',
  com_nav_delete_warning: '警告：这将永久删除您的账户。',
  com_nav_delete_data_info: '您的所有数据将被删除。',
  com_nav_conversation_mode: '对话模式',
  com_nav_auto_send_text: '自动发送文本',
  com_nav_auto_send_text_disabled: '设置为 -1 以禁用',
  com_nav_auto_transcribe_audio: '自动转录音频',
  com_nav_db_sensitivity: '分贝灵敏度',
  com_nav_playback_rate: '音频播放速度',
  com_nav_audio_play_error: '播放音频时发生错误：{0}',
  com_nav_audio_process_error: '处理音频时发生错误：{0}',
  com_nav_long_audio_warning: '较长的文本将需要更长时间来处理。',
  com_nav_tts_init_error: '初始化文本转语音失败：{0}',
  com_nav_tts_unsupported_error: '所选引擎的文本转语音在此浏览器中不受支持。',
  com_nav_source_buffer_error: '设置音频播放时发生错误。请刷新页面。',
  com_nav_media_source_init_error: '无法准备音频播放器。请检查您的浏览器设置。',
  com_nav_buffer_append_error: '音频流处理出现问题。播放可能会被中断。',
  com_nav_speech_cancel_error: '无法停止音频播放。您可能需要刷新页面。',
  com_nav_voices_fetch_error: '无法获取语音选项。请检查您的网络连接。',
  com_nav_engine: '引擎',
  com_nav_browser: '浏览器',
  com_nav_edge: '边缘端',
  com_nav_external: '外部',
  com_nav_delete_cache_storage: '删除 TTS 缓存存储',
  com_nav_enable_cache_tts: '启用 TTS 缓存',
  com_nav_voice_select: '语音',
  com_nav_enable_cloud_browser_voice: '使用云端语音',
  com_nav_info_enter_to_send:
    '启用后，按下 `ENTER` 将发送您的消息。禁用后，按下 `ENTER` 将添加新行，您需要按下 `CTRL + ENTER` / `⌘ + ENTER` 来发送消息。',
  com_nav_info_save_draft:
    '启用后，您在聊天表单中输入的文本和附件将自动本地保存为草稿。即使您重新加载页面或切换到不同的对话，这些草稿也将可用。草稿存储在您设备的本地，并在消息发送后删除。',
  com_nav_info_fork_change_default:
    '`仅可见消息` 仅包含到所选消息的直接路径，`包含相关分支` 添加路径上的分支，`包含所有目标` 包括所有连接的消息和分支。',
  com_nav_info_fork_split_target_setting:
    '启用后，将根据选择的行为，从目标消息开始到对话中的最新消息进行分叉。',
  com_nav_info_user_name_display:
    '启用后，发送者的用户名将显示在您发送的每条消息上方。禁用后，您只会在自己的消息上方看到 “您”。',
  com_nav_info_latex_parsing:
    '启用后，消息中的 LaTeX 代码将呈现为数学公式。如果您不需要 LaTeX 渲染，禁用此功能可能会提高性能。',
  com_nav_info_revoke:
    '此操作将撤销并删除您提供的所有 API 密钥。您需要重新输入这些凭据以继续使用这些渠道。',
  com_nav_info_delete_cache_storage:
    '此操作将删除存储在您设备上的所有缓存 TTS（文本转语音）音频文件。缓存音频文件用于加快先前生成的 TTS 音频的播放速度，但它们可能会占用您设备的存储空间。',
  // Command Settings Tab
  com_nav_commands: '命令',
  com_nav_commands_tab: '命令设置',
  com_nav_at_command: '@-命令',
  com_nav_at_command_description: '切换至命令 “@” 以更改端点、模型、预设等',
  com_nav_plus_command: '+-命令',
  com_nav_plus_command_description: '切换至命令 “+” 以添加多响应设置',
  com_nav_slash_command: '/-命令',
  com_nav_slash_command_description: '切换至命令 “/” 以通过键盘选择提示词',
  com_nav_command_settings: '命令设置',
  com_nav_command_settings_description: '自定义对话中可用的命令',
  com_nav_setting_general: '通用',
  com_nav_setting_chat: '对话',
  com_nav_setting_beta: '实验特性',
  com_nav_setting_data: '数据管理',
  com_nav_setting_account: '账户',
  com_nav_setting_speech: '语音',
  com_nav_language: '语言',
  com_nav_lang_auto: '自动检测语言',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_polish: 'Polski',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
  com_nav_lang_russian: 'Русский',
  com_nav_lang_japanese: '日本語',
  com_nav_lang_swedish: 'Svenska',
  com_nav_lang_korean: '한국어',
  com_nav_lang_vietnamese: 'Tiếng Việt',
  com_nav_lang_traditionalchinese: '繁體中文',
  com_nav_lang_arabic: 'العربية',
  com_nav_lang_turkish: 'Türkçe',
  com_nav_lang_dutch: 'Nederlands',
  com_nav_lang_indonesia: 'Indonesia',
  com_nav_lang_hebrew: 'עברית',
  com_nav_lang_finnish: 'Suomi',
  com_ui_accept: '我接受',
  com_ui_decline: '我不接受',
  com_ui_terms_and_conditions: '条款和条件',
  com_ui_no_terms_content: '没有可显示的条款和条件内容',
  com_warning_resubmit_unsupported: '此终端不支持重新提交AI消息',

  com_error_files_empty: '不允许上传空文件',

  com_error_files_dupe: '检测到重复文件',

  com_error_invalid_request_error:
    'AI服务因错误拒绝了请求。这可能是由于API密钥无效或请求格式不正确导致的。',

  com_error_files_validation: '验证文件时出错。',

  com_error_files_process: '处理文件时发生错误',

  com_error_no_system_messages:
    '所选的AI服务或模型不支持系统消息功能。请尝试使用提示词来替代自定义指令。',

  com_error_files_upload: '上传文件时发生错误',

  com_generated_files: '生成的文件',

  com_error_files_upload_canceled:
    '文件上传请求已取消。注意：文件上传可能仍在进行中，需要手动删除。',

  com_download_expired: '下载已过期',

  com_click_to_download: '（点击此处下载）',

  com_agents_missing_provider_model: '请在创建代理前选择提供商和模型',

  com_download_expires: '(点击此处下载 - {0}后过期)',

  com_agents_not_available: '助手不可用',

  com_agents_allow_editing: '允许其他用户编辑您的助手',

  com_agents_no_access: '您没有权限编辑此Agent。',

  com_agents_enable_file_search: '启用文件搜索',

  com_agents_file_search_info:
    '启用后，系统会告知Agent以下列出的具体文件名，使其能够从这些文件中检索相关内容。',

  com_agents_execute_code: '运行代码',

  com_agents_file_search_disabled: '必须先创建Agent，才能上传文件用于文件搜索。',

  com_ui_agent_editing_allowed: '其他用户已可以编辑此助手',

  com_ui_agent_already_shared_to_all: '该助手已对所有用户开放共享',

  com_ui_no_changes: '无需更新',

  com_ui_region: '区域',

  com_ui_select_region: '选择地区',

  com_ui_select_search_region: '以名称搜索区域',

  com_ui_revoke_keys: '撤销密钥',

  com_ui_revoke_key_endpoint: '撤销 {0} 的密钥',

  com_ui_revoke_keys_confirm: '您确定要撤销所有密钥吗？',

  com_ui_revoke_key_confirm: '您确定要撤销此密钥吗？',

  com_ui_bookmarks_delete: '删除书签',

  com_endpoint_search: '按名称搜索渠道',

  com_ui_add_multi_conversation: '添加多个对话',

  com_nav_tool_dialog_agents: 'Agent工具',

  com_nav_stop_generating: '停止生成',

  com_nav_user_msg_markdown: '以 Markdown 格式显示用户消息',

  com_nav_chat_commands: '对话命令',

  com_nav_clear_cache_confirm_message: '您确定要清除缓存吗？',

  com_nav_no_search_results: '未找到搜索结果',

  com_nav_chat_commands_info:
    '这些命令通过在您的消息开头输入特定字符来激活。每个命令都由其指定的前缀触发。如果您经常在消息开头使用这些字符，可以选择禁用这些命令。',
};
