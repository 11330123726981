import React, { memo } from 'react';
import type { TUser } from 'librechat-data-provider';
import type { IconProps } from '~/common';
import MessageEndpointIcon from './MessageEndpointIcon';
import { useAuthContext } from '~/hooks/AuthContext';
import useLocalize from '~/hooks/useLocalize';
import { UserIcon } from '~/components/svg';
import { cn } from '~/utils';

type UserAvatarProps = {
  size: number;
  user?: TUser;
  username: string;
  className?: string;
};

const UserAvatar = memo(({ size, username, className }: UserAvatarProps) => (
  <div
    title={username}
    style={{
      width: size,
      height: size,
    }}
    className={cn('relative flex items-center justify-center', className ?? '')}
  >
    <div
      style={{
        width: '20px',
        height: '20px',
      }}
      className="magenta-gradient-background relative flex items-center justify-center rounded-full text-white"
    >
      <UserIcon width={12} height={12} />
    </div>
  </div>
));

UserAvatar.displayName = 'UserAvatar';

const Icon: React.FC<IconProps> = memo((props) => {
  const { user } = useAuthContext();
  const { size = 30, isCreatedByUser } = props;
  const localize = useLocalize();

  if (isCreatedByUser) {
    const username = user?.name ?? user?.username ?? localize('com_nav_user');
    return (
      <UserAvatar
        size={size}
        user={user}
        username={username}
        className={props.className}
      />
    );
  }
  return <MessageEndpointIcon {...props} />;
});

Icon.displayName = 'Icon';

export default Icon;
