import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { Artifact } from '~/common';
import FilePreview from '~/components/Chat/Input/Files/FilePreview';
import { useChatContext } from '~/Providers';
import { useLocalize } from '~/hooks';
import { getFileType, trackArtifactUsage } from '~/utils';
import store from '~/store';

const ArtifactButton = ({ artifact }: { artifact: Artifact | null }) => {
  const localize = useLocalize();
  const { conversation } = useChatContext();
  const [artifactsVisible, setArtifactsVisible] = useRecoilState(store.artifactsVisible);
  const [currentArtifactId, setCurrentArtifactId] = useRecoilState(store.currentArtifactId);
  const [seenArtifacts, setSeenArtifacts] = useRecoilState(store.seenArtifactsState);
  const setActiveConversationId = useSetRecoilState(store.activeConversationId);
  const scopedArtifacts = useRecoilValue(store.scopedArtifactsState);
  const prevContentRef = useRef<string | null>(null);

  // Auto-show artifact when it's new or content changes
  useEffect(() => {
    if (!artifact || !conversation?.conversationId) return;

    const conversationId = conversation.conversationId;
    const conversationArtifacts = seenArtifacts[conversationId] || new Set();
    const isNewArtifact = !conversationArtifacts.has(artifact.id);
    const hasContentChanged = prevContentRef.current !== null && 
                            prevContentRef.current !== artifact.content;

    console.log('[ArtifactButton] Artifact update detected:', {
      artifactId: artifact.id,
      currentArtifactId,
      artifactsVisible,
      conversationId,
      title: artifact.title,
      hasArtifacts: !!scopedArtifacts,
      prevContent: prevContentRef.current,
      newContent: artifact.content,
      hasContentChanged,
      isNewArtifact,
      seenArtifactsInConversation: Array.from(conversationArtifacts)
    });

    // Show panel if it's a new artifact or content changed
    if (isNewArtifact || hasContentChanged) {
      console.log('[ArtifactButton] New artifact or content changed - showing panel');
      setActiveConversationId(conversationId);
      setCurrentArtifactId(artifact.id);
      setArtifactsVisible(true);

      // Track new artifact usage
      if (isNewArtifact) {
        trackArtifactUsage({
          artifact_id: artifact.id,
          artifact_title: artifact.title || 'Untitled Artifact',
          artifact_type: artifact.type || 'unknown'
        });

        const updatedSeenArtifacts = new Set(conversationArtifacts);
        updatedSeenArtifacts.add(artifact.id);
        
        setSeenArtifacts(prev => ({
          ...prev,
          [conversationId]: updatedSeenArtifacts
        }));
      }
    }

    // Update content ref after checking for changes
    prevContentRef.current = artifact.content ?? null;
  }, [
    artifact, 
    conversation?.conversationId, 
    setActiveConversationId, 
    setCurrentArtifactId, 
    setArtifactsVisible,
    seenArtifacts,
    setSeenArtifacts
  ]);

  if (artifact === null || artifact === undefined) {
    return null;
  }

  const fileType = getFileType('artifact');

  const handleClick = () => {
    console.log('[ArtifactButton] Click - Current state:', {
      artifactId: artifact.id,
      currentArtifactId,
      artifactsVisible,
      conversationId: conversation?.conversationId,
      title: artifact.title,
      hasArtifacts: !!scopedArtifacts,
    });

    if (!conversation?.conversationId) {
      console.warn('[ArtifactButton] No conversation ID available');
      return;
    }

    // Toggle visibility
    setArtifactsVisible(!artifactsVisible);

    // If showing, update the current artifact
    if (!artifactsVisible) {
      setActiveConversationId(conversation.conversationId);
      setCurrentArtifactId(artifact.id);
    }
  };

  return (
    <div className="text-text-primary group relative my-4 rounded-xl text-sm">
      <button
        type="button"
        onClick={handleClick}
        className="border-border-medium hover:border-border-xheavy relative overflow-hidden rounded-xl border transition-all duration-300 hover:shadow-lg"
      >
        <div className="w-fit bg-[#F2F2F2] p-2 dark:bg-[#1c2846] ">
          <div className="flex flex-row items-center gap-2">
            <FilePreview fileType={fileType} className="relative" />
            <div className="overflow-hidden text-left">
              <div className="truncate font-medium">{artifact.title}</div>
              <div className="text-text-secondary truncate">
                {localize('com_ui_artifact_click')}
              </div>
            </div>
          </div>
        </div>
      </button>
      <br />
    </div>
  );
};

export default ArtifactButton;
