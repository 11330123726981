interface ConvoStarterProps {
  text: string;
  onClick: () => void;
  emoji?: string;
}

export default function ConvoStarter({
  text,
  onClick,
  emoji,
}: ConvoStarterProps) {
  return (
    <button
      onClick={onClick}
      className="convo-starter prompt-card-background fade-in relative flex w-full flex-row items-center gap-[10px]
        rounded-lg p-[14px] duration-200 ease-in-out lg:flex-col lg:items-center lg:gap-4"
    >
      <div className="flex h-full items-center justify-center space-x-[10px]">
        {emoji != null && <span className="text-[18px]">{emoji}</span>}
        <p className="text-left text-[16px]">{text}</p>
      </div>
    </button>
  );
}
