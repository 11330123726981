import { useRef } from 'react';
import { RefreshCw } from 'lucide-react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Tabs from '@radix-ui/react-tabs';
import { SandpackPreviewRef } from '@codesandbox/sandpack-react';
import useArtifacts from '~/hooks/Artifacts/useArtifacts';
import { CodeMarkdown, CopyCodeButton } from './Code';
import { getFileExtension } from '~/utils/artifacts';
import { ArtifactPreview } from './ArtifactPreview';
import { useChatContext } from '~/Providers';
import { cn } from '~/utils';
import store from '~/store';

export default function Artifacts() {
  const previewRef = useRef<SandpackPreviewRef>();
  const { conversation } = useChatContext();
  const [artifactsVisible, setArtifactsVisible] = useRecoilState(store.artifactsVisible);
  const currentArtifactId = useRecoilValue(store.currentArtifactId);
  const scopedArtifacts = useRecoilValue(store.scopedArtifactsState);

  const {
    activeTab,
    isMermaid,
    isSubmitting,
    setActiveTab,
    currentIndex,
    cycleArtifact,
    currentArtifact,
    orderedArtifactIds,
  } = useArtifacts();

  // Cleanup function to remove iframes
  const cleanupIframes = () => {
    console.log('[Artifacts] Cleaning up iframes');
    const iframes = document.querySelectorAll('iframe[src*="codesandbox.io"]');
    iframes.forEach((element) => {
      const iframe = element as HTMLIFrameElement;
      console.log('[Artifacts] Removing iframe:', iframe.src);
      iframe.remove();
    });
  };

  // Hide component if no artifact is available or no conversation
  if (!artifactsVisible || !currentArtifact || !conversation?.conversationId || !scopedArtifacts) {
    console.log('[Artifacts] Hiding panel:', {
      artifactsVisible,
      hasCurrentArtifact: !!currentArtifact,
      conversationId: conversation?.conversationId,
      hasArtifacts: !!scopedArtifacts,
    });
    return null;
  }

  const handleClose = () => {
    console.log('[Artifacts] Closing panel');
    cleanupIframes();
    setArtifactsVisible(false);
  };

  const handleRefresh = () => {
    const client = previewRef.current?.getClient();
    if (client != null) {
      client.dispatch({ type: 'refresh' });
    }
  };

  return (
    <Tabs.Root value={activeTab} onValueChange={setActiveTab} asChild>
      {/* Main Parent */}
      <div className="flex h-full w-full items-center justify-center py-2">
        {/* Main Container */}
        <div className="border-border-medium bg-surface-primary text-text-primary flex h-[97%] w-[97%] flex-col overflow-hidden rounded-xl border text-xl shadow-xl">
          {/* Header */}
          <div className="border-border-medium flex min-h-[48px] flex-wrap items-center justify-between gap-2 border-b bg-[#F2F2F2] p-1.5 sm:p-2 dark:bg-[#060B17]">
            <div className="flex items-center">
              <button
                className="text-text-secondary mr-1.5 sm:mr-2"
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                  className="sm:h-4 sm:w-4"
                >
                  <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z" />
                </svg>
              </button>
              <h3 className="text-text-primary max-w-[150px] truncate text-xs sm:max-w-[200px] sm:text-sm">
                {currentArtifact.title}
              </h3>
            </div>
            <div className="flex items-center gap-1 sm:gap-2">
              {/* Refresh button */}
              {activeTab === 'preview' && (
                <button
                  className="text-text-secondary"
                  onClick={handleRefresh}
                  aria-label="Refresh"
                >
                  <RefreshCw size={14} className="sm:h-4 sm:w-4" />
                </button>
              )}
              <Tabs.List className="border-border-medium bg-surface-tertiary inline-flex h-6 rounded-full border sm:h-7">
                <Tabs.Trigger
                  value="preview"
                  className="border-0.5 text-text-secondary data-[state=active]:border-border-light data-[state=active]:bg-surface-primary-alt data-[state=active]:text-text-primary flex items-center gap-1 rounded-full border-transparent py-0.5 pl-2 pr-2 text-[10px] font-medium sm:py-1 sm:pl-2.5 sm:pr-2.5 sm:text-xs"
                >
                  Preview
                </Tabs.Trigger>
                <Tabs.Trigger
                  value="code"
                  className="border-0.5 text-text-secondary data-[state=active]:border-border-light data-[state=active]:bg-surface-primary-alt data-[state=active]:text-text-primary flex items-center gap-1 rounded-full border-transparent py-0.5 pl-2 pr-2 text-[10px] font-medium sm:py-1 sm:pl-2.5 sm:pr-2.5 sm:text-xs"
                >
                  Code
                </Tabs.Trigger>
              </Tabs.List>
              <button
                className="text-text-secondary"
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                  className="sm:h-4 sm:w-4"
                >
                  <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z" />
                </svg>
              </button>
            </div>
          </div>
          {/* Content */}
          <Tabs.Content
            value="code"
            className={cn(
              'flex-grow overflow-x-auto overflow-y-scroll bg-gray-900 p-4',
            )}
          >
            <CodeMarkdown
              content={`\`\`\`${getFileExtension(currentArtifact.type)}\n${
                currentArtifact.content ?? ''
              }\`\`\``}
              isSubmitting={isSubmitting}
            />
          </Tabs.Content>
          <Tabs.Content
            value="preview"
            className={cn(
              'flex-grow overflow-auto',
              isMermaid ? 'bg-[#282C34]' : 'bg-white',
            )}
          >
            {activeTab === 'preview' && (
              <ArtifactPreview
                artifact={currentArtifact}
                previewRef={
                  previewRef as React.MutableRefObject<SandpackPreviewRef>
                }
              />
            )}
          </Tabs.Content>
          {/* Footer */}
          <div className="border-border-medium text-text-primary flex items-center justify-between border-t bg-[#F2F2F2] p-2 text-sm dark:bg-[#060B17]">
            <div className="flex items-center">
              <button
                onClick={() => cycleArtifact('prev')}
                className="text-text-secondary mr-2"
                disabled={orderedArtifactIds.length <= 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z" />
                </svg>
              </button>
              <span className="text-xs">{`${currentIndex + 1} / ${
                orderedArtifactIds.length
              }`}</span>
              <button
                onClick={() => cycleArtifact('next')}
                className="text-text-secondary ml-2"
                disabled={orderedArtifactIds.length <= 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z" />
                </svg>
              </button>
            </div>
            <div className="flex items-center">
              <CopyCodeButton content={currentArtifact.content ?? ''} />
            </div>
          </div>
        </div>
      </div>
    </Tabs.Root>
  );
}
