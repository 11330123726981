import React from 'react'
import '../css/CardLayout.css'

type CardProps = {
  title: string;
  description: string;
  icon?: string;
};

const CardComponent: React.FC<CardProps> = ({ title, description, icon }) => {
  return (
    <div className="rounded-lg p-[1px] card-border-gradient">
      <div className="h-full w-full rounded-lg p-6 card-background-gradient">
        <div className="magenta-gradient-background inline-block flex h-[54px] w-[54px] items-center justify-center rounded-full">
          {icon && <img src={icon} alt={title} className="h-8 w-8" />}
        </div>
        <h3 className="mb-2 mt-3 text-xl text-[#0F172A] dark:text-white">{title}</h3>
        <p className="text-base text-[#0F172A] dark:text-white">{description}</p>
      </div>
    </div>
  );
};



export const cardData = [
  {
    title: 'Exclusive access to Ayzenberg.AI',
    description: 'Trained on 25 years of industry insights and data',
    icon: '/assets/icons/network.svg',
  },
  {
    title: 'Multi-modal tools',
    description: 'Easy access to multiple AI platforms and plugins like DALLE-3',
    icon: '/assets/icons/wand.svg',
  },
  {
    title: 'No monthly subscriptions',
    description: 'Use models from OpenAI, Google, Anthropic, groq',
    icon: '/assets/icons/plug.svg',
  },
];

export default CardComponent;
